import React, { useEffect, useState } from "react";

import { Form, Button} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

import { resetPageTokenValidate } from "API/authAPI";
import { resetPassword } from "API/authAPI";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import { handleKeyPress } from "helpers/numberValidator";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters
  const token = queryParams.get("token");
  const tfa = queryParams.get("tfa");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loadings, setLoading] = useState(false);

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();

  const passwordResetter = useMutation(resetPassword, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false)
      navigate("/login");
      await showToast({
        icon: "success",
        title: "Your password has been reset successfully!",
        position: "top-end",
        timer: 2000,
      });
    },
  });
  const tokenValidator = useMutation(resetPageTokenValidate, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false)

    },
  });
  const onSubmit = async (data) => {
    setApiError(false);
    if (data.password !== data.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    try {
      let response;
      if (tfa == 1) {
        response = await passwordResetter.mutateAsync({
          token: token,
          otp: data.totp,
          new_password: data.password,
          confirm_password: data.confirmPassword,
        });
      } else {
        const response = await passwordResetter.mutateAsync({
          token: token,
          new_password: data.password,
          confirm_password: data.confirmPassword,
        });
      }
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false)

        setApiError(error.response.data.message);
      } else if (error.response.status == 408) {
        setLoading(false)
        // window.location.href = "/forgot-password";
        navigate("/forgot-password");
        await showToast({
          icon: "error",
          title: "Session expired! please try again.",
          position: "top-end",
          timer: 2000,
        });
      } else {
        showServerMessage();
      }
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      try {
        const response = await tokenValidator.mutateAsync({
          token: token,
        });
      } catch (error) {
        if (error.response.status === 408) {
          setLoading(false)
          navigate("/forgot-password");
          await showToast({
            icon: "error",
            title: "Session expired! please try again.",
            position: "top-end",
            timer: 2000,
          });
        }
      }
    };
    asyncFn();
  }, []);

  return (
    <div className="container mx-auto px-4 user-fields-wrapper form-field">
       <LoadingOverlay isLoading={loadings} />
      <div className="container-child">
        <Form
          className="p-4 border rounded shadow-sm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <>
            <h2 className="mb-4 text-center login-title">Reset Password</h2>

            <Form.Group controlId="formBasicPassword">
              <Form.Label title="New password">
                <span className="label-text">New password</span>{" "}
                <span className="error-message">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                className="mb-1"
                {...register("password", {
                  required: true,
                  maxLength: 50,
                  minLength: 8,
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  },
                })}
              />
              <span className="error-message">
                {errors.password?.type === "required" &&
                  "New password is required."}
                {errors.password?.type === "maxLength" &&
                  "It accepts a maximum of 50 characters."}
                {errors.password?.type === "minLength" &&
                  "Enter a minimum of 8 characters."}
                {errors.password?.type === "pattern" &&
                  "The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character, and it must be at least 8 characters long."}
              </span>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label title="Confirm password">
                <span className="label-text">Confirm password</span>{" "}
                <span className="error-message">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                className="mb-1"
                {...register("confirmPassword", {
                  required: true,
                })}
              />
              <span className="error-message">
                {errors.confirmPassword?.type === "required" &&
                  "Please re-enter your new password to confirm."}
                {errors.confirmPassword && errors.confirmPassword.message}
                {!passwordsMatch && "Passwords do not match"}
              </span>
            </Form.Group>

            {tfa == 1 && (
              <Form.Group controlId="formBasicOTP">
                <Form.Label title="TOTP">
                  <span className="label-text">TOTP</span> 
                  <span className="error-message"> *</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="TOTP"
                  onKeyPress={handleKeyPress}
                  autocomplete="off"
                  {...register("totp", {
                    required: true,
                    maxLength: 6,
                    minLength: 6,
                    pattern: {
                      value: /^[0-9]+$/,
                    },
                  })}
                />
                <span className="error-message">
                  {errors.totp?.type === "required" && "TOTP is required."}
                  {errors.totp?.type === "pattern" &&
                    "Please enter a valid number."}
                  {errors.totp?.type === "maxLength" &&
                    "It accepts maximum 6 characters."}
                  {errors.totp?.type === "minLength" &&
                    "It accepts minimum 6 characters."}
                  {errors.totp && errors.totp.message}
                </span>
              </Form.Group>
            )}
            {displayServerMessage && (
              <FormAlertMessage message={serverErrorMessage} type="error" />
            )}
            {apiError && <FormAlertMessage message={apiError} type="error" />}
            <Button
              variant="primary"
              type="submit"
              block="true"
              className="mt-3 mb-4 submit-btn"
            >
              Update Password
            </Button>
          </>
        </Form>
      </div>
      {/* <Container
        fluid
        className="d-flex justify-content-center align-items-center login-form-conatiner user-fields-wrapper form-field"
      >
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={8}
          xl={6}
          className="justify-content-center"
        >
          <Form
            className="p-4 border rounded shadow-sm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <>
              <h2 className="mb-4 text-center login-title">Reset Password</h2>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  New Password <span className="error-message">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="mb-1"
                  {...register("password", {
                    required: true,
                    maxLength: 50,
                    minLength: 8,
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    },
                  })}
                />
                <span className="error-message">
                  {errors.password?.type === "required" &&
                    "New Password is required."}
                  {errors.password?.type === "maxLength" &&
                    "It accepts a maximum of 50 characters."}
                  {errors.password?.type === "minLength" &&
                    "Enter a minimum of 8 characters."}
                  {errors.password?.type === "pattern" &&
                    "The password must meet the following criteria: It should be a minimum of 8 characters in length and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."}
                </span>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  Confirm Password <span className="error-message">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="mb-1"
                  {...register("confirmPassword", {
                    required: true,
                  })}
                />
                <span className="error-message">
                  {errors.confirmPassword?.type === "required" &&
                    "Please re-enter your new password to confirm."}
                  {errors.confirmPassword && errors.confirmPassword.message}
                  {!passwordsMatch && "Passwords do not match"}
                </span>
              </Form.Group>

              {tfa == 1 && (
                <Form.Group controlId="formBasicOTP">
                  <Form.Label>
                    TOTP <span className="error-message">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="TOTP"
                    onKeyPress={handleKeyPress}
                    autocomplete="off"
                    {...register("totp", {
                      required: true,
                      maxLength: 6,
                      minLength: 6,
                      pattern: {
                        value: /^[0-9]+$/,
                      },
                    })}
                  />
                  <span className="error-message">
                    {errors.totp?.type === "required" && "TOTP is required."}
                    {errors.totp?.type === "pattern" &&
                      "Please enter a valid number."}
                    {errors.totp?.type === "maxLength" &&
                      "It accepts maximum 6 characters."}
                    {errors.totp?.type === "minLength" &&
                      "It accepts minimum 6 characters."}
                    {errors.totp && errors.totp.message}
                  </span>
                </Form.Group>
              )}
              {displayServerMessage && (
                <FormAlertMessage message={serverErrorMessage} type="error" />
              )}
              {apiError && <FormAlertMessage message={apiError} type="error" />}
              <Button
                variant="primary"
                type="submit"
                block="true"
                className="mt-3 mb-4 submit-btn"
              >
                Update Password
              </Button>
            </>
          </Form>
        </Col>
      </Container> */}
    </div>
  );
};

export default ResetPasswordForm;
