import moment from "moment";
import dayjs from "dayjs";

export const isValidDateFormat = (dateString) => {
  return moment(dateString, "YYYY-MM-DD", true).isValid();
};

export const convertDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
