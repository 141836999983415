import React from "react";

import UserRoleView from "views/UserRole/UserRole";

const UserRole = () => {
  return (
    <div>
      <UserRoleView />
    </div>
  );
};

export default UserRole;
