import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getSoftwareLogs } from "API/softwareLogAPI";
import SearchFilter from "components/SearchFilter/SearchFilter";
import dayjs from "dayjs";
import GenericIconButton from "components/Buttons/GenericIconButton";
import ActionByUserForm from "components/Modal/ActionByUserForm";
import { Tooltip } from "antd";
import LoadingOverlay from "components/Overlay";


import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";
import CopyToClipboard from "react-copy-to-clipboard";

const SoftwareLogTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [addActionUserModalVisible, setActionUserModalVisible] =
    useState(false);
  const [selectedLogId, setSelecetedLogID] = useState();
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [copied, setCopied] = useState(false);
  const [event ,setEvent] = useState(null)
  const [loadings, setLoading] = useState(false);



  const softwareLogModuleID = parseInt(
    process.env.REACT_APP_SOFTWARE_LOG_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    status_filter: selectedStatus,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();
  const {
    isLoading,
    isError,
    error,
    data: softwareLog,
    refetch,
  } = useQuery(["softwareLog", queryParams], getSoftwareLogs, {
    cacheTime: cacheDuration,
  });

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleStatusSelect = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    refetch();
  };
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true);
    },
  });
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000); // 3000 milliseconds = 3 seconds
  };
  const showActionUserModal = () => {
    setActionUserModalVisible(true);
  };

  const handleAddActionUser = () => {
    // Your logic when OK button is clicked for AddSimModalForm
    setActionUserModalVisible(false);
    refetch();
  };

  const handleAddActionUserCancel = () => {
    // Your logic when Cancel button is clicked for AddSimModalForm
    setActionUserModalVisible(false);
    refetch();
  };
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: softwareLogModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
   
      setLoading(false);
    
  };

  const actionButtons = (record) => {
    const buttons = [];

    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: fieldIds.softwareLog.created_at,
      title: "Date",
      dataIndex: "created_at",
      render: (created_at) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {created_at ? dayjs(created_at).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.created_at);
        const dateB = dayjs(b.created_at);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.softwareLog.modem_log_id,
      title: "Modem log",
      dataIndex: "modem_log_no",
      render: (modem_log_no, more) => (
        <div
          onClick={() =>
            navigate("/modem-log", {
              state: {
                data: {
                  id: more?.modem_id,
                  modem_name: more?.modem_name,
                  modem_log_id_filter: more?.modem_log_id,
                },
              },
            })
          }
          style={{
            maxWidth: "200px",
            width: "200px",
            // textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            textDecoration:(modem_log_no != null)? "underline":"none", color: "blue", 
          }}
        >
                 <span style={{ background: (modem_log_no != null) ? '#edf2ff' : '',cursor: "pointer"}}>  {modem_log_no || more.modem_name
            ? `${modem_log_no ??" "} ${more.modem_name ? `(${more.modem_name})` : "" }`:" "}</span>
        
        </div>
      ),
      sorter: (a, b) =>
        String(a.modem_log_no ?? "").localeCompare(
          String(b.modem_log_no ?? "")
        ),
    },
    {
      perm_id: fieldIds.softwareLog.service_partner_id,
      title: "Service Partner",
      dataIndex: "service_partner_short_name",
      sorter: (a, b) =>
        String(a.service_partner_short_name ?? "").localeCompare(
          String(b.service_partner_short_name ?? "")
        ),
    },
    {
      perm_id: fieldIds.softwareLog.event_status,
      title: "Event status",
      dataIndex: "event_status",
      sorter: (a, b) =>
        String(a.event_status ?? "").localeCompare(String(b.event_status ?? "")),
      render: (event_status) =>
      event_status != null ? event_status : "-",
    },
    {
      perm_id: fieldIds.softwareLog.is_complated,
      title: "Is completed",
      dataIndex: "is_complated",
      sorter: (a, b) =>
        String(a.is_complated ?? "").localeCompare(
          String(b.is_complated ?? "")
        ),
      render: (is_complated) =>
        is_complated != null ? (is_complated ? "Yes" : "No") : "-",
    },
    {
      perm_id: fieldIds.softwareLog.action,
      title: "Event",
      dataIndex: "action",
      sorter: (a, b) =>
        String(a.action ?? "").localeCompare(String(b.action ?? "")),
    },
    {
      perm_id: fieldIds.softwareLog.is_error,
      title: "Is error",
      dataIndex: "is_error",
      sorter: (a, b) =>
        String(a.is_error ?? "").localeCompare(String(b.is_error ?? "")),
      render: (is_error) =>
        is_error != null ? (is_error ? "Yes" : "No") : "-",
    },
    {
      perm_id: fieldIds.softwareLog.error_message,
      title: "Error message",
      dataIndex: "error_message",
      sorter: (a, b) =>
        String(a.error_message ?? "").localeCompare(
          String(b.error_message ?? "")
        ),
      render: (error_message) => (
        <div
          style={{
            maxWidth: "400px",
            minWidth: "400px",
            width: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {/* <Tooltip
            placement="topLeft"
            title={error_message ? "Click to copy" : ""}
          >
            {error_message ? (
              <CopyToClipboard text={error_message}>
                <>
                {error_message}
                </>
               
              </CopyToClipboard>
            ) : (
              "-"
            )}
          </Tooltip> */}
        <Tooltip
  placement="top"
  title={copied ? "Copied" : (error_message ? "Click to copy" : "")}
>
  {error_message ? (
    <CopyToClipboard text={error_message} onCopy={handleCopy}>
      <span style={{ cursor: 'pointer' }}>{error_message}</span>
    </CopyToClipboard>
  ) : (
    "-"
  )}
</Tooltip>
        </div>
      ),
    },
    {
      perm_id: fieldIds.softwareLog.action_taken,
      title: "Action taken ",
      dataIndex: "action_taken",
      sorter: (a, b) =>
        String(a.action_taken ?? "").localeCompare(
          String(b.action_taken ?? "")
        ),
      render: (action_taken) =>
        action_taken != null ? (action_taken ? "Yes" : "No") : "-",
    },
    {
      perm_id: fieldIds.softwareLog.action_taken_by_id,
      title: "Action taken by",
      dataIndex: "action_taken_by_first_name",
      sorter: (a, b) =>
        String(a.action_taken_by_first_name ?? "").localeCompare(
          String(b.action_taken_by_first_name ?? "")
        ),
      render: (action_taken_by_first_name, extra) => {
        return (
          <div>
            {/* <GenericIconButton
              index={extra.id}
              onChangeHandler={(e) => addActionTakenUser(extra?.id)}
              icon={"fa-solid fa-plus"}
            /> */}
            {extra.action_taken ? (
              action_taken_by_first_name + " " + extra.action_taken_by_last_name
            ) : (
              <GenericIconButton
                index={extra.id}
                onChangeHandler={(e) => addActionTakenUser(extra?.id,extra?.action)}
                icon={"fa-solid fa-plus"}
              />
            )}
          </div>
        );
      },
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: softwareLog?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={softwareLog?.results}
        columns={columns}
        permissionSet={permissions.permissions[softwareLogModuleID]}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
      />
    );
  }

  const addActionTakenUser = (id,extra) => {
    setEvent(extra);
    setSelecetedLogID(id);
    setActionUserModalVisible(true);
  };
  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      {selectedLogId && (
        <ActionByUserForm
          key={selectedLogId}
          visible={addActionUserModalVisible}
          onOk={handleAddActionUser}
          event={event}
          onCancel={handleAddActionUserCancel}
          logID={selectedLogId}
        />
      )}

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Software Logs
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[softwareLogModuleID].fields ||
                        []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={softwareLogModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name"
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center"
                  ></Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SoftwareLogTable;
