import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddExpirationListForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";
import {
  getModemDetails,
  getSimPackageDetails,
  createExpirationList,
  editExpirationList,
} from "API/expirationListAPI";
import CustomMultipleDropdown from "components/CustomDropdown/MultipleDropdown";
import { DatePicker } from "antd";
import { convertDate } from "helpers/dateValidator";

const AddExpirationListForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();

  const [selectedModem, setSelectedModem] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedPortList, setSelectedPortList] = useState();
  const [selectedIsCalled, setSelectedIsCalled] = useState();
  const [selectedActualUntil, setSelectedActualUntil] = useState();
  const [selectedCalledDate, setSelectedCalledDate] = useState();
  const [selectedBalanceDate, setSelectedBalanceDate] = useState();
  const [selectedTopupDate, setSelectedTopUpDate] = useState();
  const [loadings, setLoading] = useState(false);

  
  const [selectedExtraOption, setSelectedExtraOption] = useState({
    label: "Select All",
    value: "select",
  });

  const expirationListModuleID = parseInt(
    process.env.REACT_APP_EXPIRATION_LIST_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingModem,
    isError: isErrorModem,
    error: errorModem,
    data: modemOptions,
  } = useQuery("modemOptions", getModemDetails, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingSimPackage,
    isError: isErrorActivation,
    error: errorActivation,
    data: simPackageOptions,
  } = useQuery("simPackageOptions", getSimPackageDetails, {
    cacheTime: cacheDuration,
  });

  const booleanOptions = [
    { id: "1", name: "Yes", boolVal: true },
    { id: "0", name: "No", boolVal: false },
  ];

  useEffect(() => {
    if (
      location.state &&
      simPackageOptions&&
      modemOptions
    ) {
      const expirationData = location.state.data;
  

      const modemVal = modemOptions?.find((value) => {
        return !isNaN(Number(expirationData?.modem_name))
          ? value.id == expirationData?.modem_name
          : value.name == expirationData?.modem_name;
      });
     
      const packageVal = simPackageOptions?.sub_packages?.find((value) => {
        return !isNaN(Number(expirationData?.sub_package_name))
          ? value.id == expirationData?.sub_package_name
          : value.name == expirationData?.sub_package_name
      });
      
      const outputArray = expirationData?.port?.split(",");

      if (outputArray?.length == 64) {
        setSelectedExtraOption({ label: "UnSelect All", value: "unselect" });
      }

      const isCalledVal = booleanOptions?.find((value) => {
        return !isNaN(Number(expirationData?.is_called))
          ? value.id == expirationData?.is_called
          : value.name == expirationData?.is_called
      });
   

   
      setValue("modem", modemVal.id);
      setSelectedModem(modemVal);
      setValue("package", packageVal.id);
      setSelectedPackage(packageVal);
      setValue("port_list", outputArray);
      setSelectedPortList(outputArray);
      setValue("number", expirationData?.number);
      setValue("iccid", expirationData?.iccid);
      setValue(
        "actualUntil",
        expirationData?.actual_until != "-" &&
        expirationData?.actual_until
          ? dayjs(expirationData?.actual_until)
          : ""
      );
      setValue("isCalled", isCalledVal.id);
      setSelectedIsCalled(isCalledVal);
      setValue(
        "calledDate",
        expirationData?.call_date != "-" &&
        expirationData?.call_date
          ? dayjs(expirationData?.call_date)
          : ""
      );
      setValue("latestBalanceAmount", expirationData?.latest_balance_amount);
      setValue(
        "balanceDate",
        expirationData?.balance_date != "-" &&
        expirationData?.balance_date
          ? dayjs(expirationData?.balance_date)
          : ""
      );
      setValue("latestTopupAmount", expirationData?.latest_topup_amount);
      setValue(
        "topupDate",
        expirationData?.topup_date != "-" &&
        expirationData?.topup_date
          ? dayjs(expirationData?.topup_date)
          : ""
      );
   

      setIsEdit(true);
    } else {
      setSelectedCalledDate(dayjs());
      setValue("calledDate", dayjs());
      setSelectedBalanceDate(dayjs());
      setValue("balanceDate", dayjs());
     setSelectedTopUpDate(dayjs())
      setValue("topupDate", dayjs());
    }
  }, [
    location.state,
    modemOptions,
    simPackageOptions
  ]);

  const expirationListCreator = useMutation(createExpirationList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/expiration-list");
      await showToast({
        icon: "success",
        title: "Exiration list added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const expirationListEditor = useMutation(editExpirationList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/expiration-list");
      await showToast({
        icon: "success",
        title: "Exiration list edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const port = Array.from({ length: 64 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));

  const handleReset = () => {
    // Reset the form fields
    setSelectedModem(null);
    setSelectedPackage(null);
    setSelectedPortList(null);
    setSelectedIsCalled(null);

    setValue("actualUntil", null);
    setValue("calledDate", null);
    setValue("balanceDate", null);
    setValue("topupDate", null);
  
 
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[expirationListModuleID];
    const formData = new FormData();

    var port_string = selectedPortList ? selectedPortList?.join(",") : "";

    isFieldAddableOrEditable(
      fieldIds.expirationList.modem_id,
      modulePermission
    ) &&
      selectedModem &&
      formData.append("modem_id", selectedModem.id);

    isFieldAddableOrEditable(
      fieldIds.expirationList.package_id,
      modulePermission
    ) && formData.append("sub_package_id", selectedPackage.id);

    isFieldAddableOrEditable(fieldIds.expirationList.port, modulePermission) &&
      formData.append("port", port_string);

    isFieldAddableOrEditable(
      fieldIds.expirationList.number,
      modulePermission
    ) &&
      data.number &&
      formData.append("number", data.number);

    isFieldAddableOrEditable(fieldIds.expirationList.iccid, modulePermission) &&
      data.iccid &&
      formData.append("iccid", data.iccid ? data.iccid : "");

    isFieldAddableOrEditable(
      fieldIds.expirationList.actual_until,
      modulePermission
    ) &&
      data.actualUntil &&
      formData.append("actual_until", convertDate(data.actualUntil));

    isFieldAddableOrEditable(
      fieldIds.expirationList.is_called,
      modulePermission
    ) &&
      selectedIsCalled &&
      formData.append("is_called", selectedIsCalled ? selectedIsCalled.id : "");

    isFieldAddableOrEditable(
      fieldIds.expirationList.call_date,
      modulePermission
    ) &&
      data.calledDate &&
      formData.append("call_date", convertDate(data.calledDate));

    isFieldAddableOrEditable(
      fieldIds.expirationList.latest_balance_amount,
      modulePermission
    ) &&
      data.latestBalanceAmount &&
      formData.append(
        "latest_balance_amount",
        data.latestBalanceAmount ? data.latestBalanceAmount : ""
      );

    isFieldAddableOrEditable(
      fieldIds.expirationList.balance_date,
      modulePermission
    ) &&
      data.balanceDate &&
      formData.append("balance_date	", convertDate(data.balanceDate));

    isFieldAddableOrEditable(
      fieldIds.expirationList.latest_topup_amount,
      modulePermission
    ) &&
      data.latestTopupAmount &&
      formData.append(
        "latest_topup_amount",
        data.latestTopupAmount ? data.latestTopupAmount : ""
      );

    isFieldAddableOrEditable(
      fieldIds.expirationList.topup_date,
      modulePermission
    ) &&
      data.topupDate &&
      formData.append("topup_date	", convertDate(data.topupDate));

    try {
      let response;
      if (isEdit) {
        response = await expirationListEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await expirationListCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);
        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  const handleMultiplePorts = (e) => {
    const selectAll = e.find((option) => option.value == "select");
    const unselectAll = e.find((option) => option.value == "unselect");

    if (selectAll) {
      const arrayOfValues = port.map((obj) => obj.value);
      setSelectedPortList(arrayOfValues);
      setSelectedExtraOption({ label: "Unsselect All", value: "unselect" });
    } else if (unselectAll) {
      setSelectedPortList("");
      setSelectedExtraOption({ label: "Select All", value: "select" });
    } else {
      const arrayOfValues = e.map((obj) => obj.value);
      setSelectedPortList(arrayOfValues);
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit expiration list" : "Add an entry to Expiration List"}
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.modem_id,
                            permissions?.permissions[expirationListModuleID]
                          ) &&
                            modemOptions && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Modem"
                                  selectedOption={selectedModem}
                                  options={modemOptions}
                                  handleSelect={(e) => setSelectedModem(e)}
                                  control={control}
                                  errors={errors}
                                  name="modem"
                                  disabled={isEdit}
                                  rules={{
                                    required: "Modem is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.package_id,
                            permissions?.permissions[expirationListModuleID]
                          ) &&
                            modemOptions && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Package"
                                  selectedOption={selectedPackage}
                                  options={simPackageOptions?.sub_packages||[]}
                                  handleSelect={(e) => setSelectedPackage(e)}
                                  control={control}
                                  errors={errors}
                                  name="modem"
                                  disabled={isEdit}
                                  rules={{
                                    required: "Modem is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.port,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <CustomMultipleDropdown
                                label="Port list"
                                defaultValue={selectedPortList}
                                selectedOption={selectedPortList}
                                options={
                                  port ? [selectedExtraOption, ...port] : []
                                }
                                handleSelect={handleMultiplePorts}
                                control={control}
                                errors={errors}
                                name="port_list"
                                showSelectAllOption={true}
                                disabled={isEdit}
                                rules={{
                                  required: "Port list is required.",
                                }}
                              />
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.number,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="form-field" md="4">
                              <Form.Group>
                                <label title="Number">Number</label>
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="number"
                                  onKeyPress={handleKeyPress}
                                  {...register("number", {
                                    maxLength: 20,
                                    minLength: 10,
                                    pattern: {
                                      value: /^[0-9]+$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.number?.type === "pattern" &&
                                    "Please enter a valid number."}
                                  {errors.number?.type === "maxLength" &&
                                    "It accepts maximum 20 characters."}
                                  {errors.number?.type === "minLength" &&
                                    "Enter minimum 10 characters."}
                                  {errors.number && errors.number.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.iccid,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="ICCID">ICCID</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("iccid", {
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.iccid?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.iccid && errors.iccid.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.actual_until,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Actual until">
                                  <span className="label-text">
                                    Actual until
                                  </span>
                                </label>

                                <br />
                                <Controller
                                  name="actualUntil" // Field name in the form data
                                  control={control}
                                  defaultValue={selectedActualUntil}
                                  {...register("actualUntil", {})}
                                  render={({ field }) => (
                                    <DatePicker
                                      value={field.value}
                                      onChange={(date) => field.onChange(date)}
                                      // disabledDate={disabledDate}
                                      format="DD/MM/YYYY"
                                      allowClear={false}
                                    />
                                  )}
                                />
                                <br></br>
                                <span className="error-message">
                                  {errors.actualUntil &&
                                    errors.actualUntil.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.is_called,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <CustomDrodown
                                label="Is called"
                                selectedOption={selectedIsCalled}
                                options={booleanOptions}
                                handleSelect={(e) => setSelectedIsCalled(e)}
                                control={control}
                                errors={errors}
                                name="isCalled"
                                rules={{
                                  required: "Is called is required.",
                                }}
                              />
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.call_date,
                            permissions?.permissions[expirationListModuleID]
                          ) &&
                           (
                              <Col className="" md="4">
                                <Form.Group>
                                  <label title="Called date">
                                    <span className="label-text">
                                      Called date
                                    </span>
                                    {selectedIsCalled &&
                                      watch("isCalled") &&
                                      ["1"].includes(selectedIsCalled.id) && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                  </label>

                                  <br />
                                  <Controller
                                    name="" // Field name in the form data
                                    control={control}
                                    defaultValue={selectedCalledDate}
                                    render={({ field }) => (
                                      <DatePicker
                                        value={field.value}
                                        onChange={(date) =>
                                          field.onChange(date)
                                        }
                                        format="DD/MM/YYYY"
                                        allowClear={true}
                                      />
                                    )}
                                    {...register("calledDate", {
                                      required:
                                        watch("isCalled") &&
                                        ["1"].includes(selectedIsCalled.id) &&
                                        true,
                                    })}
                                  />
                                  <br />
                                  {errors.calledDate && (
                                    <span className="error-message">
                                      {watch("isCalled") &&
                                        ["1"].includes(selectedIsCalled.id) &&
                                        errors.calledDate?.type ===
                                          "required" &&
                                        "Called date is required."}
                                      {errors.calledDate.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.latest_balance_amount,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Latest balance amount">
                                  <span className="label-text">
                                    Latest balance amount
                                  </span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="number"
                                  onKeyPress={(e) => handleKeyPress(e, true)}
                                  {...register("latestBalanceAmount", {
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.latestBalanceAmount?.type ===
                                    "maxLength" &&
                                    "It accepts maximum 50 characters."}

                                  {errors.latestBalanceAmount &&
                                    errors.latestBalanceAmount.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.balance_date,
                            permissions?.permissions[expirationListModuleID]
                          ) &&
                           (
                              <Col className="" md="4">
                                <Form.Group>
                                  <label title="Balance date">
                                    <span className="label-text">
                                      Balance date
                                    </span>
                                    {watch("latestBalanceAmount") && (
                                      <span className="error-message"> *</span>
                                    )}
                                  </label>

                                  <br />
                                  <Controller
                                    name="" // Field name in the form data
                                    control={control}
                                    defaultValue={selectedBalanceDate}
                                    render={({ field }) => (
                                      <DatePicker
                                        value={field.value}
                                        onChange={(date) =>
                                          field.onChange(date)
                                        }
                                        format="DD/MM/YYYY"
                                        allowClear={true}
                                      />
                                    )}
                                    {...register("balanceDate", {
                                      required:
                                        watch("latestBalanceAmount") && true,
                                    })}
                                  />
                                  <br />
                                  {errors.balanceDate && (
                                    <span className="error-message">
                                      {watch("latestBalanceAmount") &&
                                        errors.balanceDate?.type ===
                                          "required" &&
                                        "Balance date is required."}
                                      {errors.balanceDate.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.latest_topup_amount,
                            permissions?.permissions[expirationListModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Latest topup amount">
                                  <span className="label-text">
                                    Latest topup amount
                                  </span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="number"
                                  onKeyPress={(e) => handleKeyPress(e, true)}
                                  {...register("latestTopupAmount", {
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.latestTopupAmount?.type ===
                                    "maxLength" &&
                                    "It accepts maximum 50 characters."}

                                  {errors.latestTopupAmount &&
                                    errors.latestTopupAmount.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.expirationList.topup_date,
                            permissions?.permissions[expirationListModuleID]
                          ) &&
                        (
                              <Col className="" md="4">
                                <Form.Group>
                                  <label title="Top-up date">
                                    <span className="label-text">
                                      Top-up date
                                    </span>
                                    {watch("latestTopupAmount") && (
                                      <span className="error-message"> *</span>
                                    )}
                                  </label>

                                  <br />
                                  <Controller
                                    name="" // Field name in the form data
                                    control={control}
                                    defaultValue={selectedTopupDate}
                                    render={({ field }) => (
                                      <DatePicker
                                        value={field.value}
                                        onChange={(date) =>
                                          field.onChange(date)
                                        }
                                        format="DD/MM/YYYY"
                                        allowClear={true}
                                      />
                                    )}
                                    {...register("topupDate", {
                                      required:
                                        watch("latestTopupAmount") && true,
                                    })}
                                  />
                                  <br />
                                  {errors.topupDate && (
                                    <span className="error-message">
                                      {watch("latestTopupAmount") &&
                                        errors.topupDate?.type === "required" &&
                                        "Top-up date is required."}
                                      {errors.topupDate.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddExpirationListForm;
