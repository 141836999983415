import React from "react";

import UserRoleTable from "components/Table/UserRoleTable";

const UserRoleView = () => {
  return (
    <div>
      <UserRoleTable />
    </div>
  );
};

export default UserRoleView;
