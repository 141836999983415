import React, { useEffect, useState } from "react";

import { Form, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

import { sendEmailLink } from "API/authAPI";
import useSuccessMessage from "customHooks/useSuccessMessage";
import Loader from "components/Loader/Loader";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";

const SendAgainForm = () => {
  const [loadings, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showMessage, showSuccessMessage] = useSuccessMessage();
  const [displayServerMessage, showServerMessage] = useErrorMessage();

  useEffect(() => {
    if (location.state == null) {
      navigate("/forgot-password");
    }
  });

  const emailSender = useMutation(sendEmailLink, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      await showToast({
        icon: "success",
        title: response.message,
        position: "top-end",
        timer: 2000,
      });
      showSuccessMessage();
    },
  });
  const sendAgain = async () => {
    setLoading(true);
    try {
      const response = await emailSender.mutateAsync({
        email: location.state.email,
      });
    } catch (error) {
      setLoading(false);
      showServerMessage();
    }
  };
  return (
    <>

      <div className="container mx-auto px-4 h-full">
      <LoadingOverlay isLoading={loadings} />
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full  px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 ">
                <Form className="flex flex-col gap-2 ">
                  <>
                    <div className="text-blueGray-400 text-center mb-3 font-bold pt-1 ">
                      <h4 className="mb-4 text-center login-title">
                        Password reset link sent to email
                      </h4>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                      <Button
                        variant="primary"
                        type="button"
                        className="mt-3 mb-4 w-50 submit-btn "
                        onClick={(e) => navigate("/login")}
                      >
                        Login
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        className="mb-4 w-50 submit-btn"
                        onClick={sendAgain}
                      >
                        Send Again
                      </Button>
                    </div>
                  </>
                </Form>
                {showMessage && (
                  <FormAlertMessage message="Email Sent." type="success" />
                )}

                {displayServerMessage && (
                  <FormAlertMessage message={serverErrorMessage} type="error" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendAgainForm;