import { createSlice } from "@reduxjs/toolkit";

const roleSlice=createSlice({
    name:"rolePermission",
    initialState:[],
    reducers:{
        addPermissions(state,action){
            state.push(action.payload)
        },
    }
})

export {roleSlice}
export const {addPermissions}=roleSlice.actions
