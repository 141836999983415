import SoldCardTable from "components/Table/SoldCardTable";

export const fieldIds = {
  registration: {
    first_name: "1",
    last_name: "2",
    email: "3",
    role_id: "4",
    user_image: "5",
    phone_number: "6",
    address: "7",
    date_of_birth: "8",
    password: "9",
    status: "10",
    reset_tfa: "147",
  },
  supplier: {
    bank_name: "32",
    bank_account: "33",
    company_name: "22",
    contact_person: "23",
    street: "24",
    street_no: "25",
    zipcode: "26",
    city: "27",
    country: "28",
    phone_no: "29",
    email: "30",
    payment_type: "31",
    crypto_wallet_address: "34",
    paypal_address: "35",
    user_id: "36",
    status: "37",
    swift_code: "188"
  },
  storageBox: {
    name: "38",
    status: "39",
    sim_pacakges: "242",
  },
  servicePartner: {
    company_name: "40",
    address: "41",
    registration_no: "42",
    vat_number: "43",
    website1: "44",
    website2: "45",
    interval_before_reuse: "46",
    gray_profit: "47",
    green_profit: "48",
    yellow_profit: "49",
    short_name: "50",
    country: "69",
    username: "72",
    password: "73",
    automation_script: "241",
  },
  networkProvider: {
    name: "51",
    country: "52",
    deliverer: "53",
    ussd: "54",
    number_on_card: "55",
    at_cnum: "56",
    pin: "57",
    network_quality: "58",
    activation: "59",
    notes: "63",
    is_activation_required_for_sms: "136",
    avoid_deactivation_card: "137",
    min_day_for_call: "138",
    min_day_for_topup: "139",
    topup_amount: "140",
    charge_after_days: "141",
    charge_amount: "142",
    one_call_cost: "143",
    active_after_activation: "144",
    active_after_first_call_or_topup: "145",
    active_after_first_charge: "146",
  },
  modemList: {
    name: "64",
    defective_port: "65",
    term: "66",
    status: "67",
    service_partner_id: "68",
    name_from_sp: "70",
    type: "264",
  },
  pcModem: {
    pc_name: "74",
    modem_id: "75",
    anydesk_id: "76",
    password_full_access: "77",
    password_for_limited_access: "78",
    config_status: "81",
    is_software_prepared_for_sp1: "82",
    seller_shop_name: "79",
    windows_license_key: "80",
    is_software_prepared_for_sp2: "83",
    is_software_prepared_for_sp3: "84",
    notes: "85",
    running_service_partner_id: "86",
  },
  simPackage: {
    date_of_delevery: "87",
    name: "88",
    provider_id: "89",
    country: "90",
    supplier_id: "91",
    amount_of_sim: "92",
    more_card: "93",
    missing_cards: "94",
    expire_date_to_activate_sim: "101",
    expire_date_after_activation: "102",
    priority: "103",
    storage_before_use_id: "104",
    description_before_use: "105",
    storage_after_use_id: "106",
    description_after_use: "107",
    storage_for_defective_id: "108",
    description_for_defactive: "109",
    delivery_date_cz_de: "111",
    delivery_date_to_supplier: "112",
    thrown_away_card: "113",
    currency: "114",
    price_per_sim: "115",
    paid_amount: "116",
    paid_date: "117",
    amount_of_card_given_back: "110",
    total_sold_card: "120",
    note: "121",
    defective: "122",
    sub_name: "123",
    date_of_first_use: "124",
    date_of_last_use: "125",
    // last_used_date: "126",
    return_type: "95",
    usage_status: "127",
    used_service_partner: "128",
    cancellation: "129",
    price_in_total: "130",
    finished_to_send_back: "131",
    is_returned: "132",
    last_give_back_date: "96",
    is_unpacking_needed: "97",
    scan: "98",
    scan_phonenumber: "99",
    activation_status: "100",
    how_many_unused: "134",
    sold_in_total: "133",
    total_sim_cards: "135",
    total_sold_price_usd: "261",
    total_sold_price_eur: "262",
  },
  modemDashboard: {
    start_date: "150",
    avg_profit_per_sim: "153",
    modem_id: "149",
    duration: "151",
    actual_profit: "152",
    traffic_light: "154",
    start_btn: "155",
    stop_btn: "156",
    finish_btn: "157",
    log_btn: "158",
    add_sim: "159",
    take_out_sim: "160",
    log_no: "161",
    service_partner_id: "162",
    start_date: "150",
    note: "163",
    term: "164",
    vip_number: "165",
    packages: "166",
    total_time: "167",
    amount: "168",
    ports: "169",
    defective_port: "170",
    storage_after_use_id: "171",
    note_for_storage_after_use: "172",
    storage_before_use_id: "173",
    storage_for_defactive_id: "174",
    start_datetime: "175",
    end_datetime: "176",
    total_time_days: "177",
    total_profit: "178",
    log_avg_profit_per_sim: "179",
    finished: "180",
    log_avg_profit_per_min: "181",
    log_avg_profit_per_day: "182",
    how_many_defactive: "183",
    provider_id: "184",
    keep_or_give_back: "185",
    note_for_storage_before_use: "186",
    note_for_storage_for_defactive: "187",
    last_updated_rate_date:"256",
  },
  softwareLog: {
    modem_log_id: "189",
    service_partner_id: "190",
    event_status: "191",
    action: "193",
    is_error: "194",
    error_message: "195",
    action_taken: "196",
    action_taken_by_id: "197",
    created_at: "198",
  },
  dashboard: {
    sim_package_counter: "199",
    modem_counter: "200",
    storage_box_counter: "201",
    available_modem_counter: "202",
    sim_count_pie_chart: "203",
    profit_chart: "204",
    sim_expire: "205",
    green_modem: "206",
  },
  admin_setting: {
    profit_fetch_interval: "207",
  },

  expirationList: {
    expiration_date_for_activation: "224",
    expiration_date_after_activation: "225",
    days_from_last_balance: "226",
    next_needed_topup_date: "230",
    modem_id: "208",
    package_id: "209",
    port: "211",
    number: "212",
    iccid: "213",
    actual_until: "214",
    is_called: "215",
    call_date: "216",
    latest_balance_amount: "217",
    balance_date: "218",
    latest_topup_amount: "219",
    topup_date: "220",
    start_datetime: "210",
    end_datetime: "221",
    activation_status: "222",
    activation_date: "223",
    total_topup_amount: "227",
    next_needed_call_date: "228",
    rem_days_for_needed_call: "229",
    rem_days_for_needed_topup: "231",
    possible_call_without_further_topup: "232",
    latest_call_without_further_topup: "233",
    remider_date_for_call: "234",
    remider_date_for_topup: "235",
    created_by_id: "236",
    add_balance: "237",
    service_partner_id: "238",
    provider_id: "239",
  },
  custom_Currency: {
    currency_date: "243",
    created_by: "244",
    updated_date: "245",
    created_date: "246",
    currency_rate_eur: "247",
    id: "248",
    currency_rate_rub:"258",
    currency_rate_czk:"260"

  },
  SoldCard:{
    updated_at:"251",
    selling_price_per_sim:"253",
    selling_currenecy:"254",
    id:"255",
    sold_card_amount:"257",
    selling_date:"259",

  }
};
