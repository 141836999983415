import React, { useState } from "react";
import {  Form, Row, Button } from "react-bootstrap";

import "./2fasetupform.css";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";
import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import LoadingOverlay from "components/Overlay";

import { otpChecker } from "API/authAPI";
import { storeData } from "helpers/authHelper";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { loginRedirector } from "helpers/loginRedirector";
import { clearLocalStorage } from "helpers/authHelper";
import showToast from "components/Toast/Toast";
import { LoadingOutlined } from "@ant-design/icons";

const TwoFASetupForm = ({ userToken }) => {
  const [displayErrorMessage, showErrorMessage] = useErrorMessage();
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();
  const [loadings, setLoading] = useState(false);

  const totpChecker = useMutation(otpChecker, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      if (response.message.token["access"]) {
        setLoading(false);
        storeData(
          response.message.id,
          response.message.token.access,
          response.message.token.refresh,
          response.message.role
        );
        // navigate("/dashboard");
        try {
          const route = await loginRedirector();
          navigate(route);
        } catch (err) {
        setLoading(false);

          showServerMessage();
          await clearLocalStorage();
        }
      }
      setConfirmLoading(false)
    },
  });
  const onSubmit = async (data) => {
    setConfirmLoading(true)
    setApiError(false);
    try {
      const response = await totpChecker.mutateAsync({
        otp: data.totp,
        token: userToken.token,
      });
    } catch (error) {
      setConfirmLoading(false)
      if (error.response.status == 408) {
        setLoading(false);

        showErrorMessage();
        navigate("/");
        await showToast({
          icon: "error",
          title: "Session expired! please try again.",
          position: "top-end",
          timer: 2000,
        });
        // window.location.href="/login"
      } else if (error.response.status == 400) {
        setLoading(false);
        setApiError(error.response.data.message);
      } else {
        setLoading(false);

        showServerMessage();
      }
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    if (keyCode == 13) {
      handleSubmit(onSubmit)();
    }
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault(); // Prevent non-numeric input
    }
  };
  return (
    <div className="user-fields-wrapper form-field">
       <LoadingOverlay isLoading={loadings} />

      <Form
        className="p-4 border rounded shadow-sm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="mb-4 text-center">Enable 2FA</h2>
        <div className="otp-form-container">
          <div className="instructions">
            <ol>
              <li>
                Download and install an app (such as Google Authenticator or
                Authy) on your mobile device.
              </li>
              <li>Scan the QR code.</li>
              <li>
                Enter and verify the authentication code generated by the app.
              </li>
            </ol>
          </div>
          <div className="qr-code-container">
            {/* Display your QR code here */}
            <QRCodeSVG value={userToken.qrCode} />
          </div>
        </div>
        <p className="backup-code-container">
          <strong>Backup / Manual Code:</strong>
          <br />
          <div className="code-wrapper">
            <span className="copy-text">{userToken.backUpCode}</span>
            <CopyToClipboard text={userToken.backUpCode}>
              <span className="copy-icon">
                <MdContentCopy size={18} />
              </span>
            </CopyToClipboard>
          </div>
          <p className="backup-code-note">
            {" "}
            Securely save the following backup/manual code It helps if you loses
            the access to authentication app.
          </p>
        </p>
        <Form.Group controlId="formBasicToken">
          <Form.Label>Enter the code from your authentication app:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter code"
            className="mb-1"
            onKeyPress={handleKeyPress}
            autocomplete="off"
            {...register("totp", {
              required: true,
              maxLength: 6,
              minLength: 6,
              pattern: {
                value: /^[0-9]+$/,
              },
            })}
          />
          <span className="error-message">
            {errors.totp?.type === "required" && "TOTP is required."}
            {errors.totp?.type === "pattern" && "Please enter a valid number."}
            {errors.totp?.type === "maxLength" &&
              "It accepts maximum 6 characters."}
            {errors.totp?.type === "minLength" &&
              "It accepts minimum 6 characters."}
            {errors.totp && errors.totp.message}
          </span>
        </Form.Group>
        {displayServerMessage && (
          <FormAlertMessage type="error" message={serverErrorMessage} />
        )}
        {/* {displayErrorMessage && (
          <ErrorMessage message="Login Session Timeout" className="alert-message"/>         
        )} */}
        {apiError && <FormAlertMessage type="error" message={apiError} />}
        <Button
          variant="primary"
          type="submit"
          block={true}
          className="mt-3 mb-4 submit-btn login-btn"
          disabled={confirmLoading}
        >
          {confirmLoading ? (
            <>
              <LoadingOutlined /> Login
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default TwoFASetupForm;
