import React, { useState } from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserAddOutlined, SearchOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import LoadingOverlay from "components/Overlay";

import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import {
  getSuppliers,
  deleteSupplier,
  editSupplier,
  getPaymentType,
  getAllCountries,
} from "API/supplierApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const SupplierTableComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const supplierModuleID = parseInt(
    process.env.REACT_APP_SUPPLIER_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    payment_type_filter: selectedPaymentType,
    country_filter: selectedCountry,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: suppliers,
    refetch,
  } = useQuery(["suppliers", queryParams], getSuppliers, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingPaymentTypes,
    isError: isErrorPaymentTypes,
    error: errorPaymentTypes,
    data: paymentTypes,
  } = useQuery("paymentTypes", getPaymentType, {
    cacheTime: cacheDuration,
  });
  const paymentTypesData = { id: "", name: "All" };

  const paymentTypesTranformed = paymentTypes?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allCountriesData = { id: "", name: "All" };

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };
  const handlePaymentTypeSelect = (selectedPaymentType) => {
    setSelectedPaymentType(selectedPaymentType);
    refetch();
  };
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const supplierEditor = useMutation(editSupplier, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);

      refetch();
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await refetch();
      await permissionRefetch();
      setPermissionChanged(true)
    },
  });

  const removeSupplier = useMutation(deleteSupplier, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      setLoading(false);

      setLoading(false);
      await refetch();
    },
  });

  const handleEdit = (user) => {
    navigate("/edit-supplier", { state: { data: user } });
  };

  const handleDelete = async (supplier) => {
    const deleteApi = async () => {
      try {
        const response = await removeSupplier.mutateAsync({ id: supplier.id });
        return response;
      } catch (error) {
        setLoading(false);
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Can't delete supplier!",
      deleteSuccessMessage: "Supplier deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await supplierEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[supplierModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[supplierModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: fieldIds.supplier.company_name,
      title: "Company name",
      dataIndex: "company_name",
      sorter: (a, b) => {
        return a.company_name.localeCompare(b.company_name);
      },
    },
    {
      perm_id: fieldIds.supplier.contact_person,
      title: "Contact person",
      dataIndex: "contact_person",
      render: (contact_person) => (
        <div
          style={{
  
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {contact_person ? contact_person : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const valueA = a.contact_person || ""; // Fallback to an empty string if null
        const valueB = b.contact_person || ""; // Fallback to an empty string if null
        return valueA.localeCompare(valueB);
      },
    },
    {
      perm_id: fieldIds.supplier.street,
      title: "Street",
      dataIndex: "street",
      render: (street) => (
        <div
          style={{
  
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {street ? street : "-"}
        </div>
      ),
      sorter: (a, b) => (a.street ?? "").localeCompare(b.street ?? ""),
    },
    {
      perm_id: fieldIds.supplier.street_no,
      title: "Street number",
      dataIndex: "street_no",
      sorter: (a, b) =>
        String(a.street_no ?? "").localeCompare(String(b.street_no ?? "")),
    },
    {
      perm_id: fieldIds.supplier.zipcode,
      title: "Zip code",
      dataIndex: "zipcode",
      sorter: (a, b) =>
        String(a.zipcode ?? "").localeCompare(String(b.zipcode ?? "")),
    },
    {
      perm_id: fieldIds.supplier.city,
      title: "City",
      dataIndex: "city",
      sorter: (a, b) =>
        String(a.city ?? "").localeCompare(String(b.city ?? "")),
    },
    {
      perm_id: fieldIds.supplier.country,
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) =>
        String(a.country ?? "").localeCompare(String(b.country ?? "")),
    },
    {
      perm_id: fieldIds.supplier.phone_no,
      title: "Phone",
      dataIndex: "phone_no",
      render: (phone_number) => (
        <div className="center-aligned-text">
          {phone_number ? phone_number : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.phone_number ?? "").localeCompare(
          String(b.phone_number ?? "")
        ),
    },
    {
      perm_id: fieldIds.supplier.email,
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) =>
        String(a.email ?? "").localeCompare(String(b.email ?? "")),
    },
    {
      perm_id: fieldIds.supplier.payment_type,
      title: "Payment type",
      dataIndex: "payment_type",
      width: "50%",
      render: (payment_type) => (payment_type ? payment_type : "-"),
      sorter: (a, b) =>
        String(a.payment_type ?? "").localeCompare(
          String(b.payment_type ?? "")
        ),
    },
    {
      perm_id: fieldIds.supplier.bank_name,
      title: "Bank name",
      dataIndex: "bank_name",
      render: (bank_name) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {bank_name ? bank_name : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.bank_name ?? "").localeCompare(String(b.bank_name ?? "")),
    },
    {
      perm_id: fieldIds.supplier.bank_account,
      title: "Bank account",
      dataIndex: "bank_account",
      render: (bank_account) => (bank_account ? bank_account : "-"),
      sorter: (a, b) => Number(a.bank_account) - Number(b.bank_account),
    },
    {
      perm_id: fieldIds.supplier.swift_code,
      title: "Swift code",
      dataIndex: "swift_code",
      render: (swift_code) => (swift_code ? swift_code : "-"),
      sorter: (a, b) =>
        String(a.swift_code ?? "").localeCompare(String(b.swift_code ?? "")),
    },
    {
      perm_id: fieldIds.supplier.crypto_wallet_address,
      title: "Crypto wallet",
      dataIndex: "crypto_wallet_address",
      render: (crypto_wallet_address) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {crypto_wallet_address ? crypto_wallet_address : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.crypto_wallet_address ?? "").localeCompare(
          String(b.crypto_wallet_address ?? "")
        ),
    },
    {
      perm_id: fieldIds.supplier.paypal_address,
      title: "PayPal address",
      dataIndex: "paypal_address",
      render: (paypal_address) => (
        <div
          style={{
 
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {paypal_address ? paypal_address : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.paypal_address ?? "").localeCompare(
          String(b.paypal_address ?? "")
        ),
    },
    {
      perm_id: fieldIds.supplier.status,
      title: "Status",
      dataIndex: "status",
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 200,
      maxWidth: 200,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: suppliers?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={suppliers?.results}
        columns={columns}
        permissionSet={permissions.permissions[supplierModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesTransformed,
          payment_type: paymentTypes ? paymentTypesTranformed : "",
        }}
        showColumnState={showColumnState}
      />
    );
  }
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: supplierModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
  };

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Supplier
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[supplierModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={supplierModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    {permissions?.permissions[supplierModuleID].fields[31]
                      ?.view_access && paymentTypes ? (
                      <DropdownFilter
                        title={"Payment Type"}
                        selectedValue={selectedPaymentType}
                        items={[paymentTypesData, ...paymentTypesTranformed]}
                        onSelect={handlePaymentTypeSelect}
                        filterName="Payment Type"
                      />
                    ) : (
                      ""
                    )}

                    {permissions?.permissions[supplierModuleID].fields[28]
                      ?.view_access && allCountries ? (
                      <DropdownFilter
                        title={"Country"}
                        selectedValue={selectedCountry}
                        items={[allCountriesData, ...allCountriesTransformed]}
                        onSelect={handleCountrySelect}
                        filterName="Country"
                        enableSearch={true}
                      />
                    ) : (
                      ""
                    )}
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Company Name | Contact Person ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                   
                    {isAddAllowed(permissions.permissions[supplierModuleID]) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-supplier")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SupplierTableComponent;
