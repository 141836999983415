import axios from "axios";
import { clearLocalStorage } from "./authHelper";

const rememberMe = localStorage.getItem("rememberMe");
export const getAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
};

export const setAccessToken = (newAccessToken) => {
  localStorage.setItem("accessToken", newAccessToken);
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
};

export const setRefreshToken = (newRefreshToken) => {
  localStorage.setItem("refreshToken", newRefreshToken);
};

export const refreshAccessToken = async () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  try {
    const response = await axios.post(`${baseURL}/token/refresh`, {
      refresh: getRefreshToken(),
    });
    if (response.status == 200) {

      setAccessToken(response.data.access);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      //logout
      clearLocalStorage();
      window.location.href = '/login';
    }
    throw new Error("Access token refresh failed");
  }
};
