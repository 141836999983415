import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import LoadingOverlay from "components/Overlay";

import {
  getNetworkProvider,
  deleteNetworkProvider,
  getNetworkQualChoice,
  getActivationType,
  getAvoidDeactivationType,
} from "API/networkProviderApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const NetworkProviderTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedNetworkQuality, setSelectedNetworkQuality] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedAvoidDeac, setSelectedAvoidDeac] = useState("");
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const networkProviderModuleID = parseInt(
    process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    network_quality_filter: selectedNetworkQuality,
    search_filter: searchInput,
    avoid_deactivation_filter: selectedAvoidDeac,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: networkProvider,
    refetch,
  } = useQuery(["networkProvider", queryParams], getNetworkProvider, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingNetworkQuality,
    isError: isErrorNetworkQuality,
    error: errorNetworkQuality,
    data: networkQualityChoices,
  } = useQuery("networkQuality", getNetworkQualChoice, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingActivationType,
    isError: isErrorActivation,
    error: errorActivation,
    data: activationType,
  } = useQuery("activationType", getActivationType, {
    cacheTime: cacheDuration,
  });

  const activationTypeChoices = activationType?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const networkQualityTypes = networkQualityChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesData = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingAvoidDeacChoice,
    isError: isErrorAvoidDeacChoice,
    error: errorAvoidDeacChoice,
    data: avoidDeacChoice,
  } = useQuery("avoidDeactivationChoices", getAvoidDeactivationType, {
    cacheTime: cacheDuration,
  });

  const avoidDeactivationTypes = avoidDeacChoice?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allData = { id: "", name: "All" };
  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleNetworkSelect = (value) => {
    setSelectedNetworkQuality(value);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const networkProvrEditor = useMutation(deleteNetworkProvider, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true)
    },
  });
  const removeNetworkProvr = useMutation(deleteNetworkProvider, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen&& selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: networkProviderModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };
  const handleEdit = (user) => {
    navigate("/edit-network-provider", { state: { data: user } });
  };

  const handleDelete = async (networkProvider) => {
    const deleteApi = async () => {
      try {
        const response = await removeNetworkProvr.mutateAsync({
          id: networkProvider.id,
        });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Network provider is in use!",
      deleteSuccessMessage: "Network provider deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await networkProvrEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[networkProviderModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[networkProviderModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: fieldIds.networkProvider.name,
      title: "Provider name",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      perm_id: fieldIds.networkProvider.country,
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) =>
        String(a.country ?? "").localeCompare(String(b.country ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.deliverer,
      title: "Supplier",
      dataIndex: "deliverer",
      render: (deliverer) => (deliverer ? deliverer : "-"),
      sorter: (a, b) =>
        String(a.deliverer ?? "").localeCompare(String(b.deliverer ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.ussd,
      title: "USSD",
      dataIndex: "ussd",
      render: (ussd) => (ussd ? ussd : "-"),
      sorter: (a, b) =>
        String(a.ussd ?? "").localeCompare(String(b.ussd ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.number_on_card,
      title: "Number on card",
      dataIndex: "number_on_card",
      render: (number_on_card) =>
        number_on_card != null ? (number_on_card ? "Yes" : "No") : "-",
      sorter: (a, b) =>
        String(a.number_on_card ?? "").localeCompare(
          String(b.number_on_card ?? "")
        ),
    },
    {
      perm_id: fieldIds.networkProvider.at_cnum,
      title: "AT + CNUM",
      dataIndex: "at_cnum",
      render: (at_cnum) =>
        at_cnum != null ? (at_cnum ? "Yes" : "No") : "-",
      sorter: (a, b) =>
        String(a.at_cnum ?? "").localeCompare(String(b.at_cnum ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.pin,
      title: "PIN?",
      dataIndex: "pin",
      render: (pin) => (pin != null ? (pin ? "Yes" : "No") : "-"),
      sorter: (a, b) => String(a.pin ?? "").localeCompare(String(b.pin ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.network_quality,
      title: "Network quality",
      dataIndex: "network_quality",
      render: (network_quality) => (network_quality ? network_quality : "-"),
      sorter: (a, b) =>
        String(a.network_quality ?? "").localeCompare(
          String(b.network_quality ?? "")
        ),
    },
    {
      perm_id: fieldIds.networkProvider.activation,
      title: "Activation",
      dataIndex: "activation",
      sorter: (a, b) =>
        String(a.activation ?? "").localeCompare(String(b.activation ?? "")),
    },
    {
      perm_id: fieldIds.networkProvider.is_activation_required_for_sms,
      title: "Activate required for receive SMS",
      dataIndex: "is_activation_required_for_sms",
      render: (is_activation_required_for_sms) => (
        <div className="center-aligned-text">
          {is_activation_required_for_sms != null
            ? is_activation_required_for_sms
              ? "Yes"
              : "No"
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.is_activation_required_for_sms ?? "").localeCompare(
          String(b.is_activation_required_for_sms ?? "")
        ),
    },
    {
      perm_id: fieldIds.networkProvider.avoid_deactivation_card,
      title: "Avoid deactivation of card",
      dataIndex: "avoid_deactivation_card",
      render: (avoid_deactivation_card) => (
        <div className="center-aligned-text">
          {avoid_deactivation_card ? avoid_deactivation_card : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.avoid_deactivation_card ?? "").localeCompare(
          String(b.avoid_deactivation_card ?? "")
        ),
    },
    {
      perm_id: fieldIds.networkProvider.min_day_for_call,
      title: "Days for call",
      dataIndex: "min_day_for_call",
      render: (min_day_for_call) => (
        <div className="center-aligned-text">
          {min_day_for_call ? min_day_for_call : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.min_day_for_call) - Number(b.min_day_for_call),
    },
    {
      perm_id: fieldIds.networkProvider.min_day_for_topup,
      title: "Days for top-up",
      dataIndex: "min_day_for_topup",
      render: (min_day_for_topup) => (
        <div className="center-aligned-text">
          {min_day_for_topup ? min_day_for_topup : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.min_day_for_topup) - Number(b.min_day_for_topup),
    },
    {
      perm_id: fieldIds.networkProvider.topup_amount,
      title: "Amount of top-up",
      dataIndex: "topup_amount",
      render: (topup_amount) => (
        <div className="center-aligned-text">
          {topup_amount ? topup_amount : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.topup_amount) - Number(b.topup_amount),
    },
    {
      perm_id: fieldIds.networkProvider.charge_after_days,
      title: "Days for charge",
      dataIndex: "charge_after_days",
      render: (charge_after_days) => (
        <div className="center-aligned-text">
          {charge_after_days ? charge_after_days : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.charge_after_days) - Number(b.charge_after_days),
    },
    {
      perm_id: fieldIds.networkProvider.charge_amount,
      title: "Amount of charge",
      dataIndex: "charge_amount",
      render: (charge_amount) => (
        <div className="center-aligned-text">
          {charge_amount ? charge_amount : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.charge_amount) - Number(b.charge_amount),
    },
    {
      perm_id: fieldIds.networkProvider.one_call_cost,
      title: "Cost for one call",
      dataIndex: "one_call_cost",
      render: (one_call_cost) => (
        <div className="center-aligned-text">
          {one_call_cost ? one_call_cost : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.one_call_cost) - Number(b.one_call_cost),
    },
    {
      perm_id: fieldIds.networkProvider.active_after_activation,
      title: "Active after activation (no further action)",
      dataIndex: "active_after_activation",
      render: (active_after_activation) => (
        <div className="center-aligned-text">
          {active_after_activation ? active_after_activation : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.active_after_activation) - Number(b.active_after_activation),
    },
    {
      perm_id: fieldIds.networkProvider.active_after_first_call_or_topup,
      title: "Active after first call/top-up (no further action)",
      dataIndex: "active_after_first_call_or_topup",
      render: (active_after_first_call_or_topup) => (
        <div className="center-aligned-text">
          {active_after_first_call_or_topup
            ? active_after_first_call_or_topup
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.active_after_first_call_or_topup) -
        Number(b.active_after_first_call_or_topup),
    },
    {
      perm_id: fieldIds.networkProvider.active_after_first_charge,
      title: "Active after first charge (Days)",
      dataIndex: "active_after_first_charge",
      render: (active_after_first_charge) => (
        <div className="center-aligned-text">
          {active_after_first_charge ? active_after_first_charge : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.active_after_first_charge) -
        Number(b.active_after_first_charge),
    },
    {
      perm_id: fieldIds.networkProvider.notes,
      title: "Notes",
      dataIndex: "notes",
      width: 350,
      render: (notes) => (
        <div
          style={{
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {notes ? notes : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.notes ?? "").localeCompare(String(b.notes ?? "")),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: networkProvider?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={networkProvider?.results}
        columns={columns}
        permissionSet={permissions.permissions[networkProviderModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesData,
          network_quality: networkQualityTypes ? networkQualityTypes : "",
          activation: activationTypeChoices ? activationTypeChoices : "",
          avoid_deactivation_card: avoidDeactivationTypes
            ? avoidDeactivationTypes
            : "",
        }}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Network Provider
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover network-provider-table">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper filter-column"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[networkProviderModuleID]
                          .fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={networkProviderModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    {permissions?.permissions[networkProviderModuleID]
                      .fields[58]?.view_access && networkQualityTypes ? (
                      <DropdownFilter
                        title={
                          networkQualityTypes
                            ? networkQualityTypes.label
                            : "Network Quality"
                        }
                        selectedValue={selectedNetworkQuality}
                        items={[allData, ...networkQualityTypes]}
                        onSelect={handleNetworkSelect}
                        filterName="Network Quality"
                      />
                    ) : (
                      ""
                    )}

                    {permissions?.permissions[networkProviderModuleID]
                      .fields[137]?.view_access && avoidDeactivationTypes ? (
                      <DropdownFilter
                        title={
                          avoidDeactivationTypes
                            ? avoidDeactivationTypes.label
                            : "Avoid Deactivation Of Card"
                        }
                        selectedValue={selectedAvoidDeac}
                        items={[allData, ...avoidDeactivationTypes]}
                        onSelect={(e) => {
                          setSelectedAvoidDeac(e);
                          refetch();
                        }}
                        filterName="Avoid Deactivation Of Card"
                      />
                    ) : (
                      ""
                    )}
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Provider Name | Deliverer ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                    {/* {!allColumnShowTrue(
                      networkProviderModuleID,
                      permissions
                    ) && (
                      <ColumnHideShowButton
                        onClickHandler={(e) =>
                          setShowColumnState(!showColumnState)
                        }
                        columnState={showColumnState}
                      />
                    )} */}
                    {isAddAllowed(
                      permissions.permissions[networkProviderModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) =>
                          navigate("/add-network-provider")
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NetworkProviderTable;
