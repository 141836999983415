export const handleKeyPress = (event, allowFloating = false) => {
    const keyCode = event.which || event.keyCode;
  
    if (!allowFloating) {
      // Prevent non-numeric input
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    } else {
      // Allow numeric keys (0-9) and the period (.) character
      if (
        (keyCode >= 48 && keyCode <= 57) || // numeric keys
        keyCode === 46 // period (.)
      ) {
        // Check if the period character is already present in the input
        if (keyCode === 46 && event.target.value.includes('.')) {
          event.preventDefault(); // Prevent entering more than one period
        }
      } else {
        event.preventDefault(); // Prevent non-numeric input
      }
    }
  };