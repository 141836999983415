import React,{useState} from "react";
import { Table } from "antd";
import { getGreenModems } from "API/dashboardAPI";
import { useQuery } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import {  Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

const GreenProfitModem = () => {
  const {
    isLoading: isLoadingGreenModemData,
    isError: isErrorGreenModemData,
    error: errorGreenModemData,
    data: greenModemData,
  } = useQuery("greenModemData", getGreenModems, {
    cacheTime: cacheDuration,
  });
  const navigate = useNavigate();
  const [loadings, setLoading] = useState(false);
  

  const columns = [
    {
      title: "Modem name",
      dataIndex: "modem__name",
    },
    {
      title: "Profit",
      dataIndex: "total_profit",
    },
    {
      title: "Currency",
      dataIndex: "currency",
    },
  ];

  // Ensure each data item has a unique key
  const dataSource = greenModemData?.data?.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Card className="">
        <Card.Header className="">
          <Card.Title className="dashboard-element-title" as={"h5"}>
            Green modems
          </Card.Title>
        </Card.Header>
        <Card.Body className="green-profit-body">
          <Table dataSource={dataSource} columns={columns} pagination={false}/>
          <div className="pt-1 pb-1 pr-2 text-right see-more-btn"  style={{ cursor: "pointer",  textDecoration: "underline", color: "blue", }}>
            <a onClick={(e)=> navigate("/modem-dashboard", { state: { greenModemFilter: true } })}>See more modems</a>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GreenProfitModem;
