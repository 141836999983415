import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Row, Col, Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { getProfitData, getModemList, getSimPackList, getNetworkProviderList } from "API/dashboardAPI";
import { useQuery } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { DatePicker } from "antd";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import NetworkProvider from "pages/NetworkProvider/NetworkProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const { RangePicker } = DatePicker;

const ProfitBarChart = () => {
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedModem, setSelectedModem] = useState("");
  const [selectedNetworkProvider, setSelectedNetworkProvider] = useState("");
  const [selectedPeriodType, setSelectedPeriodType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedPackageType, setSelectedPackageType] = useState("");

  const queryParams = {
    // page_size: pageSize,
    // page: currentPage,
    package_id: selectedPackage,
    modem_id: selectedModem,
    network_provider_id: selectedNetworkProvider,
    period_type: selectedPeriodType,
    start_time: selectedStartDate,
    end_time: selectedEndDate,
    currency: selectedCurrency,
    is_sub_pakage: selectedPackageType == "used" ? 1 : "",
  };
  const {
    isLoading: isLoadingProfitData,
    isError: isErrorProfitData,
    error: errorProfitData,
    data: profitData,
    refetch: refetchProfitData,
  } = useQuery(["profitData", queryParams], getProfitData, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingModemData,
    isError: isErrorModemData,
    error: errorModemData,
    data: modemData,
  } = useQuery("modemDataDropdown", getModemList, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingNetworkProvider,
    isError: isErrorNetworkProvider,
    error: errorNetworkProvider,
    data: nettworkProviderData,
  } = useQuery("nettworkProviderDataDropdown", getNetworkProviderList, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingSimData,
    isError: isErrorSimData,
    error: errorSimData,
    data: simData,
  } = useQuery("simDataDropdown", getSimPackList, {
    cacheTime: cacheDuration,
  });

  useEffect(() => {
    if (profitData?.currency == "EUR") {
      setCurrencySymbol("€");
    } else if (profitData?.currency == "USD") {
      setCurrencySymbol("$");
    } else if (profitData?.currency == "CZK") {
      setCurrencySymbol("Kč");
    } else if (profitData?.currency == "RUB") {
      setCurrencySymbol("₽");
    }
  }, [profitData]);

  const newPackageChoices = simData?.sim_packages?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      type: "new",
    };
  });
  const usedPackageChoices = simData?.sub_packages?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      type: "used",
    };
  });

  const periodTypeOptions = [
    {
      id: 1,
      name: "Day",
    },
    {
      id: 2,
      name: "Month",
    },
    {
      id: 3,
      name: "Year",
    },
  ];

  const currencyTypeOptions = [
    {
      id: 1,
      name: "EUR",
    },
    {
      id: 2,
      name: "USD",
    },
    {
      id: 3,
      name: "CZK",
    },
    {
      id: 4,
      name: "RUB",
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Profit",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.formattedValue || "";
            return `${label}: ${currencySymbol} ${value}`;
          },
        },
      },
      datalabels: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Period", // Set the X-axis label here
          font: {
            size: 16, // Set the font size
            letterSpacing: "5px", // Set the letter spacing
            family: "'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'",
          },
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: `Total Profit ${profitData?.currency ?? ' '}`, // Set the Y-axis label here
          font: {
            size: 16, // Set the font size
            letterSpacing: 2, // Set the letter spacing
            family: "Roboto",
            family: "'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'",
          },
        },
        beginAtZero: true,
      },
    },
  };

  // const labels = !isLoadingProfitData
  //   ? profitData.profit_data.map((entry) => entry.date)
  //   : 0;
  // Populate profitMap with profit data from the API
  const profitMap = new Map();
  profitData?.profit_data?.forEach((entry) => {
    profitMap.set(entry.date, entry.total_profit);
  });

  // Initialize an array to hold profit data for each label

  // const labels = !isLoadingProfitData
  //   ? selectedPeriodType == 1 // Day period
  //     ? Array.from({ length: 30 }, (_, i) => {
  //         return dayjs()
  //           .subtract(29 - i, "day")
  //           .format("D MMM YYYY");
  //       }).reverse()
  //     : selectedPeriodType == 2 // Month period
  //     ? Array.from({ length: 12 }, (_, i) => {
  //         return dayjs()
  //           .subtract(11 - i, "month")
  //           .format("MMM YYYY");
  //       }).reverse()
  //     : profitData?.profit_data?.map((entry) => entry.date)
  //   : [];

  const labels = !isLoadingProfitData
    ? selectedStartDate && selectedEndDate // Check if start and end dates are selected
      ? (() => {
          const startDate = dayjs(selectedStartDate);
          const endDate = dayjs(selectedEndDate);
          const diffDays = endDate.diff(startDate, "day");
          const diffMonths = endDate.diff(startDate, "month");

          if (selectedPeriodType == 1) {
            return Array.from({ length: diffDays + 1 }, (_, i) => {
              return startDate.add(i, "day").format("D MMM YYYY");
            });
          } else if (selectedPeriodType == 2) {
            return Array.from({ length: diffMonths + 1 }, (_, i) => {
              return startDate.add(i, "month").format("MMM YYYY");
            });
          } else if (selectedPeriodType == 3) {
            return Array.from(
              { length: Math.ceil(diffMonths / 12) },
              (_, i) => {
                return startDate.add(i, "year").format("YYYY");
              }
            );
          }

          return [];
        })()
      : selectedPeriodType == 1 // Last 30 days
      ? Array.from({ length: 30 }, (_, i) => {
          return dayjs()
            .subtract(29 - i, "day")
            .format("D MMM YYYY");
        })
      : selectedPeriodType == 2 // Last 12 months
      ? Array.from({ length: 12 }, (_, i) => {
          return dayjs()
            .subtract(11 - i, "month")
            .format("MMM YYYY");
        })
      : profitData?.profit_data?.map((entry) => entry.date)
    : [];

  const profitValues = labels.map((date) => {
    let formattedDate;
    if (selectedPeriodType == 2) {
      formattedDate = dayjs(date).format("YYYY-M");
    } else if (selectedPeriodType == 1) {
      formattedDate = dayjs(date).format("YYYY-MM-DD");
    }
    const profit = profitMap.get(formattedDate ? formattedDate : date);

    formattedDate = "";
    return profit !== undefined ? profit : 0;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Profit",
        data: !isLoadingProfitData ? profitValues : 0,
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "grey",
      },
    ],
  };

  const handleDateChange = (dates, dateStrings) => {
    let formattedStartDate;
    let formattedEndDate;



    if (selectedPeriodType == 2) {
      formattedStartDate = dayjs(dateStrings[0])
        .startOf("month")
        .format("YYYY-MM-DD");
      formattedEndDate = dayjs(dateStrings[1])
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (selectedPeriodType == 3) {
      formattedStartDate = dayjs(dateStrings[0])
        .startOf("month")
        .format("YYYY-MM-DD");
      formattedEndDate = dayjs(dateStrings[1])
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      formattedStartDate = dateStrings[0];
      formattedEndDate = dateStrings[1];
    }

    setSelectedStartDate(
      formattedStartDate ? formattedStartDate : dateStrings[0]
    );
    setSelectedEndDate(formattedEndDate ? formattedEndDate : dateStrings[1]);
  };
  const getPickerType = () => {
    switch (selectedPeriodType) {
      case 2:
        return "month";
      case 3:
        return "year";
      default:
        return "date";
    }
  };
  const [defaultDateRange, setDefaultDateRange] = useState(null);
  useEffect(() => {
    const getDefaultDateRange = () => {
      const dateFormat = "YYYY/MM/DD";
      const yearFormat = "YYYY";
      const monthFormat = "YYYY/MM";

      switch (selectedPeriodType) {
        case 1: // Day
          // return [dayjs().subtract(29, 'days').format(dateFormat), dayjs().format(dateFormat)];
          return [
            dayjs(dayjs().subtract(29, "days"), dateFormat),
            dayjs(dayjs(), dateFormat),
          ];
        case 2: // Month
          //return [dayjs().subtract(11, 'months').format(monthFormat), dayjs().format(monthFormat)];
          return [
            dayjs(dayjs().subtract(11, "months").startOf("month")),
            dayjs(dayjs().endOf("month")),
          ];
        case 3: // Year
          const years = profitData?.profit_data?.map((entry) =>
            dayjs(entry.date).year()
          );

          // Check if the years array is not empty
          if (years && years.length > 0) {
            // Find the minimum and maximum years using Math.min and Math.max
            const minYear = Math.min(...years);
            const maxYear = Math.max(...years);

            // Return the minimum and maximum years
            console.log(maxYear, minYear);
          } else {
            // Handle the case when the years array is empty
            console.error("Years array is empty");
            // Return default values or handle the error as needed
          }

        
          return [
            dayjs(dayjs().subtract(11, "months").startOf("month")),
            dayjs(dayjs().endOf("month")),
          ];
        default:
          return undefined;
      }
    };
    setDefaultDateRange(getDefaultDateRange());
  }, [selectedPeriodType, profitData]);

  // console.log([dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)],"gett")
  return (
    <div>
      <Card className="profit-chart-card">
        <Card.Header className="profit-chart-header">
          <Card.Title className="dashboard-element-title">Profit</Card.Title>
        </Card.Header>
        <Card.Body className="">
          <div className="filters-row">
            <Row className="mb-3 gap-3 justify-space">
              <Col
                md="12"
                lg="12"
                className="d-flex align-items-center gap-2 filter-col-wrapper filter-column"
              >
                <DropdownFilter
                  title="Package"
                  selectedValue={selectedPackage}
                  items={[
                    { id: "", name: "All packages" },
                    ...(newPackageChoices || []),
                    ...(usedPackageChoices || []),
                  ]}
                  onSelect={(e) => {
                    setSelectedPackage(e);
                  }}
                  filterName="Package"
                  packageTypeSelect={(e) => {
                    setSelectedPackageType(e);
                  }}
                />
                <DropdownFilter
                  title="Modem"
                  selectedValue={selectedModem}
                  items={[{ id: "", name: "All modems" }, ...(modemData || [])]}
                  onSelect={(e) => setSelectedModem(e)}
                  filterName="Modem"
                />
                <DropdownFilter
                  title="Network Provider"
                  selectedValue={selectedNetworkProvider}
                  items={[{ id: "", name: "All network provider" }, ...(nettworkProviderData || [])]}
                  onSelect={(e) => setSelectedNetworkProvider(e)}
                  filterName="Networrk Provider"
                />
                <DropdownFilter
                  title="Currency"
                  selectedValue={selectedCurrency}
                  items={currencyTypeOptions || []}
                  onSelect={(e) => setSelectedCurrency(e)}
                  filterName="Currency"
                  defaultValue={{ key: 0, label: "EUR", value: 1 }}
                />
                <DropdownFilter
                  title="Period type"
                  selectedValue={selectedPeriodType}
                  items={periodTypeOptions || []}
                  onSelect={(e) => setSelectedPeriodType(e)}
                  filterName="Period type"
                  defaultValue={{ key: 0, label: "Day", value: 1 }}
                />
                {defaultDateRange && (
                 <RangePicker
                 onChange={handleDateChange}
                 value={
                   selectedStartDate && selectedEndDate
                     ? [dayjs(selectedStartDate), dayjs(selectedEndDate)]
                     : defaultDateRange
                 }
                 picker={getPickerType()}
                 className="dashboard-ant-picker"
               />
                )}
                
              </Col>
            </Row>
          </div>
        </Card.Body>
        <Card.Body className="profitChartBody">
          {/* <Bar options={options} data={data} /> */}
          <Line options={options} data={data} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProfitBarChart;
