import React from "react";

import LoginView from "views/Login/Login";

const Login = () => {
  return (
    <div>
      <LoginView />
    </div>
  );
};

export default Login;
