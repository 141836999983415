import React, { useState, useEffect } from "react";

import {  Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm,  } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import showToast from "components/Toast/Toast";
import { getSettingsList, editAdminSettings } from "API/adminSettingsAPI";
import { handleKeyPress } from "helpers/numberValidator";


const SettingForm = () => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [loadings, setLoading] = useState(false);

  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedStatus, setSelectedStatus] = useState("");

  const adminSettingsModuleID = parseInt(
    process.env.REACT_APP_ADMIN_SETTING_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingAdminSettingsList,
    isError: isErrorAdminSettingsList,
    error: errorAdminSettingsList,
    data: adminSettingsList,
  } = useQuery("adminSettingsListt", getSettingsList, {
    cacheTime: cacheDuration,
  });

  const statusOptions = [
    { id: "5", name: "5 minutes" },
    { id: "10", name: "10 minutes" },
    { id: "15", name: "15 minutes" },
    { id: "20", name: "20 minutes" },
  ];
  const handleStatusSelect = (selectedStatus,key) => {
    setSelectedStatus(selectedStatus);
    handleDropdownSubmt(key, selectedStatus.id);
  };

  useEffect(() => {
    adminSettingsList?.results?.forEach((field) => {
      setValue(field.key, field.value);
      if(field.key=="profit_fetch_interval"){
        const profitFetchVal = statusOptions.find(
          (val) => val.id == field.value
        );
        setSelectedStatus(profitFetchVal);
      }
    });
  }, [adminSettingsList]);

  const adminSettingsEditor = useMutation(editAdminSettings, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "Setting edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    reset();
  };
  const handleDropdownSubmt = async (key, value) => {
    setApiError(false);
    const formData = new FormData();

    formData.append("key", key);
    formData.append("value", value);

    try {
      const response = await adminSettingsEditor.mutateAsync({
        data: formData,
      });
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };
  const handleTextareaBlur = async (key, value) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[adminSettingsModuleID];
    const formData = new FormData();

    formData.append("key", key);
    formData.append("value", getValues(key));


    try {
      const response = await adminSettingsEditor.mutateAsync({
        data: formData,
      });
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">Admin settings</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row>
                          <Col className="user-fields-wrapper">
                            <Row>
                              {adminSettingsList?.results?.map((field) => {
                                const isDropdown =
                                  field.filed_type_value === "select";
                                const isCheckbox =
                                  field.filed_type_value === "checkbox";
                                const isRadio =
                                  field.filed_type_value === "radio";
                                const isTextarea =
                                  field.filed_type_value === "textarea";
                                const isFile =
                                  field.filed_type_value === "file";
                                const isNumber =
                                  field.filed_type_value === "number";
                                const isDate =
                                  field.filed_type_value === "date";
                                const isTime =
                                  field.filed_type_value === "time";
                                const isEmail =
                                  field.filed_type_value === "email";
                                const isURL = field.filed_type_value === "url";

                                Object.values(
                                  permissions?.permissions[
                                    adminSettingsModuleID
                                  ]
                                ).filter(
                                  (obj) =>
                                    obj.name === field.key &&
                                    obj.add_edit_accesss
                                );

                                return isDropdown ? (
                                  <Col className="" md="6" key={field.id}>
                                    <CustomDrodown
                                      label={field.title}
                                      selectedOption={selectedStatus}
                                      options={statusOptions}
                                      handleSelect={(e) => {
                                        handleStatusSelect(e,field.key);
                                      }}
                                      control={control}
                                      errors={errors}
                                      name={field.filed_type_value}
                                      rules={{ required: "Select status." }}
                                    />
                                  </Col>
                                ) : (
                                  <Col className="" md="6" key={field.id}>
                                    <Form.Group>
                                      <label title={field.title}>
                                        <span className="label-text">
                                          {field.title}
                                        </span>
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      </label>

                                      {isTextarea ? (
                                        <Form.Control
                                          as="textarea"
                                          name={field.key}
                                          defaultValue=""
                                          placeholder=""
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                            maxLength: 500, // You can adjust the max length as needed
                                            minLength: 3,
                                          })}
                                        />
                                      ) : isFile ? (
                                        <Form.Control
                                          type="file"
                                          name={field.key}
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                          })}
                                        />
                                      ) : isCheckbox ? (
                                        <>
                                          <Form.Check
                                            id="remember"
                                            onBlurCapture={(e) =>
                                              handleTextareaBlur(
                                                field.key,
                                                field.value
                                              )
                                            }
                                            {...register("remember", {})}
                                          />
                                          <label htmlFor="remember">
                                            {" "}
                                            Remember me
                                          </label>
                                        </>
                                      ) : isRadio ? (
                                        <div>
                                          <Form.Check
                                            type="radio"
                                            label={`Option 1 for ${field.title}`}
                                            name={field.key}
                                            value="option1"
                                            onBlurCapture={(e) =>
                                              handleTextareaBlur(
                                                field.key,
                                                field.value
                                              )
                                            }
                                            {...register(`${field.key}`, {
                                              required: true,
                                            })}
                                          />
                                          <Form.Check
                                            type="radio"
                                            label={`Option 2 for ${field.title}`}
                                            name={field.key}
                                            value="option2"
                                            onBlurCapture={(e) =>
                                              handleTextareaBlur(
                                                field.key,
                                                field.value
                                              )
                                            }
                                            {...register(`${field.key}`, {
                                              required: true,
                                            })}
                                          />
                                        </div>
                                      ) : isNumber ? (
                                        <Form.Control
                                          type="number"
                                          name={field.key}
                                          defaultValue=""
                                          placeholder=""
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                            maxLength: 50,
                                            minLength: 3,
                                          })}
                                          onKeyPress={handleKeyPress}
                                        />
                                      ) : isDate ? (
                                        <Form.Control
                                          type="date"
                                          name={field.key}
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                          })}
                                        />
                                      ) : isTime ? (
                                        <Form.Control
                                          type="time"
                                          name={field.key}
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                          })}
                                        />
                                      ) : isEmail ? (
                                        <Form.Control
                                          type="email"
                                          name={field.key}
                                          defaultValue=""
                                          placeholder=""
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                          })}
                                        />
                                      ) : isURL ? (
                                        <Form.Control
                                          type="url"
                                          name={field.key}
                                          defaultValue=""
                                          placeholder=""
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                          })}
                                        />
                                      ) : (
                                        <Form.Control
                                          name={field.key}
                                          defaultValue=""
                                          placeholder=""
                                          type={field.filed_type_value}
                                          onBlurCapture={(e) =>
                                            handleTextareaBlur(
                                              field.key,
                                              field.value
                                            )
                                          }
                                          {...register(`${field.key}`, {
                                            required: true,
                                            maxLength: 50,
                                            minLength: 3,
                                          })}
                                          onKeyPress={
                                            field.filed_type_value === "number"
                                              ? handleKeyPress
                                              : undefined
                                          }
                                        />
                                      )}

                                      <span className="error-message">
                                        {errors[field.key]?.type ===
                                          "required" &&
                                          `${field.title} is required.`}
                                        {errors[field.key]?.type ===
                                          "maxLength" &&
                                          `It accepts a maximum of ${
                                            isTextarea
                                              ? "500"
                                              : isNumber
                                              ? "50"
                                              : "characters"
                                          }.`}
                                        {errors[field.key]?.type ===
                                          "minLength" &&
                                          `Enter a minimum of ${
                                            isTextarea
                                              ? "3"
                                              : isNumber
                                              ? "3"
                                              : "characters"
                                          }.`}
                                        {errors[field.key]?.message &&
                                          errors[field.key]?.type !==
                                            "required" &&
                                          errors[field.key]?.type !==
                                            "maxLength" &&
                                          errors[field.key]?.type !==
                                            "minLength" &&
                                          errors[field.key]?.message}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingForm;
