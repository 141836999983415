import { makeRequest } from "helpers/apiHelper";

export const getSoldList = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/soldcard/list/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getSoldid = async (data) => {
  const url=`/soldcard/list/${data.queryKey[1]}/`
  return makeRequest("get", url );
};

export const createSoldList = async (data) => {
  return makeRequest("post", "/soldcard/list/", data);
};

export const editSoldList = async ({ id, data }) => {
  return makeRequest("patch", `/soldcard/list/${id}/`, data);
};

export const deleteSoldList = ({ id }) => {
  return makeRequest("delete", `/soldcard/list/${id}/`);
};

