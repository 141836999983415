import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import LoadingOverlay from "components/Overlay";

import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "../../styles/Ccm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import {
  getCurrencyChoices,
} from "API/simPackageAPI";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { DatePicker } from 'antd';
import moment from "moment";


import {
  editSoldList, createSoldList
} from "API/soldCardList";
const AddSoldCardListForm = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [loadings, setLoading] = useState(false);

  const [currencydate, setcurrencydate] = useState(null);

  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selecetedCurrency, setSelecetedCurrency] = useState(null);
  const ccmid = location.state?.id;
  const soldListModuleID = parseInt(
    process.env.REACT_APP_SOLD_CARD_MODULE_ID,
    10
  );

  dayjs.extend(customParseFormat);


  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
    data: currencyChoices,
  } = useQuery("currencyChoices", getCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencyChoicesData = currencyChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });




  useEffect(() => {
    if (location.state.id) {
      setIsEdit(true);
      const setvalueorcurrency = [{
        "CZK": {
          id: "3"
        }, "RUB": {
          id: "4"
        }, "EUR": {
          id: "1"
        }, "USD": {
          id: "2"
        }
      }]
      setSelecetedCurrency({ id: setvalueorcurrency[0][location.state.selling_currenecy]['id'], name: location?.state.selling_currenecy })
      setValue("selling_date", moment(moment(location.state.selling_date).format("YYYY-MM-DD"), 'YYYY-MM-DD'));
      setValue("sold_card_amount", location?.state.sold_card_amount);
      setValue("selling_price_per_sim", location.state.selling_price_per_sim)
      setValue("selling_currency", setvalueorcurrency[0][location.state.selling_currenecy]['id'])

      // setcurrencydate(  moment(moment(location.state.date).format("YYYY-MM-DD"), 'YYYY-MM-DD'));
    }
  }, [location.state]);

  const modemCreator = useMutation(createSoldList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/soldcard-list", { state: { id: location.state.sim_package_id } });
      await showToast({
        icon: "success",
        title: "Sold added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const modemEditor = useMutation(editSoldList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/soldcard-list", { state: { id: location.state.sim_package_id } });
      await showToast({
        icon: "success",
        title: "Sold edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    reset();
    setSelecetedCurrency(null)
    setValue("selling_date", '');
    setValue("sold_card_amount", '');
    setValue("selling_price_per_sim", '')
    setValue("selling_currency", '')
  };
  const handleDateChange = (date, dateString) => {
    console.log(dateString,"dateString")
    setcurrencydate(date);
    setValue("selling_date", moment(date).format("YYYY-MM-DD"));
  };

  const onSubmit = async (data) => {
    const dataObject = {
      selling_currenecy: data.selling_currency, // Renamed key
      sold_card_amount: data.sold_card_amount,
      sim_package_id: location.state?.sim_package_id,// Existing key
      selling_price_per_sim: data.selling_price_per_sim,
      selling_date: moment(data.selling_date).format("YYYY-MM-DD")
    };
    // return "Hello"
    try {
      let response;
      if (isEdit) {
        response = await modemEditor.mutateAsync({
          id: ccmid,
          data: dataObject,
        });
      } else {
        response = await modemCreator.mutateAsync(dataObject);

      }
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  }
  const disabledDate = (current) => {
    return current && (current < dayjs().subtract(3, 'month').startOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">{isEdit ? "Edit" : "Add"} Sold</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>Sold details</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.SoldCard.selling_price_per_sim,
                                permissions?.permissions[soldListModuleID]
                              ) && (
                                  <Col className="" md="4">
                                    <Form.Group>
                                      <label title="selling_price_per_sim">
                                        <span className="label-text">Selling price per SIM rate</span>
                                        <span className="error-message"> *</span>
                                      </label>
                                      <Controller
                                        control={control}
                                        name="selling_price_per_sim"
                                        rules={{
                                          required: true,
                                          maxLength: 50,
                                          validate: value => !isNaN(value) || "Selling Price Per Sim Rate must be a number"
                                        }}
                                        render={({ field }) => (
                                          <Form.Control
                                            defaultValue={
                                              location.state.selling_price_per_sim ?? ""
                                            }
                                            placeholder=""
                                            type="text"
                                            {...field}
                                          />
                                        )}
                                      />
                                      <span className="error-message">
                                        {errors.selling_price_per_sim?.type === "required" &&
                                          "Selling Price Per Sim Rate is required."}
                                        {errors.selling_price_per_sim?.type === "validate" &&
                                          errors.selling_price_per_sim.message}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                )}


                              {isFieldAddableOrEditable(
                                fieldIds.SoldCard.selling_currenecy,
                                permissions?.permissions[soldListModuleID]
                              ) &&
                                currencyChoices && (
                                  <Col className="" md="4">
                                    <Controller
                                      control={control}
                                      name="selling_currency"
                                      rules={{
                                        required: "Selling Currency is required.",
                                      }}
                                      render={({ field }) => (
                                        <CustomDrodown
                                          label={
                                            <span>
                                              Selling currency
                                              <span style={{ color: "red" }}> *</span>
                                            </span>
                                          }
                                          selectedOption={selecetedCurrency}
                                          options={currencyChoicesData}
                                          handleSelect={(e) =>
                                            setSelecetedCurrency(e)
                                          }
                                          control={control}
                                          errors={errors}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.SoldCard.sold_card_amount,
                                permissions?.permissions[soldListModuleID]
                              ) && (
                                  <Col className="" md="4">
                                    <Form.Group>
                                      <label title="sold_card_amount">
                                        <span className="label-text">Sold SIM cards</span>
                                        <span className="error-message"> *</span>
                                      </label>

                                      <Controller
                                        control={control}
                                        name="sold_card_amount"
                                        rules={{
                                          required: true,
                                          maxLength: 50,
                                          validate: value => !isNaN(value) || "Sold Card Amount must be a number"
                                        }}
                                        render={({ field }) => (
                                          <Form.Control
                                            defaultValue={
                                              location.state.sold_card_amount ?? ''
                                            }
                                            placeholder=''
                                            type="text"
                                            {...field}
                                          />
                                        )}
                                      />
                                      <span className="error-message">
                                        {errors.sold_card_amount?.type === "required" &&
                                          "Sold Card Amount is required."}
                                        {errors.sold_card_amount?.type === "validate" &&
                                          errors.sold_card_amount.message}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                )}


                              {isFieldAddableOrEditable(
                                fieldIds.SoldCard.selling_date,
                                permissions?.permissions[soldListModuleID]
                              ) && (
                                  <Col className="" md="4">
                                    <Form.Group>
                                      <label title="selling_date">
                                        <span className="label-text">Selling Date</span>
                                        <span className="error-message"> *</span>

                                      </label>

                                      <Controller
                                        name="selling_date"
                                        control={control}
                                        rules={{
                                          required: "Selling Date is required.",
                                          validate: (value) => {
                                            if (value === "") {
                                              return "Selling Date is required.";
                                            }
                                            const date = moment(value, "YYYY-MM-DD");
                                            return !date.isValid() ? "Date format should be YYYY-MM-DD" : !date.isBefore(dayjs().endOf("day")) ? "Date cannot be future date" : null;
                                          },
                                        }}
                                        render={({ field, formState: { errors } }) => (
                                          <DatePicker
                                            value={field.value ? moment(field.value).startOf('day') : ''}
                                            onChange={(date, dateString) => {
                                              handleDateChange(date, dateString)
                                              field.onChange(dateString ? moment(dateString).format('YYYY-MM-DD') : '')
                                            }}
                                            format="YYYY-MM-DD"
                                            disabledDate={disabledDate}
                                            className={errors.currencydate ? 'error' : ''}
                                          />
                                        )}
                                      />


                                      {errors.selling_date && (
                                        <span className="error-message">
                                          {errors.selling_date.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                )}

                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) && (<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddSoldCardListForm;
