// api/apiHelpers.js
import {
  createApiInstance,
  apiInstanceWithoutToken,
} from "../services/axiosInstance";
import { getAccessToken, refreshAccessToken } from "../helpers/tokenManager";

export const cacheDuration = parseInt(
  process.env.REACT_APP_API_CACHE_DURATION,
  10
);

export const makeRequest = async (method, url, data = null, headers = {}) => {
  const accessToken = getAccessToken();
  const apiInstance = createApiInstance(headers);

  try {
    const response = await apiInstance.request({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Unauthorized - token might be expired, attempt to refresh
      await refreshAccessToken();
    }
    throw error;
  }
};

export const makeRequestWithoutToken = async (method, url, data = null) => {
  const apiInstance = apiInstanceWithoutToken();

  try {
    const response = await apiInstance.request({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
