import React from "react";
import { Form, Select } from "antd";
import { Controller } from "react-hook-form";

const { Option } = Select;

const RecommendedDropdown = ({
  label,
  selectedOption,
  options,
  handleSelect,
  control,
  errors,
  name,
  rules,
  disabled,
}) => {
  const filteredOptions = options.filter((option) => option !== undefined);
  return (
    <div>
      <Form.Item required={!!rules}>
        <label className="custom-label" title={label}>
          <span className="label-text">{label}</span>
          {rules?.required != undefined && (
            <span className="error-message"> *</span>
          )}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={`${name}-dropdown`}
              showSearch
              placeholder={`Select ${label}`}
              disabled={disabled}
              value={selectedOption ? selectedOption.id : undefined}
              autoComplete="new-password"
              onChange={(value) => {
                const selectedOption = filteredOptions.find(
                  (option) => option.id === value
                );
                handleSelect(selectedOption);
                field.onChange(value);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children &&
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {filteredOptions?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.name}{(!option.recomanded && option.is_used)?" (in use)":""}
                  {(option.recommend||option.recomanded) && (
                    <span className="recommended-indicator">Recommended</span>
                  )}
                  {(option.wait_for_days) && (
                    <span className="warning-indicator"> Wait for {option.wait_for_days}</span>
                  )}
                </Option>
              ))}
            </Select>
          )}
          rules={rules}
        />
        {errors[name] && (
          <span className="error-message">{errors[name].message}</span>
        )}
      </Form.Item>
    </div>
  );
};

export default RecommendedDropdown;
