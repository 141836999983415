import React, { useEffect, useState } from "react";

import {  Form, Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import LoadingOverlay from "components/Overlay";

import "./loginform.css";
import { login } from "API/authAPI";
import TOTPForm from "components/TOTPForm/TOTPForm";
import { decryptAndRetrieve } from "helpers/authHelper";
import { encryptedAndStore } from "helpers/authHelper";
import TwoFASetupForm from "components/TOTPForm/2FASetupForm";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { LoadingOutlined } from "@ant-design/icons";
const LoginForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [loginFailed, setLoginfailed] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showSetupForm, setShowSetupForm] = useState(false);
  const [userToken, setUserToken] = useState();
  const [rememberState, setRememberState] = useState();
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [loadings, setLoading] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const loginUser = useMutation(login, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      if (response.message["is_setting_up"]) {
        setUserToken({
          token: response.message.token,
          qrCode: response.message["totp_secrete_key"],
          backUpCode: response.message["totp_secrete"],
        });
        setShowSetupForm(true);
      } else {
        setLoading(false);
        setUserToken(response.message.token);
        setShowOTPForm(true);
      }
      setConfirmLoading(false)
    },
  });

  const onSubmit = async (data) => {
    if (data.remember) {
      localStorage.setItem("rememberMe", true);
      encryptedAndStore(data.email, "a#X1sN1E9T");
      encryptedAndStore(data.password, "y9M&x4VA9J");
    } else {
      localStorage.clear();
    }
    setConfirmLoading(true)
    try {
      const response = await loginUser.mutateAsync({
        email: data.email,
        password: data.password,
      });
    } catch (error) {
      setConfirmLoading(false)
      if (error.response.status == 400) {
      setLoading(false);

        setLoginfailed(true);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  useEffect(() => {
    const rememberMeCheck = localStorage.getItem("rememberMe");
    const email = decryptAndRetrieve("a#X1sN1E9T");
    const password = decryptAndRetrieve("y9M&x4VA9J");

    if (rememberMeCheck && email && password) {
      setValue("email", email);
      setValue("password", password);
      setValue("remember", true);
    }
  }, []);

  const handleRememberChange = (e) => {
    const { checked } = e.target;
    if (!checked) {
      localStorage.clear();
    }
  };

  return (
    <>
      <div className="container mx-auto">
      <LoadingOverlay isLoading={loadings} />
        <div className="px-4  mx-auto">
          <div className="relative min-w-0 break-words shadow-lg rounded-lg  border-0">
            <div className="px-4 lg:px-10 py-10">
              {showOTPForm || showSetupForm ? (
                showOTPForm ? (
                  <TOTPForm userToken={userToken} />
                ) : (
                  <TwoFASetupForm userToken={userToken} />
                )
              ) : (
                <Form
                  className="flex flex-col gap-2 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="text-blueGray-400 text-center mb-3 font-bold pt-1">
                    <h4 className="mb-4 text-center login-title">Login</h4>
                  </div>

                  <>
                    <div className="relative w-full mb-2 user-fields-wrapper form-field">
                      {/* <Form.Group controlId="formBasicEmail">
                          <Form.Label className="block text-blueGray-600 text-xs font-bold mb-2">
                            Email address{" "}
                            <span className="error-message">*</span>
                          </Form.Label>

                          <Form.Control
                            type="email"
                            placeholder="Email"
                            className=""
                            {...register("email", {
                              required: true,
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                              },
                            })}
                          />
                          <span className="error-message">
                            {errors.email?.type === "required" &&
                              "Please enter email. Email is required."}
                            {errors.email?.type === "pattern" &&
                              "Please enter a valid email"}
                            {errors.email && errors.email.message}
                          </span>
                        </Form.Group> */}

                      <Form.Group>
                        <Form.Label className="" title="Email">
                          <span className="label-text">Email</span>{" "}
                          <span className="error-message">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                         
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                            },
                          })}
                          onChange={() =>setLoginfailed(false)}
                        ></Form.Control>
                        <span className="error-message">
                          {errors.email?.type === "required" &&
                            "Please enter email. Email is required."}
                          {errors.email?.type === "pattern" &&
                            "Please enter a valid email"}
                          {errors.email && errors.email.message}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="relative w-full mb-2 user-fields-wrapper form-field">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label className="" title="Password">
                          <span className="label-text">Password</span>{" "}
                          <span className="error-message">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className=""
                          {...register("password", {
                            required: true,
                          })}
                          onChange={() =>setLoginfailed(false)}
                        />
                        <span className="error-message">
                          {errors.password?.type === "required" &&
                            "Please enter password. Password is required."}
                          {errors.password && errors.password.message}
                        </span>
                      </Form.Group>
                    </div>
                    {loginFailed && (
                      <div className="error-message">
                        Incorrect Email or Password.
                      </div>
                    )}
                    {/* {displayServerMessage && (
                      <span className="error-message">
                        {serverErrorMessage}
                      </span>
                    )} */}

                    <Form.Group
                      as={Row}
                      controlId="formBasicCheckbox"
                      className="pl-4 pr-4"
                    >
                      <Col xs={12} md={6}>
                        <div className="custom-checkbox">
                          <Form.Check
                            id="remember"
                            {...register("remember", {})}
                            onChange={handleRememberChange}
                          />
                          <label htmlFor="remember"> Remember me</label>
                        </div>
                      </Col>
                      <Col xs={12} md={6} className="text-right">
                        <a href="/forgot-password">Forgot Password?</a>
                      </Col>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      block="true"
                      className="mt-1 mb-4 submit-btn login-btn"
                      disabled={confirmLoading}
                    >
                      {confirmLoading ? (
                        <>
                          <LoadingOutlined /> Login
                        </>
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </>
                </Form>
              )}
              {/* </Col> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
