import React from "react";

import ResetPasswordView from "views/ResetPassword/ResetPassword";

const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordView />
    </div>
  );
};

export default ResetPassword;
