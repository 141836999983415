// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import CancelButton from "components/CancelButton/CancelButton";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import usePermissions from "customHooks/usePermissions";
import { getUsersData, addActionUser } from "API/softwareLogAPI";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import { getUserID } from "helpers/authHelper";
import LoadingOverlay from "components/Overlay";

import {
  getCurrencyChoices,
} from "API/simPackageAPI";

const ActionByUserForm = ({ visible, onOk, onCancel, logID, event }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedUser, setSelectedUser] = useState();
  const [loadings, setLoading] = useState(false);

  const [selecetedCurrency, setSelecetedCurrency] = useState(null);


  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingUsersData,
    isError: isErrorUsersData,
    error: errorUsersData,
    data: usersData,
  } = useQuery("userDataForAction", getUsersData, {
    cacheTime: cacheDuration,
  });

  const userChoices = usersData?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.first_name + " " + innerArray.last_name,
    };
  });


  const {
    isLoading: isLoadingCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
    data: currencyChoices,
  } = useQuery("currencyChoices", getCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencyChoicesData = currencyChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const actionUserAdder = useMutation(addActionUser, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "Action taken by added!",
        position: "top-end",
        timer: 1500,
      });
      onCancel();
      setSelectedUser(null);
      reset();
      onOk()
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    const formData = new FormData();

    formData.append("action_taken_by_id", selectedUser ? selectedUser.id : "");
    formData.append("profit", data ? data.Profit : "");
    formData.append("currency", data ? data.currency : "");
    try {
      const response = await actionUserAdder.mutateAsync({
        id: logID,
        data: formData,
      });
    } catch (error) {
      if (error.response?.status == 400) {
      setLoading(false);

        setApiError(error.response?.data?.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  useEffect(() => {
    if (userChoices) {
      const loggedUser = userChoices.find((user) => getUserID() == user.id);

      if (loggedUser) {
        setSelectedUser(loggedUser);
        setValue("actionTakenBy", loggedUser);
      }
    }
  }, [usersData]);
  return (
    
    <Modal
      title="Add action"
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[]}
      onCancel={(e) => {
        onCancel(), setSelectedUser(null), reset();
      }}
    >
       <LoadingOverlay isLoading={loadings} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="user-fields-wrapper modal-form">
            <Row className="">
              {userChoices && (
                <Col className="" md="6">
                  <CustomDrodown
                    label={`Action taken by`}
                    selectedOption={selectedUser}
                    options={userChoices}
                    handleSelect={(e) => setSelectedUser(e)}
                    control={control}
                    errors={errors}
                    name={`actionTakenBy`}
                    rules={{
                      required: "Action taken by is required.",
                    }}
                  />
                </Col>
              )}
              {event == "Software stop" ? (
                <>
                  {currencyChoices && (
                    <Col className="" md="4">
                      <CustomDrodown
                        label="Currency"
                        selectedOption={selecetedCurrency}
                        options={currencyChoicesData}
                        handleSelect={(e) =>
                          setSelecetedCurrency(e)
                        }
                        control={control}
                        errors={errors}
                        name="currency"
                        rules={{
                          required: "currency is required.",
                        }}
                      />
                    </Col>)}
                  <Col className="" style={{ marginTop: '20px' }} md="6">

                    <Form.Group>
                      <label title="Profit">
                        <span className="label-text">Profit</span>
                        <span className="error-message"> *</span>
                      </label>

                      <Form.Control
                        defaultValue=''

                        placeholder=''
                        type="text"
                        {...register("Profit", {
                          required: true,
                          maxLength: 50,
                        })}
                      ></Form.Control>
                      <span className="error-message">
                        {errors.Profit?.type === "required" &&
                          "Profit Rate is required."}

                        {errors.Profit && errors.Profit}
                      </span>
                    </Form.Group>
                  </Col>

                </>
              ) : null}

            </Row>

          </Col>
        </Row>

        {(apiError || displayServerMessage) && (<Col md="12">
          {apiError && <FormAlertMessage message={apiError} type="error" />}
          {displayServerMessage && (
            <FormAlertMessage message={serverErrorMessage} type="error" />
          )}
        </Col>)}

        <div className="mt-3">
          <Button
            className="btn-fill pull-right  add-user-button"
            type="submit"
            variant="info"
          >
            Save
          </Button>
          <CancelButton
            onClickHandler={(e) => {
              onCancel(), setSelectedUser(null), reset();
            }}
          />
        </div>

        <div className="clearfix"></div>
      </Form>
    </Modal>
  );
};

export default ActionByUserForm;
