import React from 'react'

import AddRoleForm from 'components/AddRoleForm/AddRoleForm'

const AddRoleView = () => {
  return (
    <div><AddRoleForm/></div>
  )
}

export default AddRoleView