import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";

const ResetTFAButton = ({ onClickHandler, record }) => {
  return (
    <div key={`reset-${record?.index}`}>
      <Tooltip title={record.is_2fa_enabled ? "Reset 2FA" : "2FA is not enabled"}>
        <Button
          key={`reset-${record?.id}`}
          variant="primary"
          block="true"
          className="mt-3 mb-4 btn btn-three edit reset"
          onClick={onClickHandler}
          disabled={!record.is_2fa_enabled}
        >
          {/* <i className="fa-solid fa-unlock-keyhole"></i> */}
          <i className="fa fa-undo"></i>
        </Button>
      </Tooltip>
    </div>
  );
};

export default ResetTFAButton;
