import { makeRequest } from "helpers/apiHelper";

export const getSimPacakges = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/simpackage/sim-package/?${queryParamsString}`;
  return makeRequest("get", url);
};
export const getSimPacakgesAll = async () => {
  const url = `/simpackage/sim-package/?all=1`;
  return makeRequest("get", url);
};

export const createSimPackage = async (data) => {
  return makeRequest("post", "/simpackage/sim-package/", data);
};

export const editSimPackage = async ({ id, data }) => {
  return makeRequest("patch", `/simpackage/sim-package/${id}/`, data);
};

export const getReturnChoices = async () => {
  const url = `/simpackage/sim-package/get-all-return-type/`;
  return makeRequest("get", url);
};

export const getStorageChoices = async () => {
  const url = `/simpackage/sim-package/get-all-storagebox`;
  return makeRequest("get", url);
};

export const getActivationChoices = async () => {
  const url = `/simpackage/sim-package/get-all-activation-status-choice/`;
  return makeRequest("get", url);
};

export const getCurrencyChoices = async () => {
  const url = `/simpackage/sim-package/get-all-currency-choice/`;
  return makeRequest("get", url);
};
export const getSellingCurrencyChoices = async () => {
  const url = `/simpackage/sim-package/get-all-selling-currency-choice/`;
  return makeRequest("get", url);
};
export const getScanChoices = async () => {
  const url = `/simpackage/sim-package/get-all-scan-choice/`;
  return makeRequest("get", url);
};

export const getProviderChoices = async () => {
  const url = `/simpackage/sim-package/get-all-provider/`;
  return makeRequest("get", url);
};
export const getSupplierChoices = async () => {
  const url = `/simpackage/sim-package/get-all-supplier/`;
  return makeRequest("get", url);
};


export const getSellCurrencyChoices = async () => {
  const url = `/simpackage/sim-package/get-all-selling-currency-choice/`;
  return makeRequest("get", url);
};

export const returnSimPackage = ({ id }) => {
  return makeRequest("patch", `/simpackage/sim-package/${id}/update-is-return/`);
};
