// api/axiosInstance.js

import axios from "axios";
import { getAccessToken } from "../helpers/tokenManager";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const createApiInstance = (headers) => {
  const accessToken = getAccessToken();
  return axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers, // Merge additional headers
    },
  });
};

const apiInstanceWithoutToken = () => {
  return axios.create({
    baseURL: baseURL,
  });
};
export { createApiInstance, apiInstanceWithoutToken };
