import React from "react";

import EditRoleForm from "components/AddRoleForm/EditRoleForm";

const EditRoleView = () => {
  return (
    <div>
      <EditRoleForm />
    </div>
  );
};

export default EditRoleView;
