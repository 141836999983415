import { makeRequest } from "helpers/apiHelper";

export const getCounters = async () => {
  const url = `/dashboard/counter/`;
  return makeRequest("get", url);
};

export const getPieChartData = async () => {
  const url = `/dashboard/sim-card-usage`;
  return makeRequest("get", url);
};

export const getProfitData = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/dashboard/profit-chart?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getSimPackExpire = async () => {
  const url = `/dashboard/sim-expire/`;
  return makeRequest("get", url);
};

export const getGreenModems = async () => {
  const url = `/dashboard/green-modem/`;
  return makeRequest("get", url);
};

export const getModemList = async () => {
  const url = `/dashboard/profit-chart/get-modems/`;
  return makeRequest("get", url);
};

export const getNetworkProviderList = async () => {
  const url = `/dashboard/profit-chart/get-network-providers/`;
  return makeRequest("get", url);
};

export const getSimPackList = async () => {
  const url = `/dashboard/profit-chart/get-sim-pacakges/`;
  return makeRequest("get", url);
};
