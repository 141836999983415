import React from "react";
import RegisterTableComponent from "components/Table/RegisterTableComponent";

const RegistrationView = () => {
  return (
    <div>
      <RegisterTableComponent />
    </div>
  );
};

export default RegistrationView;
