import { makeRequest } from "helpers/apiHelper";

export const getModemDashData = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/modemdashboard/dashboard/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getTrafficLights = async () => {
  const url = `/modemdashboard/dashboard/get-traffic-light-choices`;
  return makeRequest("get", url);
};

export const startAction = async (data) => {
  return makeRequest("post", "/modemdashboard/action/start/", data);
};

export const stopAction = async (data) => {
  return makeRequest("post", "/modemdashboard/action/stop/", data);
};

export const finishAction = async (data) => {
  return makeRequest("post", "/modemdashboard/action/finish/", data);
};

export const getModemLogList = async (id) => {
  let queryParams = id.queryKey[2];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `modemdashboard/modem-log/?modem_id=${id.queryKey[1]}&?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getModemData = async (params) => {
  const url = `/modemdashboard/modem-log/${params.queryKey[1]}/get-modem-data/`;
  return makeRequest("get", url);
};

export const getNewSimPackages = async (params) => {
  let modem_id = params.queryKey[2];

  const url = `/modemdashboard/modem-log/${params.queryKey[1]}/get-sim-packages/${modem_id ? '?modem_id=' + modem_id : ""}`;
  return makeRequest("get", url);
};

export const getUsedSimPackages = async (params) => {
  let modem_id = params.queryKey[2];
  const url = `/modemdashboard/modem-log/${params.queryKey[1]}/get-sub-packages/${modem_id ? '?modem_id=' + modem_id : ""}`;
  return makeRequest("get", url);
};

export const getStorageBoxes = async (params) => {
  const { id, type } = params.queryKey[1];
  let url = `/modemdashboard/modem-log/get-all-storagebox/?package_id=${id}`;

  // Add the 'type' parameter conditionally
  if (type == 'used') {
    url += `&is_sub_package=1`;
  }

  return makeRequest("get", url);
};
export const getTerms = async () => {
  const url = `/modemdashboard/modem-log/get-term-type/`;
  return makeRequest("get", url);
};

export const createModemLog = async (data) => {
  return makeRequest("post", "/modemdashboard/modem-log/", data);
};

export const editModemLog = async ({ id, data }) => {
  return makeRequest("patch", `/modemdashboard/modem-log/${id}/`, data);
};

export const retrieveSimAddData = async (params) => {
  const url = `/modemdashboard/modem-log/add-sim/${params.queryKey[1]}/`;
  return makeRequest("get", url);
};

export const retrieveSimTakeOutData = async (params) => {
  const url = `/modemdashboard/modem-log/take-out-sim/${params.queryKey[1]}/`;
  return makeRequest("get", url);
};

export const takeOutSim = async ({ id, data }) => {
  return makeRequest("patch", `/modemdashboard/modem-log/take-out-sim/${id}/`, data);
};

export const addSim = async ({ id, data }) => {
  return makeRequest("patch", `/modemdashboard/modem-log/add-sim/${id}/`, data);
};


