import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import ShowPasswordButton from "components/Buttons/ShowPasswordButton";
import "./PasswordInput.css";

const PasswordInput = ({
  label,
  name,
  register,
  required,
  error,
  isDisabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
 

  return (
    <Form.Group>
      <label title={label}>
        {" "}
        <span className="label-text">{label}</span>
        {required && <span className="error-message"> *</span>}
      </label>

      <div className="password-input-container">
        <Form.Control
          defaultValue=""
          placeholder=""
          type={showPassword ? "text" : "password"}
          {...register(name, {
            required,
            maxLength: 50,          
          })}
          disabled={isDisabled}
        />
        <div className="show-password-button-container">
          <ShowPasswordButton
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            disabled={isDisabled}
          />
        </div>
      </div>
      <span className="error-message">
        {error?.type === "required" && `${label} is required.`}
        {error?.type === "maxLength" &&
          "It accepts a maximum of 50 characters."}
      </span>
    </Form.Group>
  );
};

export default PasswordInput;
