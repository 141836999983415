import { makeRequestWithoutToken, makeRequest } from "helpers/apiHelper";
import { getRefreshToken } from "helpers/tokenManager";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const login = async (credentials) => {
  return makeRequestWithoutToken("post", `/auth/login/`, credentials);
};

export const otpChecker = async (credentials) => {
  return makeRequestWithoutToken("post", `/auth/verify/tfa/`, credentials);
};

export const sendEmailLink = async (credentials) => {
  return makeRequestWithoutToken(
    "post",
    `/auth/reset/email-send/`,
    credentials
  );
};

export const resetPageTokenValidate = async (credentials) => {
  return makeRequestWithoutToken(
    "post",
    `/auth/reset/token-verify/`,
    credentials
  );
};

export const resetPassword = async (credentials) => {
  return makeRequestWithoutToken("post", `/auth/reset/password/`, credentials);
};

export const changePassword = async ({ id, credentials }) => {
  return makeRequest(
    "patch",
    `/registration/password/change/${id}/`,
    credentials
  );
};

export const tokenRefresh = async (credentials) => {
  return makeRequestWithoutToken("post", `/token/refresh/`, credentials);
};

export const getProfileDetails = async (userId) => {
  return makeRequest("get", `/registration/profile/${userId}/`);
};

export const updateProfileDetails = async ({id, data}) => {
  return makeRequest("patch", `/registration/profile/${id}/`,data);
};

export const userLogout = async () => {
  const refresh={"refresh":getRefreshToken()}
  return makeRequestWithoutToken("post", `/auth/logout/`, refresh);
};