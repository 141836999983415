import React from "react";
import CCMListTable from "components/Table/CcmTable";
const CCMListView = () => {
  return (
    <div>
      <CCMListTable />
    </div>
  );
};

export default CCMListView;
