import React from "react";
import { Table } from "antd";
import { Switch, Space, Tooltip } from "antd";
import { getUserID } from "helpers/authHelper";
import { useEffect } from "react";

const GenericTable = ({
  data,
  columns,
  permissionSet,
  showPagination,
  paginationData,
  handleToggle,
  choiceData,
  expandableRow,
  childColumns,
  handleExportCSV,
  showColumnState,
  rowSelection,
}) => {

  const calculateIndex = (currentPage, pageSize, dataIndex) => {
    return (currentPage - 1) * pageSize + dataIndex + 1;
  };
  const dataWithIndex = data?.map((item, index) => ({
    ...item,
    key: item.id,
    index: showPagination
      ? calculateIndex(
          paginationData.currentPage,
          paginationData.pageSize,
          index
        )
      : index + 1, // Add the index number
  }));

  const visibleColumns = columns.filter((column) => {
    // Check if the column has permission defined and if the permission allows viewing
    if (column.title == "Index") {
      return true;
    }
    const permission = permissionSet.fields[column.perm_id];
    if (column.title == "Action" && column.perm_id == 0) {
      if (column.buttons.props.children.length == 0) {
        return false;
      } else {
        return true;
      }
    }

    // Apply the conditional check only when showColumnState is false
    if (showColumnState == undefined || showColumnState == true) {
      // When showColumnState is false, return the column regardless of column_show
      return permission?.view_access;
    }

    // Check if column_show is true in the permissionSet
    return permission?.column_show && permission?.view_access;
  });
  const visibleColumnsForAll = columns.filter((column) => {
    // Check if the column has permission defined and if the permission allows viewing
    if (column.title == "Index") {
      return true;
    }
    const permission = permissionSet.fields[column.perm_id];
    if (column.title == "Action" && column.perm_id == 0) {
      if (column.buttons.props.children.length == 0) {
        return false;
      } else {
        return true;
      }
    }
    // Apply the conditional check only when showColumnState is false
    return permission?.view_access;
  });
  const renderStatusCell = (
    perm_id,
    id,
    status,
    isCurrentUserAllowed = true
  ) => {
    if (perm_id == 10) {
      isCurrentUserAllowed = id != getUserID() && id != 1;
    }
    if (
      permissionSet.fields[perm_id]?.view_access &&
      permissionSet.fields[perm_id]?.add_edit_access &&
      isCurrentUserAllowed &&
      permissionSet.edit_access
    ) {
      return (
        <Space direction="vertical">
          <Tooltip title={status === true ? "Active" : "In-Active"}>
            <Switch
              defaultChecked={status}
              onChange={(newStatus) => {
                handleToggle(id, newStatus);
              }}
            />
          </Tooltip>
        </Space>
      );
    } else {
      return (
        <Space direction="vertical">
          <Tooltip title={status === true ? "Active" : "In-Active"}>
            <Switch
              defaultChecked={status}
              onChange={(newStatus) => {
                handleToggle(id, newStatus);
              }}
              disabled={true}
            />
          </Tooltip>
        </Space>
      );
    }
  };
  const columnsWithRender = visibleColumns.map((column) => {
    if (column.title === "Status") {
      return {
        ...column,
        render: (_, record) =>
          renderStatusCell(column.perm_id, record.id, record.status),
      };
    }

    return column;
  });

  const columnsWithRenderAll = visibleColumnsForAll.map((column) => {
    if (column.title === "Status") {
      return {
        ...column,
        render: (_, record) =>
          renderStatusCell(column.perm_id, record.id, record.status),
      };
    }

    return column;
  });
  const dataWithNames = () => {
    const dataWithNames = dataWithIndex?.map((item) => {
      const updatedItem = { ...item };

      // Loop through the columns to find ones with ids to replace

      columnsWithRender?.forEach((column) => {
        if (
          column.title !== "Index" &&
          column.dataIndex &&
          choiceData[column.dataIndex]
        ) {
          const id = item[column.dataIndex];
          const choiceArray = choiceData[column.dataIndex];
          const choiceItem = choiceArray?.find((choice) => choice.id === id);

          if (choiceItem) {
            updatedItem[column.dataIndex] = choiceItem.name;
          }
        }
      });

      return updatedItem;
    });
    return dataWithNames;
  };

  const onChange = (pagination, sorter) => {
    if (paginationData) {
      paginationData.onPageChange(pagination.current, pagination.pageSize);
    }
  };

  const visibleChildColumns = () => {
    const visibleColumns = childColumns?.filter((column) => {
      const permission = permissionSet.fields[column.perm_id];

      // Apply the conditional check only when showColumnState is false
      if (showColumnState === undefined || showColumnState === true) {
        // When showColumnState is false, return the child column regardless of column_show
        return permission?.view_access;
      }

      // Check if column_show is true in the permissionSet for child columns
      return permission?.column_show && permission?.view_access;
    });
    return visibleColumns;
  };

  const expandedRowRender = (record) => {
    if (!record.child || visibleChildColumns().length == 0) {
      return null; // If record.child is not present, do not expand
    }

    const childDataArray = Object.values(record.child).map((childItem) => {
      const updatedChildItem = { ...childItem };

      // Loop through the child columns to find ones with ids to replace
      visibleChildColumns()?.forEach((column) => {
        if (column.dataIndex && choiceData[column.dataIndex]) {
          const id = childItem[column.dataIndex];
          const choiceArray = choiceData[column.dataIndex];
          const choiceItem = choiceArray?.find((choice) => {
            return choice.id == id;
          });

          if (choiceItem) {
            updatedChildItem[column.dataIndex] = choiceItem.name;
          }
        }
      });

      return updatedChildItem;
    });

    return (
      <Table
        columns={visibleChildColumns()}
        dataSource={childDataArray}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    handleExportCSV &&
      handleExportCSV(
        columnsWithRenderAll,
        visibleChildColumns(),
        choiceData ? dataWithNames() : dataWithIndex
      );
  }, []);

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Table
        columns={columnsWithRender}
        dataSource={choiceData ? dataWithNames() : dataWithIndex}
        onChange={onChange}
        align="center"
        pagination={
          showPagination // Use the showPagination prop to conditionally display pagination
            ? {
                current: paginationData.currentPage,
                pageSize: paginationData.pageSize,
                total: paginationData.count,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50"], // Customize page size options as needed
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }
            : false // Set to false to hide pagination when showPagination is false
        }
        rowSelection={rowSelection ? rowSelection : undefined}
        expandable={
          expandableRow
            ? {
                expandedRowRender,
                rowExpandable: (record) =>
                  record.child &&
                  Object.keys(record.child).length > 0 &&
                  visibleChildColumns().length > 0,
              }
            : undefined
        }
      />
    </div>
  );
};

export default GenericTable;
