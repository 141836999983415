import React from "react";
import { Button } from "react-bootstrap";

const EditButton = ({ onChangeHandler, record }) => {
  return (
    <div key={`edit-${record?.index}`}>
      <Button
        key={`edit-${record?.id}`}
        variant="primary"
        block="true"
        className="mt-3 mb-4 btn btn-three edit"
        onClick={onChangeHandler}
      >
        <i className="fas fa-edit"></i>
      </Button>
    </div>
  );
};

export default EditButton;
