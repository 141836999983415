import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LoadingOverlay from "components/Overlay";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddServicePartnerForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import {
  createServicePartners,
  editServicePartners,
  getScriptOptions,
  getSingleServicePartData,
} from "API/servicePartner";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { getAllCountries } from "API/supplierApi";
import { cacheDuration } from "helpers/apiHelper";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { handleKeyPress } from "helpers/numberValidator";

const AddServicePartnerForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedAutoMationScript, setSelectedAutoMationScript] = useState();
  const [loadings, setLoading] = useState(false);

  const servicePartnerModuleID = parseInt(
    process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingScripts,
    isError: isErrorScripts,
    error: errorScripts,
    data: automationScripts,
  } = useQuery("scripts", getScriptOptions, {
    cacheTime: cacheDuration,
  });

  const allScriptChoices = automationScripts?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingEditSPData,
    isError: isErrorEditSPData,
    error: errorEditSPData,
    data: editditSPData,
    refetch: refetchEditSPData,
  } = useQuery(
    ["editSPData", location?.state?.data?.id],
    getSingleServicePartData,
    {
      cacheTime: cacheDuration,
      enabled: location?.state?.data?.id ? true : false,
    }
  );

  useEffect(() => {
    if (location.state && allCountriesTransformed) {
      const serviceProviderData = location.state.data;

      const countryVal = allCountriesTransformed?.find((val) => {
        return !isNaN(Number(serviceProviderData.country))
          ? val.id == serviceProviderData.country
          : val.name == serviceProviderData.country;
      });

      setValue("companyName", serviceProviderData.company_name);
      setValue("shortName", serviceProviderData.short_name);
      setValue("address", serviceProviderData.address);
      setValue("registrationNo", serviceProviderData.registration_no);
      setValue("vatNumber", serviceProviderData.vat_number);
      setValue("website1", serviceProviderData.website1);
      setValue("website2", serviceProviderData.website2);
      setValue(
        "intervalBeforeReuse",
        serviceProviderData.interval_before_reuse
      );
      setValue("grayProfit", serviceProviderData.gray_profit);
      setValue("greenProfit", serviceProviderData.green_profit);
      setValue("yellowProfit", serviceProviderData.yellow_profit);
      setValue("username", serviceProviderData.username);
      setValue("password", serviceProviderData.password);
      setValue("country", countryVal);
      setSelectedCountry(countryVal);

      setIsEdit(true);
    }
  }, [allCountries]);
  useEffect(() => {
 
      if (allScriptChoices&&editditSPData) {
   
        const automationVal = allScriptChoices.find((val) => {
          return !isNaN(Number(editditSPData?.automation_script))
            ? val.id == editditSPData?.automation_script
            : val.name == editditSPData?.automation_script;
        });
        setValue("automation_script", automationVal);
        setSelectedAutoMationScript(automationVal);
      }
   
  }, [automationScripts,editditSPData]);

  const servicePartnerCreator = useMutation(createServicePartners, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false)

      navigate("/service-partner");
      await showToast({
        icon: "success",
        title: "Service Partner added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const servicePartnerEditor = useMutation(editServicePartners, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false)

      navigate("/service-partner");
      await showToast({
        icon: "success",
        title: "Service Partner edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[servicePartnerModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(
      fieldIds.servicePartner.company_name,
      modulePermission
    ) &&
      data.companyName &&
      formData.append("company_name", data.companyName);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.short_name,
      modulePermission
    ) &&
      data.shortName &&
      formData.append("short_name", data.shortName);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.address,
      modulePermission
    ) && formData.append("address", data.address ? data.address : "");

    isFieldAddableOrEditable(
      fieldIds.servicePartner.registration_no,
      modulePermission
    ) && formData.append("registration_no", data.registrationNo ? data.registrationNo : "");

    isFieldAddableOrEditable(
      fieldIds.servicePartner.vat_number,
      modulePermission
    ) && formData.append("vat_number", data.vatNumber ? data.vatNumber : "");

    isFieldAddableOrEditable(
      fieldIds.servicePartner.website1,
      modulePermission
    ) && formData.append("website1", data.website1 ? data.website1 : "");

    isFieldAddableOrEditable(
      fieldIds.servicePartner.website2,
      modulePermission
    ) && formData.append("website2", data.website2 ? data.website2 : "");

    isFieldAddableOrEditable(
      fieldIds.servicePartner.interval_before_reuse,
      modulePermission
    ) &&
      data.intervalBeforeReuse &&
      formData.append("interval_before_reuse", data.intervalBeforeReuse);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.gray_profit,
      modulePermission
    ) &&
      data.grayProfit &&
      formData.append("gray_profit", data.grayProfit);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.green_profit,
      modulePermission
    ) &&
      data.greenProfit &&
      formData.append("green_profit", data.greenProfit);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.yellow_profit,
      modulePermission
    ) &&
      data.yellowProfit &&
      formData.append("yellow_profit", data.yellowProfit);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.username,
      modulePermission
    ) &&
      data.username &&
      formData.append("username", data.username);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.password,
      modulePermission
    ) &&
      data.password &&
      formData.append("password", data.password);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.country,
      modulePermission
    ) &&
      selectedCountry &&
      formData.append("country", selectedCountry.id);

    isFieldAddableOrEditable(
      fieldIds.servicePartner.automation_script,
      modulePermission
    ) &&
      formData.append(
        "automation_script",
        selectedAutoMationScript?.id ? selectedAutoMationScript?.id : ""
      );

    if (isEdit) {
    } else {
    }

    try {
      let response;
      if (isEdit) {
        response = await servicePartnerEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await servicePartnerCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error?.response?.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  // const handleKeyPress = (event, type) => {
  //   const keyCode = event.which || event.keyCode;
  //   if (type == "number") {
  //     if (keyCode < 48 || keyCode > 57) {
  //       event.preventDefault(); // Prevent non-numeric input
  //     }
  //   } else if (type == "profit") {
  //     // Allow numeric input (0-9) and the hyphen key (-)
  //     const isNumericOrHyphen =
  //       (keyCode >= 48 && keyCode <= 57) || keyCode === 45 || keyCode === 46;
  //     if (!isNumericOrHyphen) {
  //       event.preventDefault(); // Prevent non-numeric or non-hyphen input
  //     }
  //   }
  // };
  // const validateGreenProfit = (value) => {
  //   const isValid = /^\d+(\.\d+)?-\d+(\.\d+)?$/.test(value);
  //   return isValid || 'Enter a valid "number-number" format for green profit.';
  // };
  useEffect(() => {
    if (watch("greenProfit") != "" && errors.yellowProfit) {
      trigger("yellowProfit");
    }
    if (watch("yellowProfit") != "" && errors.greenProfit) {
      trigger("greenProfit");
    }
  }, [watch("greenProfit"), watch("yellowProfit")]);
  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} service partner
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.company_name,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Company name">
                                  <span className="label-text">
                                    Company name
                                  </span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("companyName", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.companyName?.type === "required" &&
                                    "Company name is required."}
                                  {errors.companyName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.companyName?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.companyName &&
                                    errors.companyName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.short_name,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Short name">
                                  <span className="label-text">Short name</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("shortName", {
                                    required: true,
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.shortName?.type === "required" &&
                                    "Short name is required."}
                                  {errors.shortName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.shortName && errors.shortName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.country,
                            permissions?.permissions[servicePartnerModuleID]
                          ) &&
                            allCountries && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Country"
                                  selectedOption={selectedCountry}
                                  options={allCountriesTransformed}
                                  handleSelect={(e) => setSelectedCountry(e)}
                                  control={control}
                                  errors={errors}
                                  name="country"
                                  rules={{
                                    required: "Country is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.username,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Username">
                                  <span className="label-text">Username</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  autoComplete="off"
                                  {...register("username", {
                                    required: true,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.username?.type === "required" &&
                                    "Username is required."}

                                  {errors.username && errors.username.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.password,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <PasswordInput
                                label="Password"
                                name="password"
                                autoComplete="off"
                                register={register}
                                required={true}
                                error={errors.password}
                                value={getValues("password")}
                                isDisabled={false}
                              />
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.registration_no,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Registration number">
                                  <span className="label-text">
                                    Registration number
                                  </span>
                                </label>
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, "number")
                                  }
                                  {...register("registrationNo", {
                                    maxLength: 50,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.registrationNo?.type ===
                                    "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.registrationNo?.type ===
                                    "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.registrationNo &&
                                    errors.registrationNo.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.vat_number,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="VAT number">
                                  <span className="label-text">VAT number</span>
                                </label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, "number")
                                  }
                                  {...register("vatNumber", {
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.vatNumber?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.vatNumber && errors.vatNumber.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.website1,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Website 1">Website 1</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("website1", {
                                    maxLength: 255,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.website1?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.website1 && errors.website1.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.website2,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Website 2">Website 2</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("website2", {
                                    maxLength: 255,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.website2?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.website2 && errors.website2.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.interval_before_reuse,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Interval before reuse (Days)">
                                  <span className="label-text">
                                    Interval before reuse (Days)
                                  </span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, "number")
                                  }
                                  {...register("intervalBeforeReuse", {
                                    required: true,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.intervalBeforeReuse?.type ===
                                    "required" &&
                                    "Interval before reuse is required."}
                                  {errors.intervalBeforeReuse &&
                                    errors.intervalBeforeReuse.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.automation_script,
                            permissions?.permissions[servicePartnerModuleID]
                          ) &&
                            automationScripts && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Automation script"
                                  selectedOption={selectedAutoMationScript}
                                  options={allScriptChoices}
                                  handleSelect={(e) =>
                                    setSelectedAutoMationScript(e)
                                  }
                                  control={control}
                                  errors={errors}
                                  name="automation_script"
                                  rules={{
                                    required: "Automation script is required.",
                                  }}
                                />
                              </Col>
                            )}
                          <Col className="" md="4">
                            <Form.Group>
                              <label title="Grey profit (USD)">
                                <span className="label-text">
                                  Grey profit (USD)
                                </span>
                                <span className="error-message"> *</span>
                              </label>

                              <Form.Control
                                defaultValue="0"
                                placeholder="0"
                                type="text"
                                disabled={"true"}
                                onKeyPress={handleKeyPress}
                                {...register("grayProfit", {})}
                              ></Form.Control>
                              <span className="error-message">
                                {errors.grayProfit?.type === "required" &&
                                  "Grey profit is required."}
                                {errors.grayProfit?.type === "pattern" &&
                                  "Enter the range in the 'number-number' format (e.g., 0)."}
                                {errors.grayProfit && errors.grayProfit.message}
                              </span>
                            </Form.Group>
                          </Col>

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.yellow_profit,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Yellow profit (USD)">
                                  <span className="label-text">
                                    Yellow profit (USD)
                                  </span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder="(e.g., 100)"
                                  type="text"
                                  onKeyPress={(e) => handleKeyPress(e, true)}
                                  {...register("yellowProfit", {
                                    required: true,

                                    validate: (value) => {
                                      const greenProfit = watch("greenProfit");
                                      return (
                                        parseFloat(value) <
                                          parseFloat(greenProfit) ||
                                        "Yellow profit must be smaller than Green profit."
                                      );
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.yellowProfit?.type === "required" &&
                                    "Yellow profit is required."}
                                  {errors.yellowProfit?.type === "pattern" &&
                                    "Enter the range in the 'number-number' format (e.g., 100)."}
                                  {errors.yellowProfit &&
                                    errors.yellowProfit.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.green_profit,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Green profit (USD)">
                                  <span className="label-text">
                                    Green profit (USD)
                                  </span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder="(e.g., 200)"
                                  type="text"
                                  onKeyPress={(e) => handleKeyPress(e, true)}
                                  {...register("greenProfit", {
                                    required: true,

                                    validate: (value) => {
                                      const yellowProfit =
                                        watch("yellowProfit");
                                      return (
                                        parseFloat(value) >
                                          parseFloat(yellowProfit) ||
                                        "Green profit must be greater than Yellow profit."
                                      );
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.greenProfit?.type === "required" &&
                                    "Green profit is required."}
                                  {errors.greenProfit?.type === "pattern" &&
                                    "Enter the range in the 'number-number' format (e.g., 200)."}
                                  {errors.greenProfit &&
                                    errors.greenProfit.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.servicePartner.address,
                            permissions?.permissions[servicePartnerModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Address">Address</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  as="textarea"
                                  row={5}
                                  {...register("address", {
                                    maxLength: 200,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.address?.type === "maxLength" &&
                                    "It accepts maximum 200 characters."}
                                  {errors.address && errors.address.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}
                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddServicePartnerForm;
