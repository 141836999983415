import React from "react";
import ModemDashboardTable from "components/Table/ModemDashboardTable";

const ModemDashboardView = () => {
  return (
    <div>
      <ModemDashboardTable />
    </div>
  );
};

export default ModemDashboardView;
