import React from "react";
import UpdateProfileForm from "components/UpdateProfileForm/UpdateProfileForm";

const UpdateProfileView = () => {
  return (
    <div>
      <UpdateProfileForm />
    </div>
  );
};

export default UpdateProfileView;
