import React from 'react'

import AddUserView from 'views/Registration/AddUserView'

const AddUser = () => {
  return (
    <div>
        <AddUserView/>
    </div>
  )
}

export default AddUser