
import React from "react";
import { Form, Select } from "antd";
import { Controller } from "react-hook-form";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

const ServicePartnerDropdown = ({
  label,
  selectedOption,
  options,
  handleSelect,
  control,
  errors,
  name,
  rules,
  disabled,
}) => {

  return (
    <div>
      <Form.Item required={!!rules}>
        <label className="custom-label" title={label}>
          <span className="label-text">{label}</span>
          {rules?.required != undefined && (
            <span className="error-message"> *</span>
          )}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={`${name}-dropdown`}
              showSearch
              placeholder={`Select ${label}`}
              disabled={disabled}
              value={selectedOption ? selectedOption.id : undefined}
              autoComplete="new-password"
              onChange={(value) => {
                const selectedOption = options.find(
                  (option) => option.id === value
                );
                handleSelect(selectedOption);
                field.onChange(value);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children &&
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.map((option) => (
                <Option key={option.id} value={option.id}>
                {option.name} {option.username && option.country && `(${option.username} - ${option.country})`}

                </Option>
              ))}
            </Select>
          )}
          rules={rules}
        />
        {errors[name] && (
          <span className="error-message">{errors[name].message}</span>
        )}
      </Form.Item>
    </div>
  );
};

export default ServicePartnerDropdown;
