// ModalComponent.jsx
import React, { useState } from "react";
import { Modal, DatePicker } from "antd";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import CancelButton from "components/CancelButton/CancelButton";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import usePermissions from "customHooks/usePermissions";
import {  addCall } from "API/expirationListAPI";
import {  useMutation } from "react-query";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import { convertDate } from "helpers/dateValidator";
import LoadingOverlay from "components/Overlay";


const AddCallForm = ({ visible, onOk, onCancel, expirationListID }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedIsCalled, setSelectedIsCalled] = useState();
  const [selectedCalledDate, setSelectedCalledDate] = useState();
  const [loadings, setLoading] = useState(false);

  const {
    watch,
    control,
    register,
    formState: { errors },
    handleSubmit,

    reset,
  } = useForm();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  const addCallEditor = useMutation(addCall, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "Call added successfully!",
        position: "top-end",
        timer: 1500,
      });
      onOk();

      onCancel();
      setSelectedIsCalled(null);
      reset();
    },
  });

  const booleanOptions = [
    { id: "1", name: "Yes", boolVal: true },
    { id: "0", name: "No", boolVal: false },
  ];

  const onSubmit = async (data) => {
    setApiError(false);
    const formData = new FormData();

    formData.append("is_called", selectedIsCalled ? selectedIsCalled.id : "");

    formData.append(
      "called_date",
      data.calledDate ? convertDate(data.calledDate) : ""
    );

    try {
      const response = await addCallEditor.mutateAsync({
        id: expirationListID,
        data: formData,
      });
    } catch (error) {
      if (error.response?.status == 400) {
      setLoading(false);

        setApiError(error.response?.data?.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <Modal
      title="Add Call"
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[]}
      onCancel={(e) => {
        onCancel(), setSelectedIsCalled(null), reset();
      }}
    >
       <LoadingOverlay isLoading={loadings} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="user-fields-wrapper  modal-form">
            <Row className="">
              {
                <Col className="" md="6">
                  <CustomDrodown
                    label="Is called"
                    selectedOption={selectedIsCalled}
                    options={booleanOptions}
                    handleSelect={(e) => setSelectedIsCalled(e)}
                    control={control}
                    errors={errors}
                    name="isCalled"
                    rules={{
                      required: "Is called is required.",
                    }}
                  />
                </Col>
              }

              {
                <Col className="" md="6">
                  <Form.Group>
                    <label title="Called date">
                      <span className="label-text">Called date<span className="text-red-500">*</span></span>
                    </label>

                    <br />
                    <Controller
                      name="" // Field name in the form data
                      control={control}
                      defaultValue={selectedCalledDate}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          onChange={(date) => field.onChange(date)}
                          format="DD/MM/YYYY"
                          allowClear={false}
                        />
                      )}
                      {...register("calledDate", {
                        required:
                          watch("isCalled") &&
                          ["1"].includes(selectedIsCalled.id) &&
                          true,
                      })}
                    />
                    <br />
                    {errors.calledDate && (
                      <span className="error-message">
                        {watch("isCalled") &&
                          ["1"].includes(selectedIsCalled.id) &&
                          errors.calledDate?.type === "required" &&
                          "Called date is required."}
                        {errors.calledDate.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              }
            </Row>
          </Col>
        </Row>

        {(apiError || displayServerMessage) &&(<Col md="12">
          {apiError && <FormAlertMessage message={apiError} type="error" />}
          {displayServerMessage && (
            <FormAlertMessage message={serverErrorMessage} type="error" />
          )}
        </Col>)}

        <div className="mt-3">
          <Button
            className="btn-fill pull-right  add-user-button"
            type="submit"
            variant="info"
          >
            Save
          </Button>
          <CancelButton
            onClickHandler={(e) => {
              onCancel(), setSelectedIsCalled(null), reset();
            }}
          />
        </div>

        <div className="clearfix"></div>
      </Form>
    </Modal>
  );
};

export default AddCallForm;
