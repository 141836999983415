import React, { useState, useEffect } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import LoadingOverlay from "components/Overlay";

import { useNavigate, useLocation } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import { getServicePartner, editModemList } from "API/modemListApi";
import { getModemLogList } from "API/modemDashApi";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import ColumnHideShowButton from "components/Buttons/ColumnHideShowButton";
import { DatePicker } from "antd";
import AddSimModalForm from "components/Modal/AddSimModalForm";
import TakeOutSimForm from "components/Modal/TakeOutSimForm";
import GenericButton from "components/Buttons/GenericButton";
import dayjs from "dayjs";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const ModemLogTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [logIdFilter, setLogIdFilter] = useState("");
  const [addSimModalVisible, setAddSimModalVisible] = useState(false);
  const [takeOutSimModalVisible, setTakeOutSimModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [loadings, setLoading] = useState(false);

  const { RangePicker } = DatePicker;

  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  const location = useLocation();

  // filter log if it comes from software log data 
  useEffect(() => {
    if (location?.state?.data?.modem_log_id_filter){
      setLogIdFilter(location?.state?.data?.modem_log_id_filter)
    }
  }, [])
  
  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    term_filter: selectedTerm,
    search_filter: searchInput,
    start_date: startDate,
    end_date: endDate,
    log_id_filter: logIdFilter,
  };

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: modemLogList,
    refetch,
  } = location?.state
    ? useQuery(
        ["modemLog", location?.state?.data?.id, queryParams],
        getModemLogList,
        {
          cacheTime: cacheDuration,
        }
      )
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const {
    isLoading: isLoadingPaymentTypes,
    isError: isErrorPaymentTypes,
    error: errorPaymentTypes,
    data: servicePartnerTypes,
  } = useQuery("servicePartner", getServicePartner, {
    cacheTime: cacheDuration,
  });
  const paymentTypesData = { id: "", name: "All" };

  const servicePartnerChoices = servicePartnerTypes?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.short_name,
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allData = { id: "", name: "All" };

  const termOptions = [
    { id: "1", name: "Short term" },
    { id: "2", name: "Long term" },
  ];
  const spTypesOptions = [
    { id: "1", name: "Main" },
    { id: "2", name: "Second" },
    { id: "3", name: "Third" },
  ];
  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const modemEditor = useMutation(editModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);

      refetch();
    },
  });


  const handleEdit = (modemLogDetails) => {
    navigate("/edit-modem-log", {
      state: { editDetails: modemLogDetails, modemData: location?.state?.data },
    });
  };

  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      await refetch();
      await permissionRefetch();
      setPermissionChanged(true);
      setLoading(false);

    },
  });

  const handleToggle = async (id, status) => {
    const response = await modemEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: modemDashModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const showAddSimModal = () => {
    setAddSimModalVisible(true);
  };

  const showTakeOutSimModal = () => {
    setTakeOutSimModalVisible(true);
  };

  const handleAddSimOk = () => {
    // Your logic when OK button is clicked for AddSimModalForm
    setAddSimModalVisible(false);
  };

  const handleAddSimCancel = () => {
    // Your logic when Cancel button is clicked for AddSimModalForm
    setAddSimModalVisible(false);
  };

  const handleTakeOutSimOk = () => {
    // Your logic when OK button is clicked for TakeOutSimForm
    setTakeOutSimModalVisible(false);
  };

  const handleTakeOutSimCancel = () => {
    // Your logic when Cancel button is clicked for TakeOutSimForm
    setTakeOutSimModalVisible(false);
  };

  const actionButtonsleft = (record) => {
    const buttons = [];
    buttons.push(
      <GenericButton
        onClickHandler={() => {
          showAddSimModal();
          setSelectedRecord(record?.id);
        }}
        name="Add Sim"
        icon={<UserAddOutlined />}
        className="export-btn"
      />
    );
    buttons.push(
      <GenericButton
        onClickHandler={() => {
          showTakeOutSimModal();
          setSelectedRecord(record?.id);
        }}
        name="Take out sim"
        icon={<UserAddOutlined />}
        className="export-btn"
      />
    );

    return (
      <div className="action-buttons">
        {buttons}
        {selectedRecord && (
          <>
            <AddSimModalForm
              key={selectedRecord.id}
              visible={addSimModalVisible}
              onOk={handleAddSimOk}
              onCancel={handleAddSimCancel}
              modemID={location?.state?.data?.id}
              modemLogID={selectedRecord}
            />
            <TakeOutSimForm
              key={selectedRecord.id}
              visible={takeOutSimModalVisible}
              onOk={handleTakeOutSimOk}
              onCancel={handleTakeOutSimCancel}
              modemID={location?.state?.data?.id}
              modemLogID={selectedRecord}
            />
          </>
        )}
      </div>
    );
  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[modemDashModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }

    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtonsleft(),
      render: (_, record) => actionButtonsleft(record),
    },
    {
      perm_id: fieldIds.modemDashboard.log_no,
      title: "Log Id",
      dataIndex: "log_no",
      sorter: (a, b) => {
        return a.log_no.localeCompare(b.log_no);
      },
    },
    {
      perm_id: fieldIds.modemDashboard.service_partner_id,
      title: "Service Partner (from modem)",
      dataIndex: "service_partner_id",
      render: (service_partner_id) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {service_partner_id ? service_partner_id : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.service_partner_id ?? "").localeCompare(
          String(b.service_partner_id ?? "")
        ),
    },
    {
      perm_id: fieldIds.modemDashboard.last_updated_rate_date,
      title: "Last profit updated at",
      dataIndex: "last_updated_rate_date",
      render: (last_updated_rate_date) => (
        <div
          className="center-aligned-text "
          style={{
            whiteSpace: "pre-wrap",
            width: "max-content",
          }}
        >
          {last_updated_rate_date ? dayjs(last_updated_rate_date).format("D MMM YYYY, h:mm:ss A"): "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.term,
      title: "Term",
      dataIndex: "term_value",
      render: (term_value) => (
        <div
          style={{
            minWidth: "max-content",
          }}
        >
          {term_value ? term_value : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.term_value ?? "").localeCompare(String(b.term_value ?? "")),
    },
    {
      perm_id: fieldIds.modemDashboard.amount,
      title: "Total SIM amount",
      dataIndex: "total_sim",
      render: (total_sim) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {total_sim ? total_sim : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_sim) - Number(b.total_sim),
    },
    {
      perm_id: fieldIds.modemDashboard.start_datetime,
      title: "Start date time",
      dataIndex: "start_datetime",
      render: (start_datetime) => (
        <div
          className="center-aligned-text"
          style={{
            minWidth: "max-content",
          }}
        >
          {start_datetime
            ? dayjs(start_datetime).format("D MMM YYYY, h:mm:ss A")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.start_datetime);
        const dateB = dayjs(b.start_datetime);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.modemDashboard.end_datetime,
      title: "End date time",
      dataIndex: "end_datetime",
      render: (end_datetime) => (
        <div
          className="center-aligned-text"
          style={{
            minWidth: "max-content",
          }}
        >
          {end_datetime
            ? dayjs(end_datetime).format("D MMM YYYY, h:mm:ss A")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.end_datetime);
        const dateB = dayjs(b.end_datetime);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.modemDashboard.total_time_days,
      title: "Total time in days",
      dataIndex: "total_time_days",
      render: (total_time_days) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {total_time_days ? total_time_days.toFixed(2) : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_time_days) - Number(b.total_time_days),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_sim,
      title: "Average profit in original currency",
      dataIndex: "log_avg_profit_per_sim",
      render: (log_avg_profit_per_sim,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
           {log_avg_profit_per_sim !=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") +((log_avg_profit_per_sim % 1 !== 0) ? log_avg_profit_per_sim.toFixed(4):log_avg_profit_per_sim)
            : "-"}
        
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_sim) - Number(b.log_avg_profit_per_sim),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_sim,
      title: "Average profit in USD",
      dataIndex: "log_avg_profit_per_sim_usd",
      render: (log_avg_profit_per_sim_usd,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_sim_usd !=null
            ? `$${(log_avg_profit_per_sim_usd % 1 !== 0) ? log_avg_profit_per_sim_usd.toFixed(4):log_avg_profit_per_sim_usd}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_sim_usd) -
        Number(b.log_avg_profit_per_sim_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_sim,
      title: "Average profit in EUR",
      dataIndex: "log_avg_profit_per_sim_eur",
      render: (log_avg_profit_per_sim_eur,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_sim_eur!=null
            ? `€${(log_avg_profit_per_sim_eur % 1 !== 0) ? log_avg_profit_per_sim_eur.toFixed(4):log_avg_profit_per_sim_eur}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_sim_eur) -
        Number(b.log_avg_profit_per_sim_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in original currency",
      dataIndex: "total_profit",
      render: (total_profit,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
           {total_profit!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + ((total_profit % 1 !== 0) ? total_profit.toFixed(4):total_profit)
            : "-"}
          
        </div>
      ),
      sorter: (a, b) => Number(a.total_profit) - Number(b.total_profit),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in USD",
      dataIndex: "total_profit_usd",
      render: (total_profit_usd,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {total_profit_usd!=null ? `$${(total_profit_usd % 1 !== 0) ? total_profit_usd.toFixed(4):total_profit_usd}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_profit_usd) - Number(b.total_profit_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in EUR",
      dataIndex: "total_profit_eur",
      render: (total_profit_eur,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {total_profit_eur!=null ? `€${(total_profit_eur % 1 !== 0) ? total_profit_eur.toFixed(4):total_profit_eur}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_profit_eur) - Number(b.total_profit_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.end_datetime,
      title: "Finished ?",
      dataIndex: "end_datetime",
      render: (end_datetime) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {end_datetime != null ? (end_datetime ? "Yes" : "No") : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.finshed ?? "").localeCompare(String(b.finshed ?? "")),
    },
    {
      perm_id: fieldIds.modemDashboard.vip_number,
      title: "VIP numbers",
      dataIndex: "vip_number",
      render: (vip_number) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {vip_number ? vip_number : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.vip_number) - Number(b.vip_number),
    },

    {
      perm_id: fieldIds.modemDashboard.ports,
      title: "Ports",
      dataIndex: "port",
      render: (port, more) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {more.child && more.child.length > 0
            ? more.child
                .flatMap((child) => child.ports)
                .map(Number)
                .sort((a, b) => a - b)
                .join(", ")
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_min,
      title: "Average profit per minute",
      dataIndex: "log_avg_profit_per_min",
      render: (log_avg_profit_per_min,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
            {log_avg_profit_per_min!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + ((log_avg_profit_per_min % 1 !== 0) ? log_avg_profit_per_min.toFixed(4):log_avg_profit_per_min)
            : "-"}
        
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_min) - Number(b.log_avg_profit_per_min),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_min,
      title: "Average profit per minute in USD",
      dataIndex: "log_avg_profit_per_min_usd",
      render: (log_avg_profit_per_min_usd,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_min_usd!=null
            ? `$${(log_avg_profit_per_min_usd % 1 !== 0) ? log_avg_profit_per_min_usd.toFixed(4):log_avg_profit_per_min_usd}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_min_usd) -
        Number(b.log_avg_profit_per_min_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_min,
      title: "Average profit per minute in EUR",
      dataIndex: "log_avg_profit_per_min_eur",
      render: (log_avg_profit_per_min_eur,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_min_eur!=null
            ? `€${(log_avg_profit_per_min_eur % 1 !== 0) ? log_avg_profit_per_min_eur.toFixed(4):log_avg_profit_per_min_eur}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_min_eur) -
        Number(b.log_avg_profit_per_min_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_day,
      title: "Average profit per day",
      dataIndex: "log_avg_profit_per_day",
      render: (log_avg_profit_per_day,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
             {log_avg_profit_per_day!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + (log_avg_profit_per_day % 1 !== 0) ? log_avg_profit_per_day.toFixed(4):log_avg_profit_per_day
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_day) - Number(b.log_avg_profit_per_day),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_day,
      title: "Average profit per day in USD",
      dataIndex: "log_avg_profit_per_day_usd",
      render: (log_avg_profit_per_day_usd,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_day_usd!=null
            ? `$${(log_avg_profit_per_day_usd % 1 !== 0) ? log_avg_profit_per_day_usd.toFixed(4):log_avg_profit_per_day_usd}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_day_usd) -
        Number(b.log_avg_profit_per_day_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.log_avg_profit_per_day,
      title: "Average profit per day in EUR",
      dataIndex: "log_avg_profit_per_day_eur",
      render: (log_avg_profit_per_day_eur,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {log_avg_profit_per_day_eur!=null
            ? `€${(log_avg_profit_per_day_eur % 1 !== 0) ? log_avg_profit_per_day_eur.toFixed(4):log_avg_profit_per_day_eur}`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.log_avg_profit_per_day_eur) -
        Number(b.log_avg_profit_per_day_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.note,
      title: "Note",
      dataIndex: "note",
      render: (note) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {note ? note : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.note ?? "").localeCompare(String(b.note ?? "")),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];
  const childColumns = [
    {
      perm_id: fieldIds.modemDashboard.packages,
      title: "Package No.",
      dataIndex: "sim_package_name",
      width: "150px",
      render: (sim_package_name, more) => (
        <div
          style={{
            maxWidth: "150px",
            minWidth: "150px",
            width: "150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {sim_package_name
            ? sim_package_name
            : more.sub_package_name
            ? more.sub_package_name
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.provider_id,
      title: "Provider",
      dataIndex: "provider_name",
      width: "150px",
      render: (provider_name) => (
        <div
          style={{
            maxWidth: "150px",
            minWidth: "150px",
            width: "150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {provider_name ? provider_name : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.storage_before_use_id,
      title: "Storage before use with note",
      dataIndex: "storage_before_use_name",
      width: "200px",
      render: (storage_before_use_name, extra) => (
        <div
          style={{
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_before_use_name
            ? `${storage_before_use_name} ${
                extra.note_for_storage_before_use
                  ? `(${extra.note_for_storage_before_use})`
                  : ""
              }`
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.storage_after_use_id,
      title: "Storage after use with note",
      dataIndex: "storage_after_use_name",
      width: "200px",
      render: (storage_after_use_name, extra) => (
        <div
          style={{
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_after_use_name
            ? `${storage_after_use_name} ${
                extra.note_for_storage_after_use
                  ? `(${extra.note_for_storage_after_use})`
                  : ""
              }`
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.keep_or_give_back,
      title: "Keep / Give back",
      dataIndex: "keep_or_give_back_value",
      width: "150px",
      render: (keep_or_give_back_value) => (
        <div
          style={{
            maxWidth: "150px",
            minWidth: "150px",
            width: "150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {keep_or_give_back_value ? keep_or_give_back_value : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.amount,
      title: "Amount (no. of SIM)",
      dataIndex: "amount",
      width: "150px",
      render: (amount) => (
        <div
          style={{
            maxWidth: "150px",
            minWidth: "150px",
            width: "150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {amount ? amount : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.ports,
      title: "Port List",
      dataIndex: "ports",
      width: "200px",
      render: (ports) => (
        <div
          style={{
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            whiteSpace: "pre-wrap",
          }}
        >
          {ports ? ports.join(", ") : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.how_many_defactive,
      title: "How many defective",
      dataIndex: "how_many_defactive",
      width: "150px",
      render: (how_many_defactive) => (
        <div style={{
          maxWidth: "150px",
          minWidth: "150px",
          width: "150px",
          whiteSpace: "pre-wrap",
        }}>{how_many_defactive ? how_many_defactive : "-"}</div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.defective_port,
      title: "Which port's SIM are defective",
      dataIndex: "defective_port",
      width: "200px",
      render: (defective_port) => (
        <div
          style={{
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            whiteSpace: "pre-wrap",
          }}
        >
          {defective_port ? defective_port.join(", ") : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.storage_for_defactive_id,
      title: "Storage for defective sim with note ",
      dataIndex: "storage_for_defactive_name",
      width: "200px",
      render: (storage_for_defactive_name, extra) => (
        <div
          style={{
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_for_defactive_name
            ? `${storage_for_defactive_name} ${
                extra.note_for_storage_for_defactive
                  ? `(${extra.note_for_storage_for_defactive})`
                  : ""
              }`
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in original currency",
      dataIndex: "total_profit",
      width: "150px",
      render: (total_profit,more) => (
        <div
          className="right-aligned-text"
          style={{
            minWidth: "150px",
            width:"150px",
            whiteSpace: "pre-wrap",
          }}
        >
             {total_profit!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + total_profit.toFixed(2)
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in USD",
      dataIndex: "total_profit_usd",
      width: "150px",
      render: (total_profit_usd) => (
        <div
          className="right-aligned-text"
          style={{
            minWidth: "150px",
            width:"150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {total_profit_usd!=null ? `$${total_profit_usd.toFixed(2)}` : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.total_profit,
      title: "Total profit in EUR",
      dataIndex: "total_profit_eur",
   
      render: (total_profit_eur) => (
        <div
          className="right-aligned-text"
          style={{
            minWidth: "150px",
            width:"150px",
            whiteSpace: "pre-wrap",
          }}
        >
          {total_profit_eur!=null ? `€${total_profit_eur.toFixed(2)}` : "-"}
        </div>
      ),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: modemLogList?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={modemLogList?.results}
        columns={columns}
        permissionSet={permissions.permissions[modemDashModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesTransformed,
          term: termOptions,
          service_partner_id: servicePartnerTypes ? servicePartnerChoices : "",
          type: spTypesOptions,
        }}
        expandableRow={true}
        childColumns={childColumns}
        showColumnState={showColumnState}
      />
    );
  }

  const handleDateChange = (dates, dateStrings) => {

    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> {`Modem Log (${location?.state?.data?.modem_name})`}
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[modemDashModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={modemDashModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    <Col
                      md="6"
                      className="d-flex align-items-center gap-2 filter-col-wrapper"
                    >
                      <RangePicker onChange={handleDateChange} />
                    </Col>
                    
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | SP name | Package name"
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    {isAddAllowed(
                      permissions.permissions[modemDashModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) =>
                          navigate("/add-modem-log", {
                            state: { modemData: location?.state?.data },
                          })
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ModemLogTable;
