import React from "react";
import { Button } from "react-bootstrap";
const AddButton = ({ onClickHandler }) => {
  return (
    <Button
      variant="primary"
      className="ms-2  mx-2 add-button max-wid"
      onClick={onClickHandler}
    >
      <div className=" flex gap-2 add-icon">
      <i className="fa fa-plus"></i><p>Add</p>
      </div>
    </Button>
  );
};

export default AddButton;
