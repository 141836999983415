import React from "react";

import { Navigate } from "react-router-dom";

import { isAuthenticated } from "helpers/authHelper";


const ProtectedRoute = ({ children }) => {
  let auth = isAuthenticated();
  return auth ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
