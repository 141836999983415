import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { getCounters } from "API/dashboardAPI";
import { useQuery, useQueryClient } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import usePermissions from "customHooks/usePermissions";
import { fieldIds } from "constants/moduleFields";

const Counter = () => {
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const dashboardModuleID = parseInt(
    process.env.REACT_APP_DASHBOARD_MODULE_ID,
    10
  );
  const {
    isLoading: isLoadingCounters,
    isError: isErrorCounters,
    error: errorCounters,
    data: counters,
  } = useQuery("counters", getCounters, {
    cacheTime: cacheDuration,
  });

  return (
    <div>
      <Row>
        {permissions?.permissions[dashboardModuleID].fields[
          fieldIds.dashboard.modem_counter
        ]?.view_access ? (
          <Col lg="6" sm="12" md="6">
            <Card className="card-stats">
              <Card.Body className="counter-card">
                <Row>
                  <Col xs="12">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">Modems</p>
                      <Card.Title as="h4">
                        {counters?.modem_counter || 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
        {permissions?.permissions[dashboardModuleID].fields[
          fieldIds.dashboard.sim_package_counter
        ]?.view_access ? (
          <Col lg="6" sm="12" md="6">
            <Card className="card-stats">
              <Card.Body className="counter-card">
                <Row>
                  <Col xs="12">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category"> Available SIM Packages</p>
                      <Card.Title as="h4">
                        {counters?.sim_package_counter || 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
        {permissions?.permissions[dashboardModuleID].fields[
          fieldIds.dashboard.storage_box_counter
        ]?.view_access ? (
          <Col lg="6" sm="12" md="6">
            <Card className="card-stats">
              <Card.Body className="counter-card">
                <Row>
                  <Col xs="12">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-app text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">Available Storage Boxes</p>
                      <Card.Title as="h4">
                        {counters?.storage_box_counter || 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
        {permissions?.permissions[dashboardModuleID].fields[
          fieldIds.dashboard.available_modem_counter
        ]?.view_access ? (
          <Col lg="6" sm="12" md="6">
            <Card className="card-stats">
              <Card.Body className="counter-card">
                <Row>
                  <Col xs="12">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">Available Modems</p>
                      <Card.Title as="h4">
                        {counters?.available_modem_counter || 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};

export default Counter;
