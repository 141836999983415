import React from "react";
import SoftwareLogTable from "components/Table/SoftwareLogTable";

const SoftwareLogView = () => {
  return (
    <div>
      <SoftwareLogTable />
    </div>
  );
};

export default SoftwareLogView;
