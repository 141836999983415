import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";

const ModemDashButtons = ({ onClickHandler, record, name, icon,partner }) => {
  let tooltipTitle = "";
  let buttonColor = "";

  // Determine tooltip title and button color based on partner status
  switch (partner?.status) {
    case "start event in queue":
    case "stop event in queue":
      tooltipTitle = `${partner?.name} is in queue`;
      buttonColor = "modem-button-in-queue";
      break;
    case "start event in process":
    case "stop event in process":
      tooltipTitle = `${partner?.name} is in progress`;
      buttonColor = "modem-button-in-process";
      break;
    case "started":
      tooltipTitle = `${partner?.name} is started`;
      buttonColor = "modem-button-started";
      break;
    case "Stopped":
      tooltipTitle = `${partner?.name} is stopped`;
      buttonColor = "modem-button-stopped";
      break;
    case "error":
      tooltipTitle = `${partner?.name} is not started/stopped due to error`;
      buttonColor = " modem-button-error";
      break;
    default:
      tooltipTitle = `${partner?.name}`;
      buttonColor = ""; // Set default button color
  }

 
  return (
    <div key={`reset-${record?.index}`}>
      <Tooltip title={tooltipTitle}>
        <Button
          key={`reset-${record?.id}`}
          variant="primary"
          block="true"
          className={`mt-3 mb-4 btn btn-three edit reset ${buttonColor}`}
          onClick={onClickHandler}
          disabled={record?.button_disabled}
          style={{ backgroundColor: buttonColor }}
        >
          <i className={icon}></i>{name}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ModemDashButtons;
