import React from "react";
import { Button } from "react-bootstrap";
import { UserAddOutlined } from "@ant-design/icons";

const GenericButton = ({ onClickHandler, name, icon }) => {
  return (
    <Button
      variant="primary"
      className="ms-2  mx-2 add-button max-wid"
      onClick={onClickHandler}
    >
      <div className="flex gap-2 add-icon">
        
          {icon}
          <p>{name}</p>
        </div>
     
    </Button>
  );
};

export default GenericButton;
