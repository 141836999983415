import React from 'react'

import RegistrationView from 'views/Registration/RegistrationView'

const Registration = () => {
  return (
    <div>
        <RegistrationView/>
    </div>
  )
}

export default Registration