import { makeRequest } from "helpers/apiHelper";

export const getSoftwareLogs = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/softwarelog/software-log/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getUsersData = async () => {
    const url = `/softwarelog/software-log/get-users/`;
    return makeRequest("get", url);
};
  

export const addActionUser = async ({ id, data }) => {
return makeRequest("patch", `/softwarelog/action-by/${id}/`, data);
};
