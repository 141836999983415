import React, { useState } from "react";
import { Tooltip } from "antd";
import ShowPasswordButton from "components/Buttons/ShowPasswordButton";
import "./PasswordHideShow.css";

const PasswordHideShow = ({ password }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const handleCopyClick = (password) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(password)
        .then(() => {
          setTooltipVisible(true);
          setTimeout(() => {
            setTooltipVisible(false);
          }, 1500); // Duration for which the tooltip is visible (in milliseconds)
        })
        .catch((error) => {
          message.error("Error copying text to clipboard");
          console.error("Clipboard writeText error:", error);
        });
    } else {
      // Fallback for browsers that do not support navigator.clipboard
      const textarea = document.createElement("textarea");
      textarea.value = password;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 1500); // Duration for which the tooltip is visible (in milliseconds)
    }
  };
  return (
    <>
      <Tooltip title={tooltipVisible ? "Copied!" : "Copy"}>
        <span onClick={e=>handleCopyClick(password)} className="passwordTextCopy">
          {showPassword ? password : "********"}
        </span>
      </Tooltip>
      <ShowPasswordButton
        elementType="anchor"
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
    </>
  );
};

export default PasswordHideShow;
