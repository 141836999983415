import React, { useRef } from "react";
import { useLocation} from "react-router-dom";

import OuterNavbar from "components/OuterNavbar/OuterNavbar";
import OuterFooter from "components/OuterFooter/OuterFooter";
import './outerlayer.css'

function OuterLayout({ children }) {
  const mainPanel = useRef(null);

  return (
    <>
      <div className="wrapper">
        <div className="main-panel outer-layer" ref={mainPanel}>
          <OuterNavbar />
          <div className="content pl-0 pr-0">{children}</div>
          <OuterFooter />
        </div>
      </div>
    </>
  );
}

export default OuterLayout;
