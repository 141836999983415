import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { SearchOutlined } from "@ant-design/icons";

const SearchFilter = ({ onChangeHandler, valueInput, placeholder }) => {
  return (
    <InputGroup className="border-lighty ml-2 search-wrapper search-filter">
      <SearchOutlined />

      <Form.Control
        type="text"
        placeholder={placeholder}
        value={valueInput}
        onChange={onChangeHandler}
        className="ms-2 mx-2 border-0"
      />
    </InputGroup>
  );
};

export default SearchFilter;
