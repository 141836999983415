import React, { useState } from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { UserAddOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import "./TableComponent.css";
import LoadingOverlay from "components/Overlay";

import { useNavigate } from "react-router-dom";
import { getRoles, deleteUserRole, editRole } from "API/userRoleAPI";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { Breadcrumbs } from "Breadcrumb";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ConfirmModal from "components/Modal/Modal";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";
import dayjs from "dayjs";

const UserRoleTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [loadings, setLoading] = useState(false);

  const userRoleModuleID = parseInt(
    process.env.REACT_APP_USERROLE_MODULE_ID,
    10
  );
  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
  };

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: roles,
    refetch,
  } = useQuery(["roles", queryParams], getRoles, {
    cacheTime: cacheDuration,
  });

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const removeUserRole = useMutation(deleteUserRole, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      await refetch();
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {

    onMutate: () => {
      setLoading(true);
    },    onSuccess: async (response) => {
      setLoading(false);
      await refetch();
      await permissionRefetch();
      setPermissionChanged(true);
    },
  });
  const userRoleEditor = useMutation(editRole, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      refetch();
    },
  });

  const handleEdit = (role) => {
    navigate("/edit-role", { state: { data: role } });
  };

  const handleDelete = async (role) => {
    const deleteApi = async () => {
      try {
        const response = await removeUserRole.mutateAsync({ id: role.id });
        setLoading(false);
        return response;
      } catch (error) {
        setLoading(false);
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "User role is in use!",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await userRoleEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: userRoleModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[userRoleModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[userRoleModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };
  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width:100,
    // },
    {
      perm_id: 11,
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      perm_id: 12,
      title: "Created at",
      dataIndex: "created_at",
      render: (created_at) => (
        <div className="center-aligned-text">
          {dayjs(created_at).format("D MMM YYYY")}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.created_at);
        const dateB = dayjs(b.created_at);

        return dateA - dateB;
      },
    },
    {
      perm_id: 13,
      title: "Permissions",
      dataIndex: "permissions",
    },
    {
      perm_id: 148,
      title: "Status",
      dataIndex: "status",
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    const filteredData = roles.results?.filter((item) =>
      item?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
    const formattedData = filteredData.map((item) => ({
      ...item,
      created_at: moment(item.created_at).format("D MMM YYYY"),
    }));
    const isAddAllowed = () => {
      const { is_addable, add_access } = permissionSet;
      return is_addable && add_access;
    };
    content = (
      <GenericTable
        data={formattedData}
        columns={columns}
        permissionSet={permissions.permissions[userRoleModuleID]}
        handleEdit={handleEdit}
        showPagination={false}
        handleToggle={handleToggle}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> User Role
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>

          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col md="3" className="d-flex align-items-center gap-2 filter-col-wrapper">
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[userRoleModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={userRoleModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Role name"
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                    {isAddAllowed(permissions.permissions[userRoleModuleID]) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-role")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserRoleTable;
