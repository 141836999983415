import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import LoadingOverlay from "components/Overlay";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import { getUsers } from "API/registerApi";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import { editUsers, resetTfa } from "API/registerApi";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import { getRoles } from "API/registerApi";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { getUserID } from "helpers/authHelper";
import dayjs from "dayjs";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import ConfirmModal from "components/Modal/Modal";
import ResetTFAButton from "components/Buttons/ResetTFAButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const RegisterTableComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const registrationModuleID = parseInt(
    process.env.REACT_APP_REGISTRATION_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    role_filter: selectedRole,
    status_filter: selectedStatus,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: users,
    refetch,
  } = useQuery(["users", queryParams], getUsers, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
    error: rolesError,
    data: roles,
  } = useQuery("roles", getRoles, {
    cacheTime: cacheDuration,
  });
  const allRoles = { id: "", name: "All" };

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };
  const handleRoleSelect = (selectedRole) => {
    setSelectedRole(selectedRole);
    refetch();
  };
  const handleStatusSelect = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const userEditor = useMutation(editUsers, {

    onMutate: () => {
      setLoading(true);
    },    onSuccess: (response) => {
      setLoading(false);

      refetch();
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await refetch();
      await permissionRefetch();
      setPermissionChanged(true)
    },
  });
  const tfaResetter = useMutation(resetTfa, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });

  const handleEdit = (user) => {
    navigate("/edit-user", { state: { data: user } });
  };
  const handleColumnDropdown = async (menuOpen) => {
    if(!menuOpen&&selectedColumns){
      const response = await columnsEditor.mutateAsync({
        id: registrationModuleID,
        data: { field_list: selectedColumns?.map(Number) }
      });
      
    }
      setLoading(false);
   
  };
  const handleToggle = async (id, status) => {
    const response = await userEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const handleTFAReset = async (id) => {
    const resetTFAApi = async () => {
      try {
        const response = await tfaResetter.mutateAsync({ id: id });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure you want to reset 2FA ?",
      text: "You won't be able to revert this!",
      onConfirm: resetTFAApi,
      confirmButtonText: "Yes, reset it!",
      successTitle: "Reset completed successfully!",
      failedTitle: "Reset not completed!",
      deleteErrorText: "Unable to reset 2FA!",
      deleteSuccessMessage: "2FA reset successfully!",
    });
  };

  const statusOptions = [
    { id: "", name: "All" },
    { id: "1", name: "Active" },
    { id: "0", name: "In active" },
  ];

  const actionButtons = (record) => {
    const buttons = [];
    if (
      permissions.permissions[registrationModuleID].edit_access &&
      record?.id != getUserID() &&
      record?.id != 1
    ) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: 1,
      title: "First name",
      dataIndex: "first_name",
      sorter: (a, b) => {
        return a.first_name.localeCompare(b.first_name);
      },
    },
    {
      perm_id: 2,
      title: "Last name",
      dataIndex: "last_name",
      sorter: (a, b) => {
        return a.last_name.localeCompare(b.last_name);
      },
    },
    {
      perm_id: 3,
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => {
        return a.email.localeCompare(b.email);
      },
    },
    {
      perm_id: 4,
      title: "Role",
      dataIndex: "role_name",
      sorter: (a, b) => {
        return a.role_name.localeCompare(b.role_name);
      },
    },
    {
      perm_id: 8,
      title: "Dob",
      dataIndex: "date_of_birth",
      render: (date_of_birth) => (
        <div className="center-aligned-text">
          {date_of_birth ? dayjs(date_of_birth).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateOfBirthA = a.date_of_birth ? dayjs(a.date_of_birth) : null;
        const dateOfBirthB = b.date_of_birth ? dayjs(b.date_of_birth) : null;
      
        if (!dateOfBirthA && !dateOfBirthB) return 0; // Both are null/invalid
        if (!dateOfBirthA) return 1; // Treat null/invalid as greater (sorts to the bottom)
        if (!dateOfBirthB) return -1; // Treat null/invalid as greater (sorts to the bottom)
      
        return dateOfBirthA - dateOfBirthB;
      },      
    },
    {
      perm_id: 6,
      title: "Phone number",
      dataIndex: "phone_number",
      render: (phone_number) => (
        <div className="center-aligned-text">
          {phone_number ? phone_number : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.phone_number ?? "").localeCompare(
          String(b.phone_number ?? "")
        ),
    },
    {
      perm_id: 7,
      title: "Address",
      dataIndex: "address",
      width: 300,
      render: (address) => (
        <div
          style={{
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {address ? address : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.address ?? "").localeCompare(String(b.address ?? "")),
    },
    {
      perm_id: 10,
      title: "Status",
      dataIndex: "status",
    },
    {
      perm_id: 147,
      title: "Reset 2FA token",
      dataIndex: "reset_tfa",
      render: (_, record) => (
        <div className="center-aligned-text">
          {record?.id != 1 && (
            <ResetTFAButton
              onClickHandler={() => handleTFAReset(record.id)}
              record={record}
            />
          )}
        </div>
      ),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: users?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    // const filteredUsers = users?.results.filter((item) => item.id != getUserID());
    content = (
      <GenericTable
        data={users?.results}
        columns={columns}
        permissionSet={permissions.permissions[registrationModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Registration
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper "
                   
                  >

                    <CheckboxDropdown
                      title="Colunms"
                     
                      items={
                        permissions?.permissions[registrationModuleID].fields ||
                        []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={registrationModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                     
                    />

                    {permissions?.permissions[registrationModuleID].fields[4]
                      ?.view_access && roles ? (
                      <DropdownFilter
                        title={selectedRole ? selectedRole.label : "Role"}
                        selectedValue={selectedRole}
                        items={[
                          allRoles,
                          ...roles.Active,
                          ...roles.Deactivated,
                        ]}
                        onSelect={handleRoleSelect}
                        filterName="Role"
                      />
                    ) : (
                      ""
                    )}
                    {permissions?.permissions[registrationModuleID].fields[10]
                      ?.view_access && (
                      <DropdownFilter
                        title="Status"
                        selectedValue={selectedStatus}
                        items={statusOptions}
                        onSelect={handleStatusSelect}
                        filterName="Status"
                      />
                    )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | First name |Last Name ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                  
                    {isAddAllowed(
                      permissions.permissions[registrationModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-user")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterTableComponent;
