import React from 'react'

import AddUserForm from 'components/AddUserForm/AddUserForm'

const AddUserView = () => {
  return (
    <div>
        <AddUserForm/>
    </div>
  )
}

export default AddUserView