import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import CustomMultipleDropdown from "components/CustomDropdown/MultipleDropdown";
import LoadingOverlay from "components/Overlay";

import {
  getModemData,
  getNewSimPackages,
  getUsedSimPackages,
  getTerms,
  createModemLog,
  editModemLog,
} from "API/modemDashApi";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";
import RecommendedDropdown from "components/CustomDropdown/RecommendedDropdown";
import DynamicPortsDropdown from "components/CustomDropdown/DynamicPortsDropdown";
import ServicePartnerDropdown from "components/CustomDropdown/ServicePartnerDropdown";
import StorageAftUseDropdown from "components/CustomDropdown/StorageAftUseDropdown";

const AddModemLog = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [selectedExtraOption, setSelectedExtraOption] = useState({
    label: "Select All",
    value: "select",
  });
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectedPorts, setSelectedPorts] = useState({});
  const [selectedDefPorts, setSelectedDefPorts] = useState({});
  const [selectedAftUse, setSelectedAftUse] = useState({});
  // const [packages, setPackages] = useState({1:{ id: 1, name: "Package 1", radioValue: "new" }});
  const [packages, setPackages] = useState([
    { id: 1, name: "Package 1", radioValue: "new" },
  ]);
  const port = Array.from({ length: 64 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
    disabled: "",
    usedBy: "",
  }));
  const [portOptions, setPortsOptions] = useState(port);

  const [selectedSP, setSelectedSP] = useState(null);
  const [storageOptions, setStorageOptions] = useState();
  const [packageIdForStorage, setPackageIdForStorage] = useState();
  const [currentPackage, setCurrentPackage] = useState();
  const [loadings, setLoading] = useState(false);


  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();
  // const {
  //   isLoading: isLoadingStorageBoxes,
  //   isError: isErrorStorageBoxes,
  //   error: errorStorageBoxes,
  //   data: allStorageBoxes,
  //   refetch: storageBoxRefetch,
  // } = useQuery(["storageboxesss", packageIdForStorage], getStorageBoxes, {
  //   cacheTime: cacheDuration,
  //   enabled: packageIdForStorage ? true : false,
  // });

  const {
    isLoading: isLoadingTerms,
    isError: isErrorTerms,
    error: errorTerms,
    data: allTerms,
  } = useQuery("terms", getTerms, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading,
    isError,
    error,
    data: modemData,
    refetch,
  } = location?.state?.modemData
    ? useQuery(["modemdata", location?.state?.modemData?.id], getModemData, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const {
    isLoading: isLoadingNewSimPack,
    isError: isErrorNewSimPack,
    error: errorNewSimPack,
    data: newSimPackData,
    refetch: refetchNewSimPack,
  } = location?.state?.modemData?.id
    ? useQuery(["newSimPack", selectedSP?.id, location?.state?.modemData?.id], getNewSimPackages, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const {
    isLoading: isLoadingUsedSimPack,
    isError: isErrorUsedSimPack,
    error: errorUsedSimPack,
    data: usedSimPackData,
    refetch: refetchUsedSimPack,
  } = location?.state?.modemData
    ? useQuery(["usedSimPack", selectedSP?.id, location?.state?.modemData?.id], getUsedSimPackages, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const spTypeChoices = modemData?.service_partners?.map((innerArray) => {
    return {
      id: innerArray.service_partner_id,
      name: innerArray.service_partner__short_name,
      username: innerArray.service_partner__username,
      country: innerArray.country,
    };
  });

  const newSimChoices = newSimPackData?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      amount: innerArray.amount,
      recommend: innerArray.recomanded,
      type: "new",
    };
  });

  const usedSimChoices = usedSimPackData?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      amount: innerArray.amount,
      recommend: innerArray.recomanded,
      wait_for_days: innerArray.wait_for_days,
      type: "used",
    };
  });
  newSimChoices?.sort((a, b) => b.recommend - a.recommend);
  usedSimChoices?.sort((a, b) => b.recommend - a.recommend);
  const termChoices = allTerms?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      if (location.state.modemData) {
        const modemDataDetails = location.state.modemData;
        setValue("modemName", modemDataDetails?.modem_name);
        setValue("defPorts", modemDataDetails?.defective_port);

        const disabledValuesArray = modemDataDetails.defective_port?.split(",");

        // Update the options array to set disabled attribute
        const updatedOptions = portOptions.map((option) => ({
          ...option,
          disabled: disabledValuesArray?.includes(option.value),
        }));

        setPortsOptions(updatedOptions);
      }

      if (location.state.editDetails && modemData) {
        setPackages([]);
        // setPackages({});
        const modemDataDetails = location.state.editDetails;

        const termVal = termChoices?.find((val) => {
          return !isNaN(Number(modemDataDetails.term))
            ? val.id == modemDataDetails.term
            : val.name == modemDataDetails.term;
        });
        const findValue = (valueToCheck) => (val) => {
          return !isNaN(Number(valueToCheck))
            ? val.id == valueToCheck
            : val.name == valueToCheck;
        };

        setSelectedSP({
          id: modemData.service_partners[0].service_partner_id,
          name: modemData.service_partners[0].name_from_sp,
        });
        setValue("servicePartName", {
          id: modemData.service_partners[0].service_partner_id,
          name: modemData.service_partners[0].name_from_sp,
        });
        setSelectedTerm(termVal);
        setValue("term", termVal);
        setValue("vipNumbers", modemDataDetails.vip_number);
        setValue("notes", modemDataDetails.note);

        for (let i = 0; i < modemDataDetails?.child.length; i++) {
          setPackages((prevPackages) => [
            ...prevPackages,
            {
              id: i + 1,
              name: `Package ${i + 1}`,
              radioValue: modemDataDetails?.child[i]?.sim_package_id
                ? "new"
                : "used",
              logPackageId: modemDataDetails?.child[i]?.log_package_id,
            },
          ]);

          setSelectedPackage((prevFields) => ({
            ...prevFields,
            [`package${i + 1}`]: {
              id: modemDataDetails?.child[i]?.sim_package_id
                ? modemDataDetails?.child[i]?.sim_package_id
                : modemDataDetails?.child[i]?.sub_package_id,
              name: modemDataDetails?.child[i]?.sim_package_name
                ? modemDataDetails?.child[i]?.sim_package_name
                : modemDataDetails?.child[i]?.sub_package_name,
              recommend: modemDataDetails?.child[i]?.sim_package_name
                ? modemDataDetails?.child[i]?.sim_package_name
                : modemDataDetails?.child[i]?.sub_package_name,
            },
          }));

          setValue(`package${i + 1}`, {
            id: modemDataDetails?.child[i]?.sim_package_id
              ? modemDataDetails?.child[i]?.sim_package_id
              : modemDataDetails?.child[i]?.sub_package_id,
            name: modemDataDetails?.child[i]?.sim_package_name
              ? modemDataDetails?.child[i]?.sim_package_name
              : modemDataDetails?.child[i]?.sub_package_name,
          });
          setValue(`amountNoOfSIM${i + 1}`, modemDataDetails?.child[i]?.amount);

          if (modemDataDetails?.child[i]?.ports.length == 64) {
            setSelectedExtraOption({
              label: "UnSelect All",
              value: "unselect",
            });
          }

          const portsOutput = modemDataDetails?.child[i]?.ports?.map((num) =>
            num.toString()
          );
          setValue(`ports${i + 1}`, portsOutput);
          setSelectedPorts((prevFields) => ({
            ...prevFields,
            [`ports${i + 1}`]: portsOutput,
          }));

          const defPortsOutput = modemDataDetails?.child[i]?.defective_port?.map((num) =>
            num.toString()
          );
          setValue(`defective_ports${i + 1}`, defPortsOutput);
          setSelectedDefPorts((prevFields) => ({
            ...prevFields,
            [`defective_ports${i + 1}`]: defPortsOutput,
          }));

          setCurrentPackage(i + 1);
          setPackageIdForStorage((prevFields) => ({
            ...prevFields,
            [`package${i+1}`]: {
              id: modemDataDetails?.child[i]?.sim_package_id
                ? modemDataDetails?.child[i]?.sim_package_id
                : modemDataDetails?.child[i]?.sub_package_id,
              name: modemDataDetails?.child[i]?.sim_package_name
                ? modemDataDetails?.child[i]?.sim_package_name
                : modemDataDetails?.child[i]?.sub_package_name,
              type: modemDataDetails?.child[i]?.sim_package_name ? "new" : "used",
            },
          }));
         
        

          setSelectedAftUse((prevFields) => ({
            ...prevFields,
            [`storageAftUse${i + 1}`]: {
              id: modemDataDetails?.child[i]?.storage_after_use_id,
              name: modemDataDetails?.child[i]?.storage_after_use_name,
            },
          }));

          setValue(`storageAftUse${i + 1}`, {
            id: modemDataDetails?.child[i]?.storage_after_use_id,
            name: modemDataDetails?.child[i]?.storage_after_use_name,
          });

          setValue(
            `storageAftUseNote${i + 1}`,
            modemDataDetails?.child[i]?.note_for_storage_after_use
              ? modemDataDetails?.child[i]?.note_for_storage_after_use
              : ""
          );
          setValue(
            `storageBefUseNote${i + 1}`,
            modemDataDetails?.child[i]?.note_for_storage_before_use
              ? modemDataDetails?.child[i]?.note_for_storage_before_use
              : ""
          );
          setValue(
            `storageDefNote${i + 1}`,
            modemDataDetails?.child[i]?.note_for_storage_for_defactive
              ? modemDataDetails?.child[i]?.note_for_storage_for_defactive
              : ""
          );
        }
      
        setIsEdit(true);
      }
    };

    fetchData();
  }, [location.state, modemData]);

  const modemLogCreator = useMutation(createModemLog, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/modem-log", {
        state: { data: location?.state?.modemData },
      });
      await showToast({
        icon: "success",
        title: "Modem log added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const modemLogEditor = useMutation(editModemLog, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/modem-log", {
        state: { data: location?.state?.modemData },
      });
      await showToast({
        icon: "success",
        title: "Modem log edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields

    setSelectedTerm(null);
    setSelectedPackage(null);
    setSelectedPorts(null);
    setSelectedDefPorts(null);
    setSelectedAftUse(null);
    setPackages([{ id: 1, name: "Package 1", radioValue: "new",amount:"" }]);

    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[modemDashModuleID];
    const dataObject = {};
    dataObject.child = {};
    dataObject.new_child = {};

    if (data.modemName) {
      dataObject.modem_id = location?.state?.modemData?.id;
    }

    if (selectedSP) {
      dataObject.service_partner_id = selectedSP?.id;
    }
    if (selectedTerm) {
      dataObject.term = selectedTerm?.id;
    }

    dataObject.vip_number = data?.vipNumbers ? data?.vipNumbers : null;
    dataObject.note = data?.notes ? data?.notes : null;

    if (isEdit) {
      for (let i = 0; i < packages.length; i++) {
        const logPackageId = packages[i]?.logPackageId;
        if (logPackageId !== undefined) {
          dataObject.child[logPackageId] = dataObject.child[logPackageId] || {};
        } else {
          dataObject.new_child[i + 1] = {};
        }
      }

      packages.map((packageItem, i) => {
        if (packageItem.logPackageId) {
          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.packages,
              modulePermission
            ) &&
            selectedPackage
          ) {
            dataObject.child[packageItem.logPackageId][
              packageItem.radioValue === "used"
                ? "sub_package_id"
                : "sim_package_id"
            ] = selectedPackage[`package${packageItem.id}`]?.id;
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.amount,
              modulePermission
            ) &&
            data[`amountNoOfSIM${packageItem.id}`]
          ) {
            dataObject.child[packageItem.logPackageId].amount =
              data[`amountNoOfSIM${packageItem.id}`];
          }
          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.ports,
              modulePermission
            ) &&
            selectedPorts?.[`ports${packageItem.id}`]
          ) {
            
            let outputArray = [];
            if (Array.isArray(selectedPorts[`ports${packageItem.id}`])) {
              // Check if the first element is an object (for the first case)
              if (typeof selectedPorts[`ports${packageItem.id}`][0] === "object") {
                outputArray = selectedPorts[`ports${packageItem.id}`].map((item) => item.value);
              } else {
                // Directly use the array (for the second case)
                outputArray = selectedPorts[`ports${packageItem.id}`];
              }
            }
            dataObject.child[packageItem.logPackageId].ports = outputArray?.join(",");
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.defective_port,
              modulePermission
            ) &&
            selectedDefPorts?.[`defective_ports${packageItem.id}`]
          ) {
            let defPortsVal = selectedDefPorts?.[`defective_ports${packageItem.id}`]?.join(",") || null; 
            dataObject.child[packageItem.logPackageId].defective_port = defPortsVal;
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.storage_after_use_id,
              modulePermission
            ) &&
            selectedAftUse?.[`storageAftUse${packageItem.id}`]
          ) {
            dataObject.child[packageItem.logPackageId].storage_after_use_id =
              selectedAftUse?.[`storageAftUse${packageItem.id}`].id;
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.note_for_storage_after_use,
              modulePermission
            )
          ) {
            dataObject.child[
              packageItem.logPackageId
            ].note_for_storage_after_use = data[
              `storageAftUseNote${packageItem.id}`
            ]
              ? data[`storageAftUseNote${packageItem.id}`]
              : null;
          }
        }
        if (
          packageItem.logPackageId == undefined &&
          packageItem.removeIt == undefined
        ) {
          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.packages,
              modulePermission
            ) &&
            selectedPackage
          ) {
            dataObject.new_child[i + 1].sim_package_id =
              selectedPackage[`package${packageItem.id}`].id;
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.amount,
              modulePermission
            ) &&
            data[`amountNoOfSIM${packageItem.id}`]
          ) {
            dataObject.new_child[i + 1].amount =
              data[`amountNoOfSIM${packageItem.id}`];
          }

          const outputArray = selectedPorts?.[`ports${packageItem.id}`].map(
            (item) => item.value
          );
        
          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.ports,
              modulePermission
            ) &&
            selectedPorts?.[`ports${packageItem.id}`]
          ) {
            dataObject.new_child[i + 1].ports = outputArray?.join(",");
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.ports,
              modulePermission
            ) &&
            selectedDefPorts?.[`defective_ports${packageItem.id}`]
          ) {
            dataObject.new_child[i + 1].defective_port =
              selectedDefPorts?.[`defective_ports${packageItem.id}`]?.join(",");
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.storage_after_use_id,
              modulePermission
            ) &&
            selectedAftUse?.[`storageAftUse${packageItem.id}`]
          ) {
            dataObject.new_child[i + 1].storage_after_use_id =
              selectedAftUse?.[`storageAftUse${packageItem.id}`].id;
          }

          if (
            isFieldAddableOrEditable(
              fieldIds.modemDashboard.note_for_storage_after_use,
              modulePermission
            )
          ) {
            dataObject.new_child[i + 1].note_for_storage_after_use = data[
              `storageAftUseNote${packageItem.id}`
            ]
              ? data[`storageAftUseNote${packageItem.id}`]
              : null;
          }
        }
        if (
          packageItem.logPackageId != undefined &&
          packageItem.removeIt == true
        ) {
          dataObject.child[packageItem.logPackageId] = {};
        }
      });
    } else {
      for (let i = 1; i <= packages.length; i++) {
        dataObject.child[i] = dataObject.child[i] || {};
      }
      packages.map((packageItem, i) => {
        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.packages,
            modulePermission
          ) &&
          selectedPackage
        ) {
          if (packageItem.radioValue === "used") {
            dataObject.child[i + 1].sub_package_id =
              selectedPackage[`package${packageItem.id}`].id;
          } else {
            dataObject.child[i + 1].sim_package_id =
              selectedPackage[`package${packageItem.id}`].id;
          }
        }

        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.amount,
            modulePermission
          ) &&
          data[`amountNoOfSIM${packageItem.id}`]
        ) {
          dataObject.child[i + 1].amount =
            data[`amountNoOfSIM${packageItem.id}`];
        }

        const outputArray = selectedPorts?.[`ports${packageItem.id}`].map(
          (item) => item.value
        );

        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.ports,
            modulePermission
          ) &&
          selectedPorts?.[`ports${packageItem.id}`]
        ) {
          dataObject.child[i + 1].ports = outputArray.join(",");
        }

        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.ports,
            modulePermission
          ) &&
          selectedDefPorts?.[`defective_ports${packageItem.id}`]
        ) {
          dataObject.child[i + 1].defective_port =
            selectedDefPorts?.[`defective_ports${packageItem.id}`]?.join(",");
        }

        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.storage_after_use_id,
            modulePermission
          ) &&
          selectedAftUse?.[`storageAftUse${packageItem.id}`]
        ) {
          dataObject.child[i + 1].storage_after_use_id =
            selectedAftUse?.[`storageAftUse${packageItem.id}`].id;
        }

        if (
          isFieldAddableOrEditable(
            fieldIds.modemDashboard.note_for_storage_after_use,
            modulePermission
          )
        ) {
          dataObject.child[i + 1].note_for_storage_after_use = data[
            `storageAftUseNote${packageItem.id}`
          ]
            ? data[`storageAftUseNote${packageItem.id}`]
            : null;
        }
      });
    }

    try {
      let response;
      if (isEdit) {
        response = await modemLogEditor.mutateAsync({
          id: location.state.editDetails.id,
          data: dataObject,
        });
      } else {
        response = await modemLogCreator.mutateAsync(dataObject);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  const handleMultipleOptions = async (
    fieldSetter,
    fieldName,
    e,
    defaultLabel
  ) => {
    const selectAll = e?.find((option) => option.value === "select");
    const unselectAll = e?.find((option) => option.value === "unselect");

    if (selectAll) {
      const arrayOfValues = portOptions?.map((obj) => obj.value);
      fieldSetter((prevFields) => ({
        ...prevFields,
        [fieldName]: arrayOfValues,
      }));
      setSelectedExtraOption({ label: "Unselect All", value: "unselect" });
    } else if (unselectAll) {
      setSelectedExtraOption({ label: "Select All", value: "select" });
      fieldSetter((prevFields) => ({
        ...prevFields,
        [fieldName]: "",
      }));
    } else {
      const arrayOfValues = e?.map((obj) => obj.value);
      fieldSetter((prevFields) => ({
        ...prevFields,
        [fieldName]: arrayOfValues,
      }));
    }
  };

  const handleMultipleDefPorts = async (fieldName, e) => {
    handleMultipleOptions(setSelectedDefPorts, fieldName, e, "Unselect All");
  };

  const [selectedAmounfField, setSelectedAmountField] = useState();
  const handleMultiplePorts = async (fieldName, e) => {
    handleMultipleOptions(setSelectedPorts, fieldName, e, "Unselect All");
    // const newAmount = await calculateNewAmount(selectedPorts, fieldName);
    // console.log(newAmount,"coming late")
    // // Set the new amount in the state
    // setValue(`amountNoOfSIM${fieldName.match(/\d+/)[0]}`, newAmount);
    setSelectedAmountField(fieldName);
  };
  useEffect(() => {
    const newAmount = calculateNewAmount(selectedPorts, selectedAmounfField);
    // Set the new amount in the state
    setValue(`amountNoOfSIM${selectedAmounfField?.match(/\d+/)[0]}`, newAmount);
  }, [selectedPorts, selectedAmounfField]);

  const handleAddPackage = () => {
    const newPackage = {
      id: packages.length + 1,
      name: `Package ${packages.length + 1}`,
      radioValue: "new", // Default radio button value
      amount:"",
    };
    setPackages([...packages, newPackage]);
  };

  const handleRemovePackage = (packageId) => {
    // Check if any package has logPackageId
    const hasLogPackageId = packages.some(
      (pkg) => pkg?.logPackageId !== undefined && pkg?.id === packageId
    );
    if (hasLogPackageId) {
      // logic1
      const updatedPackages = packages.map((pkg) => {
        if (pkg?.logPackageId !== undefined && pkg?.id === packageId) {
          // Update properties of the package that meets the condition
          return {
            ...pkg,
            removeIt: true, // Change this line based on your specific update logic
          };
        } else {
          return pkg;
        }
      });
      setPackages(updatedPackages);
    } else {
      // logic2
      const updatedPackages = packages.filter((pkg) => pkg.id !== packageId);
      setPackages(
        updatedPackages.map((pkg, index) => ({ ...pkg, id: index + 1 }))
      );
    }
  };

  const handleRadioChange = (packageId, value) => {
    const updatedPackages = packages.map((pkg) =>
      pkg.id === packageId ? { ...pkg, radioValue: value } : pkg
    );
    setPackages(updatedPackages);
  };

  const handlePackageChange = (fieldName, value) => {
    setSelectedPackage((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };
  const updatePortsWithPackage = (e, packageId) => {
    let totalAmount = 0;
    const usedPortsByPackage = [];
    const selectedPorts = [];
    let nextAvailablePortIndex = 0;

    // Iterate over all package items to calculate the total amount
    packages.forEach((item) => {
      const amountFieldName = `amountNoOfSIM${item?.id}`;
      const fieldValue = getValues(amountFieldName);

      // Check if the fieldValue is a valid number
      if (!isNaN(fieldValue)) {
        totalAmount += parseInt(fieldValue);
      }
    });

    // Perform validation for the total amount
    if (totalAmount > 64) {
      setError(`amountNoOfSIM${packageId}`, {
        type: "manual",
        message: "Total amount across all packages cannot exceed 64.",
      });
    } else {
      clearErrors(`amountNoOfSIM${packageId}`);
      const numberOfSims = e.target.value;
      let changedPortsCount = 0;

      // Check if ports are available
      const availablePorts = portOptions.filter(
        (portOption) => !portOption.disabled
      );

      if (availablePorts.length < numberOfSims) {
        console.log("🚀 ~ updatePortsWithPackage ~ numberOfSims:", numberOfSims)
        console.log("🚀 ~ updatePortsWithPackage ~ availablePorts.length:", availablePorts.length)
        setError(`amountNoOfSIM${packageId}`, {
          type: "manual",
          message:
            "Not enough available ports for the selected number of SIMs.",
        });
        return;
      }

      const updatedPortOptions = (portOptions) => {

        portOptions.map((portOption) => {
          portOption.usedBy = '';
        })
        let usedPort = []
        packages.forEach((item, index) => {
          let packagAmount  = getValues(`amountNoOfSIM${item?.id}`);
          let portCounter = 0
          portOptions.map((portOption) => {
            if (!portOption.disabled && portOption.usedBy == "" && (!usedPort.includes(portOption.value)) && portCounter < packagAmount) {
              // Add the 'usedBy' attribute to the selected number of ports
              const updatedPort = { ...portOption, usedBy: item.id };

              portOptions = portOptions.map(item2 => ({
                  ...item2,
                  usedBy: item2.value === portOption.value ? item.id : item2.usedBy
              }));
              usedPort.push(portOption.id);
              portCounter++;
      
              if (portCounter <= numberOfSims) {
                selectedPorts.push(updatedPort);
                }
              }
            })
          })
        return portOptions;
        
      }
      // Update the state with the modified portOptions array
      let data = updatedPortOptions(portOptions)
      setPortsOptions(data);
      
        packages.forEach((item, index) => {
          let usedPortsByPackage2 = []
          let iterPackageId = item.id
          data.map((portOptions) => {
            if (portOptions.usedBy == iterPackageId) {
              usedPortsByPackage2.push(portOptions);
            }
          });
          setValue(`ports${iterPackageId}`, usedPortsByPackage2);
          setSelectedPorts((prevFields) => ({
            ...prevFields,
            [`ports${iterPackageId}`]: usedPortsByPackage2,
          }));
        })
    }
  };



  const calculateNewAmount = (selectedPorts, packageId) => {
    const newAmount = selectedPorts ? selectedPorts[packageId]?.length : 0;

    return newAmount;
  };

 useEffect(()=>{
  console.log(storageOptions,"storageOptions")
 },[storageOptions])


  // useEffect(() => {
  //   const storegeNotInUse = allStorageBoxes?.storege_not_in_use || [];
  //   const recomandedStoregeNotInUse =
  //     allStorageBoxes?.recomanded_storege_not_in_use || [];

  //   // Merge the two arrays into a single array
  //   const mergedArray = [...storegeNotInUse, ...recomandedStoregeNotInUse];

  //   const sortedArray = mergedArray.sort((a, b) => {
  //     const aRecomanded = a.recomanded || false;
  //     const bRecomanded = b.recomanded || false;

  //     return bRecomanded - aRecomanded;
  //   });

  
  //   setStorageOptions((prevFields) => ({
  //     ...prevFields,
  //     [`storageOption${currentPackage}`]: sortedArray,
  //   }));
  // }, [allStorageBoxes]);

  useEffect(() => {
    const storageOptionKey = `storageOption${currentPackage}`;
    // Check if storageOptions and storageOptionKey are truthy

    if (storageOptions && storageOptions[storageOptionKey]) {
      const sortedArray = [...storageOptions[storageOptionKey]].sort((a, b) => {
        const aRecomanded = a.recomanded || false;
        const bRecomanded = b.recomanded || false;

        return bRecomanded - aRecomanded;
      });

      // Log the first item with recomanded as true
      const firstRecomanded = sortedArray.find((item) => item.recomanded);

      // Set the first item as selected, if there are no already selected item
      if (firstRecomanded && (
          (
            typeof selectedAftUse === 'object' &&
            selectedAftUse !== null &&
            !Array.isArray(selectedAftUse) &&
            Object.keys(selectedAftUse).length === 0
          )
          || !selectedAftUse
        )
      ) {
        const fieldName = `storageAftUse${currentPackage}`;

        // Update selectedAftUse state
        setSelectedAftUse((prevFields) => ({
          ...prevFields,
          [fieldName]: {
            id: firstRecomanded.id,
            name: firstRecomanded.name,
          },
        }));

        // Update the form control value using setValue
        setValue(fieldName, {
          id: firstRecomanded.id,
          name: firstRecomanded.name,
        });
      }
    }
  }, [storageOptions, currentPackage]);



  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} modem log
                </Card.Title>
                <Breadcrumbs  key={location.state.modemData.id}/>
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>Modem Details</legend>

                            <Row>
                              <Col className="" md="4">
                                <Form.Group>
                                  <label title="Modem name">
                                    <span className="label-text">
                                      Modem name
                                    </span>
                                  </label>

                                  <Form.Control
                                    defaultValue=""
                                    placeholder=""
                                    type="text"
                                    disabled={"true"}
                                    {...register("modemName", {
                                      maxLength: 50,
                                    })}
                                  ></Form.Control>
                                  <span className="error-message">
                                    {errors.modemName?.type === "maxLength" &&
                                      "It accepts maximum 50 characters."}
                                    {errors.modemName && errors.name.message}
                                  </span>
                                </Form.Group>
                              </Col>

                              <Col className="" md="4">
                                <Form.Group>
                                  <label title="Defective ports">
                                    <span className="label-text">
                                      Defective ports
                                    </span>
                                  </label>

                                  <Form.Control
                                    defaultValue=""
                                    placeholder=""
                                    type="text"
                                    disabled={"true"}
                                    {...register("defPorts", {
                                      maxLength: 50,
                                    })}
                                  ></Form.Control>
                                  <span className="error-message">
                                    {errors.modemName?.type === "maxLength" &&
                                      "It accepts maximum 50 characters."}
                                    {errors.modemName && errors.name.message}
                                  </span>
                                </Form.Group>
                              </Col>

                              {isFieldAddableOrEditable(
                                fieldIds.modemDashboard.service_partner_id,
                                permissions?.permissions[modemDashModuleID]
                              ) &&
                                spTypeChoices && (
                                  <Col className="" md="4">
                                    <ServicePartnerDropdown
                                      label="Service Partner name"
                                      selectedOption={selectedSP}
                                      options={spTypeChoices}
                                      handleSelect={(e) => setSelectedSP(e)}
                                      control={control}
                                      errors={errors}
                                      name="servicePartName"
                                      rules={{
                                        required:
                                          "Service Partner name is required.",
                                      }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.modemDashboard.term,
                                permissions?.permissions[modemDashModuleID]
                              ) &&
                                allTerms && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Term"
                                      selectedOption={selectedTerm}
                                      options={termChoices}
                                      handleSelect={(e) => setSelectedTerm(e)}
                                      control={control}
                                      errors={errors}
                                      name="term"
                                      rules={{ required: "Term is required." }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.modemDashboard.vip_number,
                                permissions?.permissions[modemDashModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="VIP numbers">
                                      <span className="label-text">
                                        VIP numbers
                                      </span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("vipNumbers", {})}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.vipNumbers &&
                                        errors.vipNumbers.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.modemDashboard.note,
                                permissions?.permissions[modemDashModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Notes">Note / Comment</label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      row={5}
                                      {...register("notes", {})}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.notes && errors.notes.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          {packages?.map(
                            (packageItem) =>
                              packageItem.removeIt != true && (
                                <fieldset>
                                  <legend>{`Package ${packageItem.id}`}</legend>
                                  <Row>
                                    <Col>
                                      <label className="package-select-label">
                                        <input
                                          type="radio"
                                          value="new"
                                          checked={
                                            packageItem.radioValue === "new"
                                          }
                                          onChange={() =>
                                            handleRadioChange(
                                              packageItem.id,
                                              "new"
                                            )
                                          }
                                        />
                                        New package
                                      </label>
                                      <label className="package-select-label">
                                        <input
                                          type="radio"
                                          value="used"
                                          checked={
                                            packageItem.radioValue === "used"
                                          }
                                          onChange={() =>
                                            handleRadioChange(
                                              packageItem.id,
                                              "used"
                                            )
                                          }
                                        />
                                        Used package
                                      </label>
                                    </Col>
                                    <Col lg="6" md="12" className="text-right">
                                      <Button
                                        type="button"
                                        onClick={handleAddPackage}
                                      >
                                        Add package
                                      </Button>
                                      {packageItem?.id != 1 && (
                                        <Button
                                          className="ml-2"
                                          type="button"
                                          onClick={() =>
                                            handleRemovePackage(packageItem.id)
                                          }
                                        >
                                          Remove package
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard.packages,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) &&
                                       (
                                        <Col className="" md="4">
                                          <RecommendedDropdown
                                            label={`Package`}
                                            selectedOption={
                                              (selectedPackage && selectedPackage[
                                                `package${packageItem?.id}`
                                              ]) || []
                                            }
                                            options={
                                              isEdit
                                                ? packageItem?.radioValue ==
                                                  "new"
                                                  ? [
                                                      selectedPackage[
                                                        `package${packageItem?.id}`
                                                      ],
                                                      ...newSimChoices || [],
                                                    ]
                                                  : [
                                                      selectedPackage[
                                                        `package${packageItem?.id}`
                                                      ],
                                                      ...usedSimChoices || [],
                                                    ]
                                                : packageItem?.radioValue ==
                                                  "new"
                                                ? newSimChoices || []
                                                : usedSimChoices || []
                                            }
                                            handleSelect={(e) => {
                                              handlePackageChange(
                                                `package${packageItem?.id}`,
                                                e
                                              );
                                          
                                              setCurrentPackage(packageItem?.id);
                                            
                                              setPackageIdForStorage((prevFields) => ({
                                                ...prevFields,
                                                [`package${packageItem?.id}`]: e,
                                              }));
                                            }}
                                            control={control}
                                            errors={errors}
                                            name={`package${packageItem?.id}`}
                                            rules={{
                                              required: "Package is required.",
                                            }}
                                          />
                                        </Col>
                                      )}
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard.amount,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) && (
                                      <Col className="" md="4">
                                        <Form.Group>
                                          <label title="Amount (no. of SIM)">
                                            <span className="label-text">
                                              Amount (no. of SIM)
                                            </span>
                                            <span className="error-message"> * </span>
                                          </label>

                                          <Form.Control
                                            defaultValue=""
                                            placeholder=""
                                            type="text"
                                            onKeyPress={handleKeyPress}
                                            onBlurCapture={(e) =>
                                              updatePortsWithPackage(
                                                e,
                                                packageItem?.id
                                              )
                                            }
                                            {...register(
                                              `amountNoOfSIM${packageItem?.id}`,
                                              {required: true}
                                            )}

                                          ></Form.Control>
                                          <span className="error-message">
                                            {errors[
                                              `amountNoOfSIM${packageItem?.id}`
                                            ]?.type === "required" &&
                                              "Amount is required."}
                                            {errors[
                                              `amountNoOfSIM${packageItem?.id}`
                                            ] &&
                                              errors[
                                                `amountNoOfSIM${packageItem?.id}`
                                              ].message}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    )}
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard.ports,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) && (
                                      <Col className="" md="4">
                                        <DynamicPortsDropdown
                                          label="Ports"
                                          defaultValue={
                                            selectedPorts?.[
                                              `ports${packageItem?.id}`
                                            ]
                                          }
                                          selectedOption={
                                            selectedPorts?.[
                                              `ports${packageItem?.id}`
                                            ]
                                          }
                                          options={
                                            portOptions
                                              ? [
                                                  selectedExtraOption,
                                                  ...portOptions,
                                                ]
                                              : []
                                          }
                                          handleSelect={(e) =>
                                            handleMultiplePorts(
                                              `ports${packageItem?.id}`,
                                              e
                                            )
                                          }
                                          control={control}
                                          errors={errors}
                                          name={`ports${packageItem?.id}`}
                                          showSelectAllOption={true}
                                          disabledOptions={[1, 2]}
                                          packageId={packageItem?.id}
                                        />
                                      </Col>
                                    )}
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard.ports,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) && (
                                      <Col className="" md="4">
                                        <CustomMultipleDropdown
                                          label="Defective ports"
                                          defaultValue={
                                            selectedDefPorts?.[
                                              `defective_ports${packageItem?.id}`
                                            ] || []
                                          }
                                          selectedOption={
                                            selectedDefPorts?.[
                                              `defective_ports${packageItem?.id}`
                                            ] || []
                                          }
                                          options={
                                            port
                                              ? [selectedExtraOption, ...port]
                                              : []
                                          }
                                          handleSelect={(e) =>
                                            handleMultipleDefPorts(
                                              `defective_ports${packageItem?.id}`,
                                              e
                                            )
                                          }
                                          control={control}
                                          errors={errors}
                                          name={`defective_ports${packageItem?.id}`}
                                          showSelectAllOption={true}
                                        />
                                      </Col>
                                    )}
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard
                                        .storage_after_use_id,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) && 
                                      (
                                       
                                        <Col className="" md="4">
                                        
                                        <StorageAftUseDropdown
                                          packageIdForStorage={
                                            packageIdForStorage
                                          }
                                          selectedAftUse={
                                            selectedAftUse?.[
                                              `storageAftUse${packageItem?.id}`
                                            ] || []
                                          }
                                          packageItem={packageItem}
                                          isEdit={isEdit}
                                          storageOptions={storageOptions}
                                          setStorageOptions={setStorageOptions}
                                          setSelectedAftUse={setSelectedAftUse}
                                          currentPackage={currentPackage}
                                        />
                                        
                                        </Col>
                                      )}
                                    {isFieldAddableOrEditable(
                                      fieldIds.modemDashboard
                                        .note_for_storage_after_use,
                                      permissions?.permissions[
                                        modemDashModuleID
                                      ]
                                    ) && (
                                      <Col className="" md="4">
                                        <Form.Group>
                                          <label title="Note for storage after use">
                                            Note for storage after use
                                          </label>{" "}
                                          <Form.Control
                                            defaultValue=""
                                            placeholder=""
                                            as="textarea"
                                            row={5}
                                            {...register(
                                              `storageAftUseNote${packageItem.id}`,
                                              {}
                                            )}
                                          ></Form.Control>
                                          <span className="error-message">
                                            {errors[
                                              `storageAftUseNote${packageItem.id}`
                                            ] &&
                                              errors[
                                                `storageAftUseNote${packageItem.id}`
                                              ].message}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    )}
                                    <Col className="" md="4">
                                      <Form.Group>
                                        <label title="Storage before use with note">
                                          Storage before use with note
                                        </label>{" "}
                                        <Form.Control
                                          defaultValue=""
                                          placeholder=""
                                          as="textarea"
                                          row={5}
                                          disabled={true}
                                          {...register(
                                            `storageBefUseNote${packageItem.id}`,
                                            {}
                                          )}
                                        ></Form.Control>
                                        <span className="error-message">
                                          {errors[
                                            `storageBefUseNote${packageItem.id}`
                                          ] &&
                                            errors[
                                              `storageBefUseNote${packageItem.id}`
                                            ].message}
                                        </span>
                                      </Form.Group>
                                    </Col>
                                    <Col className="" md="4">
                                      <Form.Group>
                                        <label title="Storage for defective with note">
                                          Storage for defective with note
                                        </label>{" "}
                                        <Form.Control
                                          defaultValue=""
                                          placeholder=""
                                          as="textarea"
                                          row={5}
                                          disabled={true}
                                          {...register(
                                            `storageDefNote${packageItem.id}`,
                                            {}
                                          )}
                                        ></Form.Control>
                                        <span className="error-message">
                                          {errors[
                                            `storageDefNote${packageItem.id}`
                                          ] &&
                                            errors[
                                              `storageDefNote${packageItem.id}`
                                            ].message}
                                        </span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </fieldset>
                              )
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddModemLog;
