import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./outerfooter.css";

const OuterFooter = () => {
  return (
    <footer className="footer px-0 px-lg-3">
      <Container>
        <nav>
          <p className="copyright text-center no-float">
            Copyright © {new Date().getFullYear()} <a href="/">Rockwell Depot S.R.O</a>
            . All rights reserved.
          </p>
        </nav>
      </Container>
    </footer>
  );
};

export default OuterFooter;
