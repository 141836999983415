import React from "react";
import ModemLogTable from "components/Table/ModemLogTable";

const ModemLogView = () => {
  return (
    <div>
      <ModemLogTable />
    </div>
  );
};

export default ModemLogView;
