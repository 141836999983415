import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import LoadingOverlay from "components/Overlay";
import {
  getCCMList,
  deleteCCMList,editCCMList
,
} from "API/ccmlistApI";
import dayjs from "dayjs";

import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import { changeShowHideColumns } from "API/userRoleAPI";
import moment from 'moment';

const CCMListTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);


  const ccmListModuleID = parseInt(
    process.env.REACT_APP_CCM_LIST_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    term_filter: selectedTerm,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: modemLists,
    refetch,
  } = useQuery(["ccmLists", queryParams], getCCMList, {
    cacheTime: cacheDuration,
  });


  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const modemEditor = useMutation(editCCMList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true)
    },
  });
  const removeModem = useMutation(deleteCCMList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });


  const handleEdit = (user) => {
    navigate("/edit-ccm-list", {
      state: {
        id: user.id,
        currency_date: user.currency_date,
        date: user.created_date,
        currency_rate_eur: user.currency_rate_eur,
        currency_rate_rub: user.currency_rate_rub,
        currency_rate_czk: user.currency_rate_czk,
      },
    });
  };

  const handleDelete = async (modem) => {
    const deleteApi = async () => {
      try {
        const response = await removeModem.mutateAsync({ id: modem.id });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "CCM is in use!",
      deleteSuccessMessage: "CCM deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await modemEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[ccmListModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[ccmListModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    {
      perm_id: fieldIds.custom_Currency.currency_date,
      title: "Currency date",
      dataIndex: "currency_date",
    
    },
    {
      perm_id: fieldIds.custom_Currency.created_date,
      title: "Created date",
      dataIndex: "created_date",
      render: (created_date) => {
        return (
          <div className="center-aligned-text" >
            {moment(created_date).format("YYYY-MM-DD") || "-"}
          </div>
        )
    
      },
  
      sorter: (a, b) => {
        const dateOfDelA = dayjs(a.created_date);
        const dateOfDelB = dayjs(b.created_date);
        return dateOfDelA - dateOfDelB;
      },
    },
    {
      perm_id: fieldIds.custom_Currency.currency_rate_eur,
      title: "Currency rate (EUR/USD)",
      dataIndex: "currency_rate_eur",
    },
    {
      perm_id: fieldIds.custom_Currency.currency_rate_rub,
      title: "Currency rate (RUB/USD)",
      dataIndex: "currency_rate_rub",
    },
    {
      perm_id: fieldIds.custom_Currency.currency_rate_czk,
      title: "Currency rate (CZK/USD)",
      dataIndex: "currency_rate_czk",
    },

    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];
  const childColumns =[]
  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: modemLists?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={modemLists?.results}
        columns={columns}
        permissionSet={permissions?.permissions[ccmListModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        expandableRow={true}
        showColumnState={showColumnState}
      />
    );
  }
  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  return (
    <>
     <LoadingOverlay isLoading={loadings} />
    <Container fluid>

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Custom Currency Module
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row ">
                <Row className="mb-3 gap-3 justify-space justify-content-end ">
         <div style={{width:"830px",marginRight: "0px"}}>
                  <SearchFilter
                 onChangeHandler={handleSearch}
                 valueInput={searchInput}
                 placeholder="Search | Currency Rate"
                 style={{alignSelf:"flex-start"}}
               />
              </div>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-end top-table-buttons"
                  >
                    {isAddAllowed(
                      permissions.permissions[ccmListModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-ccm-list")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default CCMListTable;
