import React from "react";

import ForgotPasswordView from "views/ResetPassword/ForgotPassword";

const ForgotPassword = () => {
  return (
    <div>
      <ForgotPasswordView />
    </div>
  );
};

export default ForgotPassword;
