// ModalComponent.jsx
import React, { useState } from "react";
import { Modal, DatePicker } from "antd";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import CancelButton from "components/CancelButton/CancelButton";
import usePermissions from "customHooks/usePermissions";
import { addBalance } from "API/expirationListAPI";
import { useMutation } from "react-query";
import { handleKeyPress } from "helpers/numberValidator";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import { convertDate } from "helpers/dateValidator";
import LoadingOverlay from "components/Overlay";


const AddBalanceForm = ({ visible, onOk, onCancel, expirationListID }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedBalanceDate, setSelectedBalanceDate] = useState();
  const [loadings, setLoading] = useState(false);

  const {
    watch,
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  const addBalanceEditor = useMutation(addBalance, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "Balance added successfully!",
        position: "top-end",
        timer: 1500,
      });
      onOk();
      reset();
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    const formData = new FormData();

    formData.append(
      "leatest_balance_amount",
      data.latestBalanceAmount ? data.latestBalanceAmount : ""
    );

    formData.append(
      "leatest_balance_date",
      data.balanceDate ? convertDate(data.balanceDate) : ""
    );

    try {
      const response = await addBalanceEditor.mutateAsync({
        id: expirationListID,
        data: formData,
      });
    } catch (error) {
      if (error.response?.status == 400) {
      setLoading(false);

        setApiError(error.response?.data?.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <Modal
      title="Add balance"
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[]}
      onCancel={(e) => {
        onCancel(), reset();
      }}
    >
       <LoadingOverlay isLoading={loadings} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="user-fields-wrapper modal-form">
            <Row className="">
              {
                <Col className="" md="6">
                  <Form.Group>
                    <label title="Latest balance amount">
                      <span className="label-text">Latest balance amount <span className="text-red-500">*</span></span>
                    </label>

                    <Form.Control
                      defaultValue=""
                      placeholder="Latest Balance Amount"
                      type="number"
                      onKeyPress={(e) => handleKeyPress(e, true)}
                      {...register("latestBalanceAmount", {
                        maxLength: 50,
                      })}
                    ></Form.Control>
                    <span className="error-message">
                      {errors.latestBalanceAmount?.type === "maxLength" &&
                        "It accepts maximum 50 characters."}

                      {errors.latestBalanceAmount &&
                        errors.latestBalanceAmount.message}
                    </span>
                  </Form.Group>
                </Col>
              }

              {
                <Col className="" md="6">
                  <Form.Group>
                    <label title="Balance date">
                      <span className="label-text">Balance date <span className="text-red-500">*</span></span>
                    </label>

                    <br />
                    <Controller
                      name="" // Field name in the form data
                      control={control}
                      defaultValue={selectedBalanceDate}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          onChange={(date) => field.onChange(date)}
                          format="DD/MM/YYYY"
                          allowClear={false}
                        />
                      )}
                      {...register("balanceDate", {
                        required: watch("latestBalanceAmount") && true,
                      })}
                    />
                    <br />
                    {errors.balanceDate && (
                      <span className="error-message">
                        {watch("latestBalanceAmount") &&
                          errors.balanceDate?.type === "required" &&
                          "Balance date is required."}
                        {errors.balanceDate.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              }
            </Row>
          </Col>
        </Row>

        {(apiError || displayServerMessage) &&(<Col md="12">
          {apiError && <FormAlertMessage message={apiError} type="error" />}
          {displayServerMessage && (
            <FormAlertMessage message={serverErrorMessage} type="error" />
          )}
        </Col>)}

        <div className="mt-3">
          <Button
            className="btn-fill pull-right  add-user-button"
            type="submit"
            variant="info"
          >
            Save
          </Button>
          <CancelButton
            onClickHandler={(e) => {
              onCancel(), reset();
            }}
          />
        </div>

        <div className="clearfix"></div>
      </Form>
    </Modal>
  );
};

export default AddBalanceForm;
